import { createSlice } from "@reduxjs/toolkit";

const FamiliesSlice = createSlice({
  name: "families",
  initialState: {
    present: false,
    families: [],
    totalCount: 0,
    pageNumber: 1,
  },
  reducers: {
    getFamilies(state, action) {
      state.families = action.payload;
      state.present = true;
    },
    getTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    clearFamilies() {},
  },
});

export const FamiliesAction = FamiliesSlice.actions;
export const FamiliesReducer = FamiliesSlice.reducer;
