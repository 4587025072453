import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Swal from "sweetalert2";
import { ChurchAction } from "../../store/slices/ChurchSlice";

const UpdateUserDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
});

const EditProfileModal = ({ show, setShow, commonAPIRequest }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { churchDetails, churchUserDetails } = useSelector(
    (state) => state.church
  );

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      userEmail: "",
    },
    validationSchema: UpdateUserDetailsValidationSchema,
    onSubmit: (values) => {
      callUpdateUserInfoApi(values);
    },
  });

  const callUpdateUserInfoApi = (values) => {
    let serviceParams = {
      api: API.UPDATE_USER_INFO,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNum: values?.phone,
        userEmail: values.userEmail,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Details Updated Successfully.`, "success");
          callGetUserDetailsApi();
          setShow(false);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetUserDetailsApi = () => {
    let serviceParams = {
      api: API.GET_USER_INFO,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          console.log("result user data", result);
          dispatch(ChurchAction.setChurchUserDetails(result?.payload));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      formik.setFieldValue("firstName", churchUserDetails?.firstName);
      formik.setFieldValue("lastName", churchUserDetails?.lastName);
      formik.setFieldValue("phone", churchUserDetails?.phoneNum);
      formik.setFieldValue("userEmail", churchUserDetails?.userEmail);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Profile Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col sm="6">
                <TextInput
                  label={"First Name"}
                  placeholder={"Enter your first name"}
                  id={"firstName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="firstName"
                  value={formik.values.firstName}
                  error={formik.errors.firstName}
                  touched={formik.touched.firstName}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Last Name"}
                  placeholder={"Enter your last name"}
                  id={"lastName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="lastName"
                  value={formik.values.lastName}
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                />
              </Col>

              <Col sm="12">
                <TextInput
                  label={"Mobile Number"}
                  placeholder={"Enter your Phone"}
                  id={"phone"}
                  setFieldValue={formik.setFieldValue}
                  keyword="phone"
                  value={formik.values.phone}
                  error={formik.errors.phone}
                  touched={formik.touched.phone}
                />
              </Col>

              <Col sm="12">
                <TextInput
                  label={"User Email"}
                  placeholder={"Confirm your password"}
                  id={"userEmail"}
                  setFieldValue={formik.setFieldValue}
                  keyword="userEmail"
                  value={formik.values.userEmail}
                  error={formik.errors.userEmail}
                  touched={formik.touched.userEmail}
                  disabled={true}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditProfileModal);
