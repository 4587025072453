import React, { useEffect, useState } from "react";

// -------------------------- DATA ------------------------------

import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import FinanceSection from "../../dashboard/analytics-components/FinanceSection";
import BirthdaySection from "../../dashboard/analytics-components/BirthdaySection";
import AnniversarySection from "../../dashboard/analytics-components/AnniversarySection";

const ParishDashboard = ({ commonAPIRequest, churchId }) => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState();

  const callGetDashboardData = () => {
    let serviceParams = {
      api: `${API.CHURCH_DASHBOARD}?churchId=${churchId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setDashboardData(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    callGetDashboardData();
  }, []);

  return (
    <React.Fragment>
      {/* <Heade onSkin={setSkin} /> */}
      <div className="">
        {loading ? (
          <div
            className=" d-flex align-items-center justify-content-center "
            style={{ height: "90vh" }}
          >
            <Lottie
              style={{ width: 100, height: 100 }}
              animationData={loadingAnimation}
              loop={true}
            />
            loading dashboard...
          </div>
        ) : (
          <Row className="g-3">
            <FinanceSection data={dashboardData?.financialsInfo} />

            {[
              {
                label: "Baptism's Done",
                icon: "ri-shopping-bag-3-line",
                value: dashboardData?.sacramentsInfo?.baptismsThisMonth,
                percent: "0.7",
                status: "down",
              },
              {
                label: "Holy Communion's Done",
                icon: "ri-briefcase-4-line",
                value: dashboardData?.sacramentsInfo?.holyCommunionsThismonth,
                percent: "2.1",
                status: "up",
              },
              {
                label: "Holy Confirmation's Done",
                icon: "ri-inbox-line",
                value: dashboardData?.sacramentsInfo?.confirmationsThisMonth,
                percent: "0.3",
                status: "down",
              },
              {
                label: "Marriage's Done",
                icon: "ri-bar-chart-box-line",
                value: dashboardData?.sacramentsInfo?.marriagesThisMonth,
                percent: "1.2",
                status: "up",
              },
            ].map((card, index) => (
              <Col xs="6" xl="3" key={index}>
                <Card className="card-one">
                  <Card.Body>
                    <Card.Title as="label" className="fs-sm fw-medium mb-1">
                      {card.label}
                    </Card.Title>
                    <h3 className="card-value mb-1">
                      <i className={card.icon}></i> {card.value}
                    </h3>
                    <small>
                      {new Date().toLocaleDateString("en-IN", {
                        month: "long",
                        year: "numeric",
                      })}
                    </small>
                  </Card.Body>
                </Card>
              </Col>
            ))}

            <Col lg={"6"}>
              <BirthdaySection
                birthdays={
                  dashboardData?.birthdayDetailList
                    ? dashboardData?.birthdayDetailList
                    : []
                }
              />
            </Col>

            <Col lg={"6"}>
              <AnniversarySection
                anniversarys={
                  dashboardData?.anniversaryDetailList
                    ? dashboardData?.anniversaryDetailList
                    : []
                }
              />
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

export default withAPIRequest(ParishDashboard);
