import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { useFormik } from "formik";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import {
  ExtMarriageStep1ValidationSchema,
  ExtMarriageStep2ValidationSchema,
  ExtMarriageStep3ValidationSchema,
} from "../../validations/marriage";
import Swal from "sweetalert2";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Select from "react-select";
import { useSelector } from "react-redux";

const AddExternalMarriageDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  holyConfirmationDetails,
  familyDetails,
  baptismDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(1);
  const [potentialBrides, setPotentialBrides] = useState([]);
  const [selectedWife, setSelectedWife] = useState({});
  const [baptismNameGroom, setBaptismNameGroom] = useState(true);
  const [baptismNameBride, setBaptismNameBride] = useState(true);
  const { churchDetails } = useSelector((state) => state.church);

  const formik1 = useFormik({
    initialValues: {
      confiramtionDate: "",
      marriageDate: "",
      extMarriage: true,
      extMarriageChurch: "",
      extMarriageCity: "",
      confirmationDateForValidation: "",
      marriageDateNotSure: false,
    },
    validationSchema: ExtMarriageStep1ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      groomFirstName: "",
      groomMiddleName: "",
      groomLastName: "",
    },
    validationSchema: ExtMarriageStep2ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik3 = useFormik({
    initialValues: {
      brideFirstName: "",
      brideMiddleName: "",
      brideLastName: "",
      brideMemberId: "",
    },
    validationSchema: ExtMarriageStep3ValidationSchema,
    onSubmit: (values) => {
      callAddMarriageDetailsApi();
    },
  });

  // function to call API for Add Marriage start

  const callAddMarriageDetailsApi = () => {
    let serviceParams = {
      api: API.EXTERNAL_MARRIAGE_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        marriageDetail: {
          groomFirstName: formik2.values.groomFirstName,
          groomMiddleName: formik2.values.groomMiddleName,
          groomLastName: formik2.values.groomLastName,
          brideFirstName: formik3.values.brideFirstName,
          brideMiddleName: formik3.values.brideMiddleName,
          brideLastName: formik3.values.brideLastName,
          marriageDate: new Date(formik1.values.marriageDate),
          extMarriage: formik1.values.extMarriage,
          extMarriageChurch: formik1.values.extMarriageChurch,
          extMarriageCity: formik1.values.extMarriageCity,
          marriageDateAccurate: !formik1.values.marriageDateNotSure,
        },
        brideMemberId: formik3.values.brideMemberId,
        groomMemberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Marriage Details Added Successfully.`,
            "success"
          );
          getFamilyMemberDetails();
          setShow(false);
          formik1.resetForm();
          formik2.resetForm();
          formik3.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add Marriage end

  // function to fetch all members details in the family start

  const getAllFamilyMembersDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY_MEMBERS}?familyId=${familyDetails?.familyId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          //
          if (basicDetails.familyHead) {
            // let wifeInfo =
            //   result.payload.familyMemberDetails &&
            //   result.payload.familyMemberDetails.filter(
            //     (member) => member.relationToHead === "WIFE"
            //   )[0];
            // if (wifeInfo?.baptismName) {
            //   formik3.setFieldValue("brideFirstName", wifeInfo?.baptismName);
            // } else {
            //   formik3.setFieldValue("brideFirstName", wifeInfo?.firstName);
            // }
            // formik3.setFieldValue("brideMiddleName", wifeInfo?.middleName);
            // formik3.setFieldValue("brideLastName", wifeInfo?.lastName);
            // formik3.setFieldValue("brideMemberId", wifeInfo?.memberId);
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "WIFE" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "SON") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "DAUGHTER IN LAW" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "BROTHER") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "SISTER IN LAW" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "GRAND SON") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "GRAND DAUGHTER IN LAW" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "UNCLE") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "AUNT" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "GRAND FATHER") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "GRAND MOTHER" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "FATHER") {
            // let wifeInfo =
            //   result.payload.familyMemberDetails &&
            //   result.payload.familyMemberDetails.filter(
            //     (member) => member.relationToHead === "MOTHER"
            //   )[0];
            // if (wifeInfo?.baptismName) {
            //   formik3.setFieldValue("brideFirstName", wifeInfo?.baptismName);
            // } else {
            //   formik3.setFieldValue("brideFirstName", wifeInfo?.firstName);
            // }
            // formik3.setFieldValue("brideMiddleName", wifeInfo?.middleName);
            // formik3.setFieldValue("brideLastName", wifeInfo?.lastName);
            // formik3.setFieldValue("brideMemberId", wifeInfo?.memberId);
            let wifes = result.payload.familyMemberDetails.filter(
              (member) =>
                member.relationToHead === "MOTHER" &&
                member?.marriageStatus === "SINGLE"
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          } else if (basicDetails?.relationToHead === "HUSBAND") {
            let wifes = result.payload.familyMemberDetails.filter(
              (member) => member.familyHead
            );
            setPotentialBrides(
              wifes.map((wife) => {
                return {
                  label: `${wife?.firstName} ${wife?.lastName}`,
                  value: wife?.memberId,
                  firstName: wife?.firstName,
                  middleName: wife?.middleName,
                  lastName: wife?.lastName,
                  baptismName: wife?.baptismName,
                };
              })
            );
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // function to fetch all members details in the family end

  useEffect(() => {
    if (show) {
      if (basicDetails?.gender === "Male") {
        if (basicDetails?.baptismName) {
          formik2.setFieldValue("groomFirstName", basicDetails?.baptismName);
        } else {
          formik2.setFieldValue("groomFirstName", basicDetails?.firstName);
        }
        formik2.setFieldValue("groomMiddleName", basicDetails?.middleName);
        formik2.setFieldValue("groomLastName", basicDetails?.lastName);
      }
      formik1.setFieldValue(
        "confirmationDate",
        new Date(holyConfirmationDetails?.confirmationDate)
      );
      formik1.setFieldValue(
        "confirmationDateForValidation",
        new Date(
          new Date(holyConfirmationDetails?.confirmationDate).setDate(
            new Date(holyConfirmationDetails?.confirmationDate).getDate() - 1
          )
        )
      );
      if (baptismDetails?.marriageDate) {
        formik1.setFieldValue(
          "marriageDate",
          new Date(baptismDetails?.marriageDate)
        );
      }
      getAllFamilyMembersDetailsApi();
    }
  }, [show]);

  useEffect(() => {
    if (baptismNameGroom) {
      if (basicDetails?.baptismName) {
        formik2.setFieldValue("groomFirstName", basicDetails?.baptismName);
      }
    } else {
      formik2.setFieldValue("groomFirstName", basicDetails?.firstName);
    }
  }, [baptismNameGroom]);

  useEffect(() => {
    if (baptismNameBride) {
      if (selectedWife?.baptismName) {
        formik3.setFieldValue("brideFirstName", selectedWife?.baptismName);
      }
    } else {
      formik3.setFieldValue("brideFirstName", selectedWife?.firstName);
    }
  }, [baptismNameBride]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Marriage Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 3
                      ? "current last"
                      : currentState > 3
                      ? "done last"
                      : "last"
                  }
                  aria-disabled="true"
                >
                  <a id="wizard1-t-2" href="#" aria-controls="wizard1-p-2">
                    <span className="number">3</span>{" "}
                    <span className="title">Step 3</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <Row>
                    <p className="text-muted">
                      As {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                      {basicDetails?.lastName}'s marriage was done{" "}
                      <span className="fw-bold text-primary text-decoration-underline">
                        outside of {churchDetails?.churchName}.
                      </span>{" "}
                      <br /> Please Enter these basic marriage details for the
                      records.
                    </p>

                    <Col sm={12}>
                      <DateInput
                        label={"Marriage Date"}
                        setFieldValue={formik1.setFieldValue}
                        keyword={"marriageDate"}
                        value={formik1.values.marriageDate}
                        error={formik1.errors.marriageDate}
                        touched={formik1.touched.marriageDate}
                      />
                    </Col>

                    <Col className="mt-2" sm={12}>
                      <Form.Check
                        type="checkbox"
                        label="Not sure if marriage date is correct"
                        onChange={(e) => {
                          formik1.setFieldValue(
                            "marriageDateNotSure",
                            e.target.checked
                          );
                        }}
                        checked={formik1.values.marriageDateNotSure}
                      />
                    </Col>

                    <Col sm={6}>
                      <TextInput
                        label={"Marriage Church Name"}
                        placeholder={"e.g. St. Mary's Church"}
                        setFieldValue={formik1.setFieldValue}
                        keyword={"extMarriageChurch"}
                        value={formik1.values.extMarriageChurch}
                        error={formik1.errors.extMarriageChurch}
                        touched={formik1.touched.extMarriageChurch}
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInput
                        label={"Marriage Church City"}
                        placeholder={"e.g. Noida"}
                        setFieldValue={formik1.setFieldValue}
                        keyword={"extMarriageCity"}
                        value={formik1.values.extMarriageCity}
                        error={formik1.errors.extMarriageCity}
                        touched={formik1.touched.extMarriageCity}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Groom's Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  {/* <BaptismFormStep2 formik={formikStep2} /> */}
                  <Row>
                    <Col sm={12}>
                      <TextInput
                        label={"Groom's First Name"}
                        setFieldValue={formik2.setFieldValue}
                        keyword={"groomFirstName"}
                        value={formik2.values.groomFirstName}
                        error={formik2.errors.groomFirstName}
                        touched={formik2.touched.groomFirstName}
                        disabled
                      />
                      {basicDetails?.baptismName && (
                        <Form.Check
                          type="switch"
                          className="mt-1"
                          checked={baptismNameGroom}
                          label="Use Baptism Name"
                          onChange={(e) => {
                            setBaptismNameGroom(e.target.checked);
                          }}
                        />
                      )}
                    </Col>
                    <Col sm={12}>
                      <TextInput
                        label={"Groom's Middle Name"}
                        setFieldValue={formik2.setFieldValue}
                        keyword={"groomMiddleName"}
                        value={formik2.values.groomMiddleName}
                        error={formik2.errors.groomMiddleName}
                        touched={formik2.touched.groomMiddleName}
                      />
                    </Col>
                    <Col sm={12}>
                      <TextInput
                        label={"Groom's Last Name"}
                        setFieldValue={formik2.setFieldValue}
                        keyword={"groomLastName"}
                        value={formik2.values.groomLastName}
                        error={formik2.errors.groomLastName}
                        touched={formik2.touched.groomLastName}
                      />
                    </Col>
                  </Row>
                </div>
              )}

              {currentState === 3 && (
                <div>
                  <Row className="d-flex justify-content-between">
                    <Col sm={6}>
                      <h3
                        id="wizard1-h-0"
                        tabIndex="-1"
                        className="title current"
                      >
                        Bride's Basic Information
                      </h3>
                      <p className="mb-0">
                        Please enter the below details right!
                      </p>
                    </Col>
                    <Col sm={6}>
                      <Form.Label
                        className="mt-2 fw-bold text-primary"
                        htmlFor={"potentialWife"}
                      >
                        Select Bride to fetch details
                      </Form.Label>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? "grey" : "#506fd9",
                            borderWidth: 2,
                          }),
                        }}
                        value={selectedWife}
                        id={"potentialWife"}
                        options={potentialBrides}
                        onChange={(e) => {
                          setSelectedWife(e);
                          formik3.setFieldValue("brideMemberId", e.value);
                          if (e?.baptismName) {
                            formik3.setFieldValue(
                              "brideFirstName",
                              e?.baptismName
                            );
                          } else {
                            formik3.setFieldValue(
                              "brideFirstName",
                              e?.firstName
                            );
                          }
                          formik3.setFieldValue(
                            "brideMiddleName",
                            e?.middleName
                          );
                          formik3.setFieldValue("brideLastName", e?.lastName);
                          formik3.setFieldValue("brideMemberId", e.value);
                        }}
                        isSearchable={true}
                        // isDisabled={
                        //   basicDetails.familyHead ||
                        //   basicDetails?.relationToHead === "FATHER"
                        // }
                      />
                    </Col>
                  </Row>
                  {!formik3.values.brideMemberId &&
                    potentialBrides.length === 0 && (
                      <Alert variant="danger" className="my-2">
                        <h6 className="m-0">Brides Details not found.</h6>
                        <p className="m-0">
                          Please Verify if the bride is a part of{" "}
                          {basicDetails?.firstName}'s family.
                        </p>
                      </Alert>
                    )}
                  <hr className="hr" />
                  <Row>
                    <Col sm={12}>
                      <TextInput
                        label={"Bride's First Name"}
                        setFieldValue={formik3.setFieldValue}
                        keyword={"brideFirstName"}
                        value={formik3.values.brideFirstName}
                        error={formik3.errors.brideFirstName}
                        touched={formik3.touched.brideFirstName}
                        disabled
                      />
                      {selectedWife?.baptismName && (
                        <Form.Check
                          type="switch"
                          className="mt-1"
                          checked={baptismNameBride}
                          label="Use Baptism Name"
                          onChange={(e) => {
                            setBaptismNameBride(e.target.checked);
                          }}
                        />
                      )}
                    </Col>
                    <Col sm={12}>
                      <TextInput
                        label={"Bride's Middle Name"}
                        setFieldValue={formik3.setFieldValue}
                        keyword={"brideMiddleName"}
                        value={formik3.values.brideMiddleName}
                        error={formik3.errors.brideMiddleName}
                        touched={formik3.touched.brideMiddleName}
                      />
                    </Col>
                    <Col sm={12}>
                      <TextInput
                        label={"Bride's Last Name"}
                        setFieldValue={formik3.setFieldValue}
                        keyword={"brideLastName"}
                        value={formik3.values.brideLastName}
                        error={formik3.errors.brideLastName}
                        touched={formik3.touched.brideLastName}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (!(currentState <= 1)) {
                setCurrentState((prevValue) => prevValue - 1);
              }
            }}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (currentState === 1) {
                formik1.handleSubmit();
              } else if (currentState === 2) {
                formik2.handleSubmit();
              } else if (currentState === 3) {
                formik3.handleSubmit();
              }
            }}
          >
            {currentState === 3 ? "Save" : "Next"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddExternalMarriageDetailsModal);
