import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";

import img29 from "../../assets/img/img29.jpg";
import img30 from "../../assets/img/img30.jpg";
import img31 from "../../assets/img/img31.jpg";
import img32 from "../../assets/img/img32.jpg";
import img33 from "../../assets/img/img33.jpg";
import { Link } from "react-router-dom";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { LoadingAction } from "../../store/slices/LoadingSlice";
import { useDispatch } from "react-redux";
import AddParishUsersModal from "../../modals/parishes/AddParishUsersModal";

const ParishUsers = ({ selectedTab, commonAPIRequest, churchId }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);

  const callGetAllParishUsers = () => {
    let serviceParams = {
      api: `${API.GET_ALL_USERS_RESOURCE}?pgNum=1&pgSize=100&churchId=${churchId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    dispatch(LoadingAction.startTranslucentLoader());
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        dispatch(LoadingAction.endTranslucentLoader());
        try {
          console.log("users", result.payload);
          setUsers(result?.payload?.userList);
          // setParishes(result?.payload?.churchList);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  useEffect(() => {
    if (selectedTab === 2) {
    }
    callGetAllParishUsers();
  }, []);

  return (
    <Row>
      <div className="d-flex justify-content-end mb-3">
        <Button
          onClick={() => {
            setAddUserModal(true);
          }}
        >
          Add User
        </Button>
      </div>
      <Col xs="12" sm>
        <Table className="table-music mb-0">
          <tbody>
            {users.map((user, index) => (
              <tr key={user?.userId}>
                <td className="w-5">{index + 1}</td>
                <td className="w-75">
                  <div className="media-music">
                    <Link to="" className="media-img">
                      <img src={img29} alt="" />
                    </Link>
                    <div className="media-body">
                      <h6>
                        <Link to="">
                          {user.firstName} {user?.lastName}
                        </Link>
                      </h6>
                      <span>{user.phoneNum}</span>
                    </div>
                  </div>
                </td>
                <td className="w-15">
                  <span className="time">ADMIN</span>
                </td>
                <td className="w-5">
                  <Link to="" className="dropdown-link">
                    <i className="ri-more-2-fill"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>

      <AddParishUsersModal
        show={addUserModal}
        setShow={setAddUserModal}
        churchId={churchId}
        callGetAllParishUsers={callGetAllParishUsers}
      />
    </Row>
  );
};

export default withAPIRequest(ParishUsers);
