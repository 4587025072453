import { useFormik } from "formik";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import DateInput from "../../../components/form/DateInput";

function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const MarriageCertificateFilterModal = ({
  show,
  setShow,
  callGetAllMarriageByDate,
}) => {
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      callGetAllMarriageByDate(
        formatDateToYYYYMMDD(values.startDate),
        formatDateToYYYYMMDD(values.endDate)
      );
      setShow(false);
      formik.resetForm();
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="sm"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Marriage Filter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <DateInput
              label={"Start Date"}
              placeholder={"Select Start Date"}
              setFieldValue={formik.setFieldValue}
              keyword={"startDate"}
              value={formik.values.startDate}
              error={formik.errors.startDate}
              touched={formik.touched.startDate}
            />
          </Col>
          <Col sm={12}>
            <DateInput
              label={"End Date"}
              placeholder={"Select End Date"}
              setFieldValue={formik.setFieldValue}
              keyword={"endDate"}
              value={formik.values.endDate}
              error={formik.errors.endDate}
              touched={formik.touched.endDate}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => formik.handleSubmit()}>
          Search
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MarriageCertificateFilterModal;
