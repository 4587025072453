import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import bloodIcon from "../../assets/img/blood.png";

import { useFormik } from "formik";
import SelectInput from "../../components/form/SelectInput";
import { Link } from "react-router-dom";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Avatar from "../../components/Avatar";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const BloodGroupDonationModal = ({ show, setShow, commonAPIRequest }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      bloodGroup: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const callSearchUsersApi = () => {
    let serviceParams = {
      api: `${API.SEARCH_MEMBER}?bloodGroup=${formik.values.bloodGroup}&filterBy=All`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setMembers(
            result.payload.searchSuggestions.length !== 0
              ? result.payload.searchSuggestions
              : []
          );
          //   dispatch(
          //     SearchAction.getMembers(
          //   result.payload.searchSuggestions.length !== 0
          //     ? result.payload.searchSuggestions
          //     : []
          //     )
          //   );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (formik.values.bloodGroup) {
      callSearchUsersApi();
    }
  }, [formik.values.bloodGroup]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">Blood Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <SelectInput
              selectOptions={[
                { label: "A+", value: "A%2B" },
                { label: "A-", value: "A-" },
                { label: "B+", value: "B%2B" },
                { label: "B-", value: "B-" },
                { label: "AB+", value: "AB%2B" },
                { label: "AB-", value: "AB-" },
                { label: "O+", value: "O%2B" },
                { label: "O-", value: "O-" },
              ]}
              label={"Blood Group"}
              setFieldValue={formik.setFieldValue}
              keyword={"bloodGroup"}
              value={formik.values.bloodGroup}
              error={formik.errors.bloodGroup}
              touched={formik.touched.bloodGroup}
            />
          </Col>
          <hr className="hr mt-4" />

          {loading && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="text-center"
              >
                <Lottie
                  style={{ width: 120, height: 120 }}
                  animationData={loadingAnimation}
                  loop={true}
                />
              </div>

              <p className="text-center">Loading...</p>
            </div>
          )}
          {/*  */}
          {members.length !== 0 && (
            <div
              style={{ height: 500, overflow: "hidden", overflowY: "scroll" }}
            >
              <Table
                // style={{ height: 500 }}
                className="table table-files"
                responsive
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Willing</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="media">
                          <div className={"media-icon "}>
                            {/* <i className="ri-user-3-fill"></i> */}
                            <Avatar img={bloodIcon} />
                          </div>
                          <h4 className="file-name ">
                            <Link className="text-primary fw-bold" href="">
                              {user.firstName} {user.middleName}
                              {user.lastName}
                            </Link>
                            <h6 className="m-0">{user?.phNumber}</h6>
                            <h6 className="m-0">{user?.emailAddress}</h6>
                          </h4>
                        </div>
                      </td>
                      <td> {getAge(user?.dateOfBirth) + " Years Old"}</td>
                      <td>
                        {user?.donateBlood ? (
                          <i className="ri-check-line fs-24 text-success"></i>
                        ) : (
                          <i className="ri-close-line fs-24 text-danger"></i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {/*  */}
          {!loading && members.length === 0 && (
            <div className="mb-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="text-center"
              >
                <Lottie
                  style={{ width: 180, height: 180 }}
                  animationData={noSearchAnimation}
                  loop={true}
                />
              </div>
              <h3 className="text-center fw-bold">No Members Found</h3>
              <h5 className="text-center fw-400">
                Please Try With Another Blood Group.
              </h5>
            </div>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default withAPIRequest(BloodGroupDonationModal);
