import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FamiliesReducer } from "./slices/FamiliesSlice";
import { ZonesReducer } from "./slices/ZonesSlice";
import { AnalyticsReducer } from "./slices/AnalyticsSlice";
import { LoadingReducer } from "./slices/LoadingSlice";
import { AuthReducer } from "./slices/AuthSlice";
import { SearchReducer } from "./slices/SearchSlice";
import { FamilyReducer } from "./slices/FamilySlice";
import { BaptismsReducer } from "./slices/BaptismsSlice";
import { HolyCommunionsReducer } from "./slices/HolyCommunionsSlice";
import { HolyConfirmationsReducer } from "./slices/HolyConfirmationsSlice";
import { MarriagesReducer } from "./slices/MarriagesSlice";
import { AnnulmentsReducer } from "./slices/AnnulmentsSlice";
import { ChurchReducer } from "./slices/ChurchSlice";
import { FinancialsReducer } from "./slices/FinancialsSlice";

//
import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
//

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    families: FamiliesReducer,
    zones: ZonesReducer,
    analytics: AnalyticsReducer,
    loading: LoadingReducer,
    auth: AuthReducer,
    search: SearchReducer,
    family: FamilyReducer,
    baptisms: BaptismsReducer,
    holyCommunions: HolyCommunionsReducer,
    holyConfirmations: HolyConfirmationsReducer,
    marriages: MarriagesReducer,
    annulments: AnnulmentsReducer,
    church: ChurchReducer,
    financial: FinancialsReducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
