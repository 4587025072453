import React, { useState } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import Lottie from "lottie-react";
import { Button, Modal, Spinner } from "react-bootstrap";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import DonationReciptPdf from "../../components/donation-receipt/DonationReceiptPdf";
import donationLottie from "../../assets/lottie/donation_delhi_theme.json";
import DioceseDonationReceiptPdf from "../../components/donation-receipt/DioceseDonationReceiptPdf";

const DioceseDonationLinkReceiptModal = ({
  show,
  setShow,
  donationDetails,
  churchDetails,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="sm"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
          Diocese Donation Receipt Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ alignSelf: "center" }}>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <BlobProvider
            // key={currentBaptism?.id}
            document={
              <DioceseDonationReceiptPdf
                details={donationDetails}
                churchDetails={churchDetails}
              />
            }
          >
            {({ blob, url, loading, error }) => {
              // Handle loading state
              if (loading) {
                return (
                  <Button
                    style={{ width: 120 }}
                    // variant="primary"
                    disabled
                  >
                    <Spinner animation="border" variant="light" />
                  </Button>
                );
              }
              // Handle error state
              if (error) {
                return (
                  <Button className="rounded-pill" variant="danger" disabled>
                    Error
                  </Button>
                );
              }
              // Function to open PDF in a new tab
              const openPdf = () => {
                const pdfWindow = window.open();
                pdfWindow.location.href = url;
              };
              return (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="text-center"
                  >
                    <Lottie
                      style={{ width: 180, height: 180 }}
                      animationData={donationLottie}
                      loop={true}
                    />
                  </div>
                  <Button
                    variant="primary"
                    style={{ width: 120 }}
                    // className="rounded-pill"
                    onClick={openPdf}
                  >
                    <i className="ri-file-text-line"></i> Receipt
                  </Button>
                </div>
              );
            }}
          </BlobProvider>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DioceseDonationLinkReceiptModal;
