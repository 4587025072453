import { createSlice } from "@reduxjs/toolkit";

const FinancialsSlice = createSlice({
  name: "financials",
  initialState: {
    csfListPresent: false,
    csfList: [],
    csfMonth: new Date().getMonth() + 1,
    csfTotalCount: 0,
    csfPageNumber: 1,
    csfGraphDataPresent: false,
    csfGraphData: [],
    //
    donationListPresent: false,
    donationList: [],
    donationMonth: new Date().getMonth() + 1,
    donationTotalCount: 0,
    donationPageNumber: 1,
    donationGraphDataPresent: false,
    donationGraphData: [],
    //
    expenseListPresent: false,
    expenseList: [],
    expenseMonth: new Date().getMonth() + 1,
    expenseTotalCount: 0,
    expensePageNumber: 1,
    expenseGraphDataPresent: false,
    expenseGraphData: [],
    //
    dioceseDonationListPresent: false,
    dioceseDonationList: [],
    dioceseDonationMonth: new Date().getMonth() + 1,
    dioceseDonationTotalCount: 0,
    dioceseDonationPageNumber: 1,
    dioceseDonationGraphDataPresent: false,
    dioceseDonationGraphData: [],
  },
  reducers: {
    getCsfList(state, action) {
      state.csfList = action.payload;
      state.csfListPresent = true;
    },
    getCsfTotalCount(state, action) {
      state.csfTotalCount = action.payload;
    },
    setCsfPageNumber(state, action) {
      state.csfPageNumber = action.payload;
    },
    setCsfMonth(state, action) {
      state.csfMonth = action.payload;
    },
    clearCsfList(state, action) {
      state.csfList = [];
      state.csfListPresent = false;
    },
    setCsfGraphData(state, action) {
      state.csfGraphData = action.payload;
      state.csfGraphDataPresent = true;
    },
    //
    getDonationList(state, action) {
      state.donationList = action.payload;
      state.donationListPresent = true;
    },
    getDonationTotalCount(state, action) {
      state.donationTotalCount = action.payload;
    },
    setDoantionPageNumber(state, action) {
      state.donationPageNumber = action.payload;
    },
    setDonationMonth(state, action) {
      state.donationMonth = action.payload;
    },
    clearDonationList(state, action) {
      state.donationList = [];
      state.donationListPresent = false;
    },
    setDonationGraphData(state, action) {
      state.donationGraphData = action.payload;
      state.donationGraphDataPresent = true;
    },
    //
    getExpenseList(state, action) {
      state.expenseList = action.payload;
      state.expenseListPresent = true;
    },
    getExpenseTotalCount(state, action) {
      state.expenseTotalCount = action.payload;
    },
    setExpenseMonth(state, action) {
      state.expenseMonth = action.payload;
    },
    setExpensePageNumber(state, action) {
      state.expensePageNumber = action.payload;
    },
    clearExpenseList(state, action) {
      state.expenseList = [];
      state.expenseListPresent = false;
    },
    setExpenseGraphData(state, action) {
      state.expenseGraphData = action.payload;
      state.expenseGraphDataPresent = true;
    },
    //
    getDioceseDonationList(state, action) {
      state.dioceseDonationList = action.payload;
      state.dioceseDonationListPresent = true;
    },
    getDioceseDonationTotalCount(state, action) {
      state.dioceseDonationTotalCount = action.payload;
    },
    setDioceseDoantionPageNumber(state, action) {
      state.dioceseDonationPageNumber = action.payload;
    },
    setDioceseDonationMonth(state, action) {
      state.dioceseDonationMonth = action.payload;
    },
    clearDioceseDonationList(state, action) {
      state.dioceseDonationMonth = [];
      state.dioceseDonationListPresent = false;
    },
    setDioceseDonationGraphData(state, action) {
      state.dioceseDonationGraphData = action.payload;
      state.dioceseDonationGraphDataPresent = true;
    },
  },
});

export const FinancialsAction = FinancialsSlice.actions;
export const FinancialsReducer = FinancialsSlice.reducer;
