import React, { useState } from "react";
import Header from "../layouts/Header";
import { Card, Col, Row } from "react-bootstrap";
import img2 from "../assets/img/img2.jpg";

const Certificates = () => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <h5 id="section6" className="main-subtitle">
          Image Overlay
        </h5>
        <p>
          Turn an image into a card background and overlay your card’s text.
          Depending on the image, you may or may not need additional styles or
          utilities.
        </p>

        <Card className="card-example">
          <Card.Body>
            <Row>
              <Col sm="9" md="7" lg="8">
                <Card className="bg-dark text-white border-0">
                  <Card.Img src={img2} alt="Card image" />
                  <Card.ImgOverlay>
                    <Card.Title>Card title</Card.Title>
                    <Card.Text>
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </Card.Text>
                    <Card.Text>Last updated 3 mins ago</Card.Text>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Certificates;
