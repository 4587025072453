import React, { useEffect, useState } from "react";
import { Col, Offcanvas, ProgressBar, Row, Button } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { ThreeCircles } from "react-loader-spinner";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import EditAnnulmentDetailsModal from "../../modals/annulment/EditAnnulmentDetailsModal";

const AnnulmentInfoOffset = ({
  show,
  setShow,
  commonAPIRequest,
  annulmentId,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentAnnulment, setCurrentAnnulment] = useState();
  const [showEditAnnulmentModal, setShowEditAnnulmentModal] = useState(false);

  const callGetAnnulmentDetails = () => {
    let serviceParams = {
      api: `${API.ANNULMENT_DETAILS}?annulmentId=${annulmentId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCurrentAnnulment(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      callGetAnnulmentDetails();
    }
  }, [show]);

  return (
    <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          Annulment Details <br />
          <p className="fw-bold m-0 text-primary">
            {currentAnnulment?.referenceNum}
          </p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Button
        className="mx-2 "
        onClick={() => {
          setShowEditAnnulmentModal(true);
        }}
      >
        {" "}
        Edit
      </Button>
      <hr className="hr" />
      <Offcanvas.Body>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThreeCircles
              height="40"
              width="40"
              color="#912D2D"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <Row>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's First Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.groomFirstName}
                </p>
              </div>
            </Col>
            {currentAnnulment?.groomMiddleName && (
              <Col sm={6}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Groom's Middle Name</strong>
                  </p>
                  <p
                    style={{ fontSize: 18, fontWeight: "lighter" }}
                    className="mb-0"
                  >
                    {currentAnnulment?.groomMiddleName}
                  </p>
                </div>
              </Col>
            )}
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Last Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.groomLastName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Father Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.groomFatherName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Mother Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.groomMotherName}
                </p>
              </div>
            </Col>
            {currentAnnulment?.groomAddress && (
              <Col sm={12}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Groom's Address</strong>
                  </p>
                  <p
                    style={{ fontSize: 18, fontWeight: "lighter" }}
                    className="mb-0"
                  >
                    {currentAnnulment?.groomAddress}
                  </p>
                </div>
              </Col>
            )}
            <ProgressBar now="100" className="h-1 my-2" />
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's First Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.brideFirstName}
                </p>
              </div>
            </Col>
            {currentAnnulment?.brideMiddleName && (
              <Col sm={6}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Bride's Middle Name</strong>
                  </p>
                  <p
                    style={{ fontSize: 18, fontWeight: "lighter" }}
                    className="mb-0"
                  >
                    {currentAnnulment?.brideMiddleName}
                  </p>
                </div>
              </Col>
            )}
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Last Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.brideLastName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Father Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.brideFatherName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Mother Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.brideMotherName}
                </p>
              </div>
            </Col>

            {currentAnnulment?.brideAddress && (
              <Col sm={12}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Bride's Address</strong>
                  </p>
                  <p
                    style={{ fontSize: 18, fontWeight: "lighter" }}
                    className="mb-0"
                  >
                    {currentAnnulment?.brideAddress}
                  </p>
                </div>
              </Col>
            )}

            <ProgressBar now="100" className="h-1 my-2" />

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Annulment</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {new Date(currentAnnulment?.annulmentDate).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Tribunal</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.tribunalName}
                </p>
              </div>
            </Col>

            <Col sm={12}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Remark</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentAnnulment?.remark}
                </p>
              </div>
            </Col>
          </Row>
        )}
      </Offcanvas.Body>
      <EditAnnulmentDetailsModal
        show={showEditAnnulmentModal}
        setShow={setShowEditAnnulmentModal}
        currentAnnulment={currentAnnulment}
      />
    </Offcanvas>
  );
};

export default withAPIRequest(AnnulmentInfoOffset);
