import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import SelectInput from "../../components/form/SelectInput";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Select from "react-select";

const UpdateFamilyHeadFormStep2 = ({
  familyMembers,
  commonAPIRequest,
  formik,
}) => {
  const [maleRelations, setMaleRelations] = useState([]);
  const [femaleRelations, setFemaleRelations] = useState([]);
  const [newRelations, setNewRelations] = useState([]);

  const getAllMaleRelationsList = () => {
    let serviceParams = {
      api: `${API.GET_RELATION}?gender=Male`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          setMaleRelations(
            result.payload.map((relation) => {
              return {
                label: relation.relation,
                value: relation.relation,
              };
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  const getAllFemaleRelationsList = () => {
    let serviceParams = {
      api: `${API.GET_RELATION}?gender=Female`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          setFemaleRelations(
            result.payload.map((relation) => {
              return {
                label: relation.relation,
                value: relation.relation,
              };
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  useEffect(() => {
    getAllFemaleRelationsList();
    getAllMaleRelationsList();
  }, []);

  useEffect(() => {
    formik.setFieldValue("memberRelationDetails", newRelations);
  }, [newRelations]);

  return (
    <div>
      {familyMembers?.map((member) => {
        return (
          <Alert
            key={member.memberId}
            variant={"primary"}
            className="alert-outline d-flex justify-content-between"
          >
            <Row style={{ width: "100%" }}>
              <Col sm={6}>
                <p style={{ fontSize: 24 }} className="m-0 fw-bold">
                  {member?.firstName} {member?.middleName} {member?.lastName}
                </p>
              </Col>
              <Col sm={6}>
                <div>
                  <Form.Label
                    className="mt-2 fw-bold"
                    htmlFor={"relationSelect"}
                  >
                    New Relation
                  </Form.Label>
                  <Select
                    id={"relationSelect"}
                    options={
                      member?.gender === "Male"
                        ? maleRelations
                        : femaleRelations
                    }
                    onChange={(e) => {
                      if (
                        newRelations?.find(
                          (m) => m?.memberId === member?.memberId
                        )
                      ) {
                        setNewRelations((prevValue) =>
                          prevValue?.map((val) =>
                            val.memberId === member?.memberId
                              ? {
                                  memberId: member?.memberId,
                                  relationToHead: e.value,
                                }
                              : val
                          )
                        );
                      } else {
                        setNewRelations((prevValue) => [
                          ...prevValue,
                          {
                            memberId: member?.memberId,
                            relationToHead: e.value,
                          },
                        ]);
                      }
                    }}
                    isSearchable={true}
                  />
                </div>
              </Col>
            </Row>
          </Alert>
        );
      })}
    </div>
  );
};

export default withAPIRequest(UpdateFamilyHeadFormStep2);
