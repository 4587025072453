import React from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";

const AnnulmentFormStep3 = ({ formik, edit }) => {
  return (
    <Row className="g-3">
      <Col sm={4}>
        <TextInput
          label={"Bride's First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideFirstName"}
          value={formik.values.brideFirstName}
          error={formik.errors.brideFirstName}
          touched={formik.touched.brideFirstName}
          disabled={true}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Bride's Middle Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideMiddletName"}
          value={formik.values.brideMiddletName}
          error={formik.errors.brideMiddletName}
          touched={formik.touched.brideMiddletName}
          disabled={true}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Bride's Last Name"}
          placeholder={"e.g. Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideLastName"}
          value={formik.values.brideLastName}
          error={formik.errors.brideLastName}
          touched={formik.touched.brideLastName}
          disabled={true}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Bride's Father's Name"}
          placeholder={"e.g. James Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideFatherName"}
          value={formik.values.brideFatherName}
          error={formik.errors.brideFatherName}
          touched={formik.touched.brideFatherName}
          disabled={true}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Bride's Mother's Name"}
          placeholder={"e.g. Julia Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"brideMotherName"}
          value={formik.values.brideMotherName}
          error={formik.errors.brideMotherName}
          touched={formik.touched.brideMotherName}
          disabled={true}
        />
      </Col>
      <Col sm={12}>
        <TextArea
          label={"Bride's Address"}
          placeholder={"Type Address here..."}
          setFieldValue={formik.setFieldValue}
          keyword={"brideAddress"}
          value={formik.values.brideAddress}
          error={formik.errors.brideAddress}
          touched={formik.touched.brideAddress}
        />
      </Col>
    </Row>
  );
};
export default AnnulmentFormStep3;
