import * as Yup from "yup";

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const DemiseValidationSchema = Yup.object().shape({
  demiseDate: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  burialDate: Yup.date()
    .required("Required")
    .min(Yup.ref("demiseDate"), "Burial date can't be before Demise")
    .max(
      new Date(new Date().setDate(new Date().getDate() + 7)),
      `Date needs to be on or before ${dateFormatter(
        new Date().setDate(new Date().getDate() + 7)
      )}`
    ),
  causeOfDeath: Yup.string().required("Required"),
  burialPlace: Yup.string().required("Required"),
  ministerName: Yup.string().required("Required"),
});
