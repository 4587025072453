import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { PDFViewer } from "@react-pdf/renderer";
import AnnulmentCertificatePdf from "../../components/sacrament-certificates/AnnulmentCertificatePdf";

const GenerateAnnulmentCertificateModal = ({
  show,
  setShow,
  churchDetails,
  currentAnnulment,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Annulment Certificate Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <PDFViewer
            style={{
              width: "100%", // Set width
              height: "80vh", // Set height
            }}
          >
            <AnnulmentCertificatePdf
              details={currentAnnulment}
              churchDetails={churchDetails}
            />
          </PDFViewer>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GenerateAnnulmentCertificateModal;
