import Lottie from "lottie-react";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import SelectInput from "../../components/form/SelectInput";
import { useFormik } from "formik";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import ImageUploadInput from "../../components/form/ImageUploadInput";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const imageTypes = [
  { label: "Cover Image", value: 1 },
  { label: "Watermark", value: 2 },
  { label: "Logo", value: 3 },
];

const UpdateParishImagesModal = ({
  show,
  setShow,
  commonAPIRequest,
  callGetAllChurches,
  churchId,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      imageType: 1,
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const callUpdateChurchImageApi = (churchId, base64, type) => {
    let serviceParams = {
      api: API.CHURCH_IMAGE,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        base64: base64,
        format: type.split("/").pop().toLowerCase(),
        imageType:
          formik.values?.imageType === 1
            ? "image"
            : formik.values?.imageType === 2
            ? "watermark"
            : "logo",
        churchId: churchId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          //  setParishes(result?.payload?.churchList);
          callGetAllChurches();
          setShow(false);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Parish Image Update
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              <SelectInput
                selectOptions={imageTypes}
                setFieldValue={formik.setFieldValue}
                keyword={"imageType"}
                id={"imageType"}
                placeholder={"Select Image Type"}
                value={formik.values.imageType}
                error={formik.errors.imageType}
                touched={formik.touched.imageType}
              />
              {/* <p>
                Current Status Of Parish:{" "}
                <span className="fw-bold text-primary">Active</span>
              </p> */}
              {/* <Col
                sm={12}
                className="justify-content-end align-items-end d-flex"
              >
                <Form.Check
                  type="switch"
                  checked={formik.values.active}
                  label="Active?"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    formik.setFieldValue("active", e.target.checked);
                  }}
                />
              </Col> */}

              <Col className="p-2">
                <ImageUploadInput
                  onImageSelected={(base64, file) =>
                    callUpdateChurchImageApi(churchId, base64, file.type)
                  }
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {/* {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )} */}
    </Modal>
  );
};

export default withAPIRequest(UpdateParishImagesModal);
