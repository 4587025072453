import * as Yup from "yup";

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const HolyOrderValidationSchema = Yup.object().shape({
  diocese: Yup.string().required("Required"),
  dateOfOrdination: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  dateOfFinalVows: Yup.date()
    .required("Required")
    .min(
      Yup.ref("dateOfOrdination"),
      "Final Vows date can't be before Date of ordination"
    )
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
});
