import React from "react";
import churchLogo from "../../assets/img/logo-1.png";
import waterMark from "../../assets/img/holy-comm.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const ExportPDFComponent = () => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark */}
        <View style={styles.watermarkContainer}>
          <Image style={{ width: "50%" }} src={waterMark} />
        </View>

        {/* Certificate Content */}
        <View style={styles.certContainer}>
          <div style={styles.borderGray}>
            <div style={styles.borderRed}>
              {/* Header */}
              <View>
                <View style={styles.header}>
                  <Image style={styles.image} src={churchLogo} />
                  <View style={styles.headerText}>
                    <Text
                      style={{
                        fontSize: 24,
                      }}
                    >
                      St. Mary's Church
                    </Text>
                    <Text style={{ fontSize: 12, marginTop: 5 }}>
                      A-71/A Sector-34 Noida, Uttar-Pradesh 201307
                    </Text>
                  </View>
                </View>

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: "2px solid #d4af37",
                      paddingTop: 8,
                      paddingBottom: 4,
                      margin: 0,
                      fontSize: 13,
                    }}
                  >
                    Extract from the Parochial Register of St Mary Church,
                    Archdiocese Of Delhi
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      textTransform: "uppercase",
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      textDecoration: "underline",
                    }}
                  >
                    Baptism Certificate
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  {/* Dynamic content here */}
                  <Text style={styles.keyText}>
                    Full Name: <Text style={styles.valueText}>Alan J John</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Date of Baptism:{" "}
                    <Text style={styles.valueText}>12/03/2024</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Date of Birth/Age:{" "}
                    <Text style={styles.valueText}>12/03/2024</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Sex: <Text style={styles.valueText}>Male</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Father's Name:{" "}
                    <Text style={styles.valueText}>John Curry</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Mother's Name:{" "}
                    <Text style={styles.valueText}>Jane Curry</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Father's Nationality:{" "}
                    <Text style={styles.valueText}>Indian</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Father's Domicile:{" "}
                    <Text style={styles.valueText}>Delhi</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    God Father's Name:{" "}
                    <Text style={styles.valueText}>Jacob Thomas</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    God Father's Domicile:{" "}
                    <Text style={styles.valueText}>Noida</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    God Mother's Name:{" "}
                    <Text style={styles.valueText}>Jane James</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    God Mother's Domicile:{" "}
                    <Text style={styles.valueText}>Kerela</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Place Of Baptism:{" "}
                    <Text style={styles.valueText}>
                      St. Mary's Church, Noida
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Minister's Name:{" "}
                    <Text style={styles.valueText}>Fr. Joseph</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Date of Confirmation:{" "}
                    <Text style={styles.valueText}>12/03/2024</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Date of Communion:{" "}
                    <Text style={styles.valueText}>12/03/2024</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Remark: <Text style={styles.valueText}>-</Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Reference No.:{" "}
                    <Text style={styles.valueText}>AGR/SMC/BPT000001</Text>
                  </Text>

                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text style={{ fontSize: 12 }}>Parish Priest</Text>
                  <Text style={{ fontSize: 10, color: "#7e7e7e" }}>
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12 }}>Seal</Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.2, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderGray: {
    padding: 5,
    border: "3px solid #58595b",
    height: "100%",
  },
  borderRed: {
    border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    borderColor: "#d4af37",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    backgroundColor: "rgb(240, 229, 207, 0.5)",
    height: "100%",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 10,
  },
  headerText: {
    textAlign: "center",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  image: {
    maxHeight: 80,
    maxWidth: 80,
    marginRight: 10,
  },
  keyText: { fontSize: 12, color: "#7e7e7e", marginBottom: 10 },
  valueText: { fontSize: 12, color: "#000", textTransform: "capitalize" },
});

export default ExportPDFComponent;
