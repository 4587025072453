import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { ThreeCircles } from "react-loader-spinner";
import { AuthContext } from "react-oauth2-code-pkce";

function Signin({ commonAPIRequest }) {
  const navigate = useNavigate();
  const { token, tokenData } = useContext(AuthContext);
  useEffect(() => {
    if (token) {
      navigate("/admin/dashboard", { replace: true });
    }
  }, [token]);

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col className=" d-block">
          <div
            style={{
              position: "relative",
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100,
            }}
          >
            <ThreeCircles
              height="80"
              width="80"
              color="#fff"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withAPIRequest(Signin);
