import { Button } from "react-bootstrap";
import React from "react";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { downloadExcel } from "react-export-table-to-excel";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const SubZoneExcelReport = ({ commonAPIRequest, subZoneId, subZoneName }) => {
  const header = [
    "S.No",
    "Church Family ID",
    "Name",
    "Phone",
    "Email",
    "Address",
  ];

  const getAllFamilyDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY}?pgNum=1&pgSize=1000&subZoneId=${subZoneId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: null,
    };

    // setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          const data = result.payload?.headDetails.map((detail, i) => {
            return {
              sno: i + 1,
              familyId: detail?.churchFamilyId,
              name: `${detail?.firstName} ${detail?.middleName} ${detail?.lastName}`,
              phone: detail?.phNumber,
              email: detail?.emailAddress,
              address: detail?.address?.addressLine1,
            };
          });
          downloadExcel({
            fileName: `Families_in_sub_zones_${subZoneName}`,
            sheet: `families_in_${subZoneName}`,
            tablePayload: {
              header,
              // accept two different data structures
              body: data,
            },
          });
        } catch (error) {
          console.log("error", error);
        }
        //
      } else {
        // setLoading(false);
      }
    });
  };

  function handleDownloadExcel() {
    getAllFamilyDetailsApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(SubZoneExcelReport);
