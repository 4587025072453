import Lottie from "lottie-react";
import React from "react";
import { Badge, Card, Nav } from "react-bootstrap";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";

function isSameDateAsToday(inputDate) {
  const today = new Date();

  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
}

const AnniversarySection = ({ anniversarys }) => {
  return (
    <Card className="card-one">
      <Card.Header>
        <Card.Title as="h6">
          {anniversarys?.length} Anniversary's Today
        </Card.Title>
        <Nav className="nav-icon nav-icon-sm ms-auto">
          <Nav.Link href="" className="nav-link">
            <i className="ri-equalizer-line"></i>
          </Nav.Link>
          <Nav.Link href="" className="nav-link">
            <i className="ri-more-2-fill"></i>
          </Nav.Link>
        </Nav>
      </Card.Header>
      <Card.Body className="p-0">
        {false ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 120, height: 120 }}
                animationData={loadingAnimation}
                loop={true}
              />
            </div>

            <p className="text-center">Loading...</p>
          </div>
        ) : (
          <ul className="people-group">
            {anniversarys.length === 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h4 className="text-center fw-bold">No Anniversary's Today</h4>
              </div>
            ) : (
              anniversarys.map((user, index) => (
                <li className="people-item" key={user.memberId}>
                  <Avatar
                    initial={`${user?.groomFirstName
                      .charAt(0)
                      .toUpperCase()} ${user?.groomLastName
                      .charAt(0)
                      .toUpperCase()}`}
                  />
                  <div className="people-body">
                    <h6>
                      {user?.groomFirstName} {user?.groomLastName} /{" "}
                      {user?.brideFirstName} {user?.brideLastName}
                      {isSameDateAsToday(new Date(user?.marriageDate)) && (
                        <Badge
                          className="d-inline ms-2 text-white fw-bold"
                          bg="primary"
                          pill
                        >
                          Anniversary Today
                        </Badge>
                      )}
                    </h6>
                    <span>
                      {new Date(user.marriageDate).toLocaleDateString("en-GB", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      })}
                    </span>
                  </div>
                  <Nav as="nav" className="nav-icon">
                    {/* <Nav.Link href="">
                      <i className="ri-user-star-line"></i>
                    </Nav.Link> */}
                    <Link
                      to={"/admin/member-details"}
                      state={{ memberId: user.groomMemberId, back: true }}
                    >
                      <i className="ri-contacts-line"></i>
                    </Link>
                  </Nav>
                </li>
              ))
            )}
          </ul>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-center">
        {/* <Link href="" className="fs-sm">
          View more
        </Link> */}
      </Card.Footer>
    </Card>
  );
};

export default AnniversarySection;
