import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import MyTextEditor from "../components/ReactTextEditor";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import SelectInput from "../components/form/SelectInput";
import { Row, Col } from "react-bootstrap";
import {
  admissionLetter,
  burialRequestToAnotherParishPriest,
  burialRequestToCemetery,
  characterDefaultTemplate,
  confirmationLetter,
  marriageNOCLetter,
  marriagePreparationLetter,
  membershipCertificateLetter,
  nocAfterMarriageBanns,
  recommendationLetter,
  recommendationLetterToArchbishop,
  transferDefaultTemplate,
} from "../constants/letter-templates";

const templates = [
  { label: "Transfer", value: 1 },
  { label: "Character Certificate", value: 2 },
  { label: "Member Certificate", value: 3 },
  { label: "Marriage Preparation", value: 4 },
  { label: "Recommendation", value: 5 },
  { label: "Hostel Admission", value: 6 },
  { label: "Marriage NOC", value: 7 },
  { label: "Burial Request To Cemetery", value: 8 },
  { label: "Burial Request To Another Parish", value: 9 },
  { label: "NOC After Marriage Banns", value: 10 },
  { label: "Confirmation Letter", value: 11 },
  { label: "Recommendation To Archbishop", value: 12 },
];

const Letters = () => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { churchDetails } = useSelector((state) => state?.church);
  const [currentTemplate, setCurrentTemplate] = useState(() =>
    transferDefaultTemplate(churchDetails)
  );

  const formik = useFormik({
    initialValues: {
      template: 1,
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  useEffect(() => {
    switch (formik.values?.template) {
      case 1:
        setCurrentTemplate(() => transferDefaultTemplate(churchDetails));
        break;

      case 2:
        setCurrentTemplate(() => characterDefaultTemplate(churchDetails));
        break;

      case 3:
        setCurrentTemplate(() => membershipCertificateLetter(churchDetails));
        break;

      case 4:
        setCurrentTemplate(() => marriagePreparationLetter(churchDetails));
        break;

      case 5:
        setCurrentTemplate(() => recommendationLetter(churchDetails));
        break;

      case 6:
        setCurrentTemplate(() => admissionLetter(churchDetails));
        break;

      case 7:
        setCurrentTemplate(() => marriageNOCLetter(churchDetails));
        break;

      case 8:
        setCurrentTemplate(() => burialRequestToCemetery(churchDetails));
        break;

      case 9:
        setCurrentTemplate(() =>
          burialRequestToAnotherParishPriest(churchDetails)
        );
        break;

      case 10:
        setCurrentTemplate(() => nocAfterMarriageBanns(churchDetails));
        break;

      case 11:
        setCurrentTemplate(() => confirmationLetter(churchDetails));
        break;

      case 12:
        setCurrentTemplate(() =>
          recommendationLetterToArchbishop(churchDetails)
        );
        break;
      default:
        break;
    }
  }, [formik.values?.template]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 ">
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={6}>
            {" "}
            <h5 id="section3" className="main-subtitle m-0">
              Generate Letters
            </h5>
            <p>
              Customizable Letters for{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                {churchDetails?.churchName}
              </span>
              .
            </p>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-end align-items-center">
              <SelectInput
                selectOptions={templates}
                setFieldValue={formik.setFieldValue}
                keyword={"template"}
                id={"template"}
                placeholder={"Select Letter Template"}
                value={formik.values.template}
                error={formik.errors.template}
                touched={formik.touched.template}
              />
            </div>
          </Col>
        </Row>
        <hr className="hr" />
        <MyTextEditor
          currentTemplate={currentTemplate}
          churchDetails={churchDetails}
        />
        {/* <Button variant="danger  mx-4">Print</Button> */}
      </div>

      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default Letters;
