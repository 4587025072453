import { createSlice } from "@reduxjs/toolkit";

const FamilySlice = createSlice({
  name: "family",
  initialState: {
    currentFamilyId: null,
    familyMembers: null,
    basicDetails: null,
    csfDetails: null,
  },
  reducers: {
    setFamilyId(state, action) {
      state.currentFamilyId = action.payload;
    },
    setCurrentFamilyMembers(state, action) {
      state.familyMembers = action.payload;
    },
    setCurrentBasicFamilyDetails(state, action) {
      state.basicDetails = action.payload;
    },
    setCurrentFamilyCsfDetails(state, action) {
      state.csfDetails = action.payload;
    },
    clearFamilies(state) {
      state.currentFamilyId = null;
      state.familyMembers = null;
      state.basicDetails = null;
      state.csfDetails = null;
    },
  },
});

export const FamilyAction = FamilySlice.actions;
export const FamilyReducer = FamilySlice.reducer;
