import React, { useContext, useEffect, useState } from "react";
import Header from "../layouts/Header";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { Button, Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "react-oauth2-code-pkce";
import Swal from "sweetalert2";
import quoteImage from "../assets/img/quote-of the-day.png";

// -------------------------- DATA ------------------------------

import { useDispatch, useSelector } from "react-redux";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import { ChurchAction } from "../store/slices/ChurchSlice";
import BirthdaySection from "./analytics-components/BirthdaySection";
import FinanceSection from "./analytics-components/FinanceSection";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/lottie/loading_animation_delhi_theme.json";
import { AnalyticsAction } from "../store/slices/AnalyticsSlice";
import AnniversarySection from "./analytics-components/AnniversarySection";
import ReactApexChart from "react-apexcharts";
import {
  optionOne,
  optionThree,
  optionTwo,
  seriesOne,
  seriesThree,
  seriesTwo,
} from "../data/DashboardData2";

// -------------------------- DATA ------------------------------

function Analytics({ commonAPIRequest }) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { token, tokenData } = useContext(AuthContext);

  console.log("token-data", tokenData);

  const timestampSecondsNow = Math.floor(Date.now() / 1000);
  console.log(timestampSecondsNow); // Example output: 1716496364

  console.log(
    "you password expiry -->",
    new Date(tokenData?.passExpOn * 1000).toLocaleDateString()
  );

  const dispatch = useDispatch();
  const { present } = useSelector((state) => state.church);
  const { analyticsPresent, analytics } = useSelector(
    (state) => state.analytics
  );
  const [loading, setLoading] = useState(false);

  const [dashboardData, setDashboardData] = useState();

  const callGetChurchDetailsApi = () => {
    let serviceParams = {
      api: API.GET_CHURCH,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(ChurchAction.setChurchDetails(result?.payload));
          console.log("result?.payload", result?.payload);
          localStorage.setItem("churchName", result?.payload?.churchName);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetUserDetailsApi = () => {
    let serviceParams = {
      api: API.GET_USER_INFO,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(ChurchAction.setChurchUserDetails(result?.payload));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetDashboardData = () => {
    let serviceParams = {
      api: API.DASHBOARD_DATA,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setDashboardData(result?.payload);
          dispatch(AnalyticsAction.setAnalytics(result?.payload));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!present) {
      callGetChurchDetailsApi();
      callGetUserDetailsApi();
    }
  }, [present]);

  useEffect(() => {
    if (!analyticsPresent) {
      callGetDashboardData();
    }
  }, [analyticsPresent]);

  useEffect(() => {
    if (tokenData?.passExpOn - timestampSecondsNow < 604800) {
      Swal.fire({
        title: "Update Password!",
        text: `Your password is expiring on ${new Date(
          tokenData?.passExpOn * 1000
        ).toLocaleDateString("en-GB")}`,
        imageUrl: require("../assets/img/pasword-expiry-illustration.png"),
        imageWidth: 300,
        imageHeight: 300,
        imageAlt: "Custom image",
      });
      // Swal.fire(
      //   "Update Password",
      //   `Your password is expiring on ${new Date(
      //     tokenData?.passExpOn * 1000
      //   ).toLocaleDateString("en-GB")}`,
      //   "warning"
      // );
    }
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 ">
        {loading ? (
          <div
            className=" d-flex align-items-center justify-content-center "
            style={{ height: "90vh" }}
          >
            <Lottie
              style={{ width: 160, height: 160 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span style={{ marginLeft: -40 }}>Loading Dashboard...</span>
          </div>
        ) : (
          <Row className="g-3">
            <div className="d-none d-sm-inline">
              <Col
                style={{ borderRadius: 10, backgroundColor: "#e7e7e7" }}
                className="d-flex my-2 p-2 allign-items-center justify-content-between"
              >
                <marquee
                  className="fw-bold mt-2 me-3"
                  behavior="scroll"
                  direction="left"
                  scrollamount="5"
                >
                  For God so loved the world that he gave his one and only Son,
                  that whoever believes in him shall not perish but have eternal
                  life. <span className="fw-light">- John [3:16]</span>
                </marquee>

                <Button onClick={() => callGetDashboardData()} variant="light">
                  <i className="ri-refresh-line"></i>
                </Button>
              </Col>
            </div>

            <div className="d-sm-none pt-0  ">
              <Col>
                <img
                  className="mb-3"
                  style={{ borderRadius: 10, width: "100%" }}
                  src={quoteImage}
                  alt="quoteimage"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 120,
                    // backgroundColor: "red",
                    width: "50%",
                  }}
                  className="text-white ms-3"
                >
                  For God so loved the world that he gave his one and only Son,
                  that whoever believes in him shall not perish but have eternal
                  life.
                  <div className="d-flex justify-content-end">
                    <span className="fw-light">- John [3:16]</span>
                  </div>
                </div>
              </Col>
            </div>

            <FinanceSection data={analytics?.financialsInfo} />
            {/*  */}

            {[
              {
                label: "Baptism's Done",
                icon: "ri-shopping-bag-3-line",
                value: analytics?.sacramentsInfo?.baptismsThisMonth,
                percent: "0.7",
                status: "down",
              },
              {
                label: "Holy Communion's Done",
                icon: "ri-briefcase-4-line",
                value: analytics?.sacramentsInfo?.holyCommunionsThismonth,
                percent: "2.1",
                status: "up",
              },
              {
                label: "Holy Confirmation's Done",
                icon: "ri-inbox-line",
                value: analytics?.sacramentsInfo?.confirmationsThisMonth,
                percent: "0.3",
                status: "down",
              },
              {
                label: "Marriage's Done",
                icon: "ri-bar-chart-box-line",
                value: analytics?.sacramentsInfo?.marriagesThisMonth,
                percent: "1.2",
                status: "up",
              },
            ].map((card, index) => (
              <Col xs="6" xl="3" key={index}>
                <Card className="card-one">
                  <Card.Body>
                    <Card.Title as="label" className="fs-sm fw-medium mb-1">
                      {card.label}
                    </Card.Title>
                    <h3 className="card-value mb-1">
                      <i className={card.icon}></i> {card.value}
                    </h3>
                    <small>
                      {new Date().toLocaleDateString("en-IN", {
                        month: "long",
                        year: "numeric",
                      })}
                    </small>
                  </Card.Body>
                </Card>
              </Col>
            ))}

            {/*  */}

            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.familyCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Families Registered
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of active families registered in the
                        parish.
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesOne}
                        options={optionOne}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.memberCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Parishioner's Registered
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of active members registered in the parish.
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesTwo}
                        options={optionTwo}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.youthCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Youth Population
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of youth in the parish
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesThree}
                        options={optionThree}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md="6" xl="3">
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="7">
                      <h3 className="card-value mb-1">
                        {analytics?.memberStatistics?.seniorCount}
                      </h3>
                      <label className="card-title fw-medium text-dark mb-1">
                        Total Senior Citizen's
                      </label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">
                        Total number of senior citizen's in the parish.
                      </span>
                    </Col>
                    <Col xs="5">
                      <ReactApexChart
                        series={seriesThree}
                        options={optionThree}
                        type="bar"
                        height={70}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* <Col md="6" xl="4">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Sacrament Data</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <div className="chart-donut-two mb-4">
                    <Doughnut data={dataPie} options={optionPie} />
                  </div>

                  <Row className="g-4 mb-2">
                    {[
                      {
                        name: "Baptism",
                        value: "650",
                        percent: "40%",
                        progress: 60,
                      },
                      {
                        name: "Holy Communion",
                        value: "450",
                        percent: "25%",
                        progress: 75,
                        variant: "ui-02",
                      },
                      {
                        name: "Holy Confirmation",
                        value: "300",
                        percent: "20%",
                        progress: 80,
                        variant: "gray-700",
                      },
                      {
                        name: "Marrriage",
                        value: "250",
                        percent: "15%",
                        progress: 85,
                        variant: "gray-500",
                      },
                    ].map((item, index) => (
                      <Col xs="6" key={index}>
                        <label className="fs-sm fw-medium mb-1">
                          {item.name}
                        </label>
                        <h3 className="card-value mb-2">
                          {item.value} <small>{item.percent}</small>
                        </h3>
                        <ProgressBar
                          now={item.progress}
                          className="ht-5 mb-0"
                          variant={item.variant}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}

            {/*  */}
            {/* <Col xl="4">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Events This Month</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <ul className="events-list">
                    {[
                      {
                        active: true,
                        date: {
                          day: "Sat",
                          num: "03",
                        },
                        events: [
                          {
                            time: "08:00am - 10:30am",
                            title: "Workshop",
                            text: "Duis aute irure dolor in repre hen derit in volup tate velit esse cillum.",
                          },
                        ],
                      },
                      {
                        date: {
                          day: "Wed",
                          num: "07",
                        },
                        events: [
                          {
                            time: "08:00am - 11:30am",
                            title: "5th Religious Conference",
                            text: "Excep teur sint occae cat cupi datat non proident sunt in culpa qui.",
                          },
                          {
                            time: "1:30pm - 5:30pm",
                            title: "Church Workshop Events",
                            text: "Datat non proident sunt in culpa qui.",
                          },
                        ],
                      },
                      {
                        date: {
                          day: "Thu",
                          num: "08",
                        },
                        events: [
                          {
                            time: "08:30am - 03:30pm",
                            title: "Recollection Meetup",
                            text: "Sed ut perspi ciatis unde omnis iste natus error sit volup tatem.",
                          },
                        ],
                      },
                      {
                        date: {
                          day: "Mon",
                          num: "23",
                        },
                        events: [
                          {
                            time: "09:00am - 05:30pm",
                            title: "Golden Autumn Festival",
                          },
                        ],
                      },
                    ].map((item, index) => (
                      <li key={index} className={item.active ? "active" : ""}>
                        <div className="event-date">
                          <small>{item.date.day}</small>
                          <h5>{item.date.num}</h5>
                        </div>
                        <div className="events-body">
                          {item.events.map((event, ind) => (
                            <div key={ind} className="ev-item">
                              <small>{event.time}</small>
                              <h6>{event.title}</h6>
                              {event.text && <p>{event.text}</p>}
                            </div>
                          ))}
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col> */}

            {/*  */}

            <Col lg={"6"}>
              <BirthdaySection
                birthdays={
                  analytics?.birthdayDetailList
                    ? analytics?.birthdayDetailList
                    : []
                }
              />
            </Col>

            <Col lg={"6"}>
              <AnniversarySection
                anniversarys={
                  analytics?.anniversaryDetailList
                    ? analytics?.anniversaryDetailList
                    : []
                }
              />
            </Col>

            {/*  */}
            {/* <Col md="6" xl="4">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Expense History</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-0">
                  <ul className="people-group">
                    {[
                      {
                        bg: "teal",
                        icon: "ri-shopping-cart-line",
                        label: "Purchase from #10322",
                        date: "Oct 21, 2023, 3:30pm",
                        value: "+ $250.00",
                        status: "Completed",
                        color: "success",
                      },
                      {
                        bg: "info",
                        icon: "ri-coins-line",
                        label: "Process refund to #00910",
                        date: "Oct 19, 2023, 3:30pm",
                        value: "- $16.50",
                        status: "Processing",
                        color: "warning",
                      },
                      {
                        bg: "primary",
                        icon: "ri-truck-line",
                        label: "Process delivery to #44333",
                        date: "Oct 18, 2023, 6:18pm",
                        value: "3 Items",
                        status: "For pickup",
                        color: "info",
                      },
                      {
                        bg: "pink",
                        icon: "ri-truck-line",
                        label: "Payment from #023328",
                        date: "Oct 18, 2023, 12:40pm",
                        value: "+ $129.50",
                        status: "Completed",
                        color: "success",
                      },
                      {
                        bg: "secondary",
                        icon: "ri-secure-payment-line",
                        label: "Payment failed #087651",
                        date: "Oct 15, 2023, 08:09am",
                        value: "$150.20",
                        status: "Declined",
                        color: "danger",
                      },
                    ].map((item, index) => (
                      <li className="people-item" key={index}>
                        <div className="avatar">
                          <span
                            className={"avatar-initial fs-20 bg-" + item.bg}
                          >
                            <i className={item.icon}></i>
                          </span>
                        </div>
                        <div className="people-body">
                          <h6>
                            <Link to="">{item.label}</Link>
                          </h6>
                          <span>{item.date}</span>
                        </div>
                        <div className="text-end">
                          <div className="fs-sm">{item.value}</div>
                          <span className={"d-block fs-xs text-" + item.color}>
                            {item.status}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-center">
                  <Link to="" className="fs-sm">
                    Manage Transactions
                  </Link>
                </Card.Footer>
              </Card>
            </Col> */}
            {/*  */}
            {/* <Col md="6" xl="8">
              <Card className="card-example">
                <Card.Body>
                  <Line
                    data={dataLine2}
                    options={optionLine2}
                    className="ht-300"
                  />
                </Card.Body>
                <Card.Footer>
                  <pre>
                    <p>Families and Parishioner's registered each month.</p>
                  </pre>
                </Card.Footer>
              </Card>
            </Col> */}
          </Row>
        )}

        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}

export default withAPIRequest(Analytics);
