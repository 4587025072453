var data = [
  [0, 9],
  [1, 7],
  [2, 4],
  [3, 8],
  [4, 4],
  [5, 12],
  [6, 4],
  [7, 6],
  [8, 5],
  [9, 10],
  [10, 4],
  [11, 5],
  [12, 10],
  [13, 2],
  [14, 6],
];

const chart = {
  parentHeightOffset: 0,
  stacked: true,
  sparkline: {
    enabled: true,
  },
};

const states = {
  hover: {
    filter: {
      type: "none",
    },
  },
  active: {
    filter: {
      type: "none",
    },
  },
};

const plotOptions = {
  bar: {
    columnWidth: "60%",
  },
};

const stroke = {
  curve: "straight",
  lineCap: "square",
};

export const seriesOne = [
  {
    type: "column",
    data: [
      [0, 0],
      [1, 0],
      [2, 5],
      [3, 10],
      [4, 6],
      [5, 10],
      [6, 15],
      [7, 18],
      [8, 7],
      [9, 11],
      [10, 13],
      [11, 15],
      [12, 13],
      [13, 7],
      [14, 5],
    ],
  },
  {
    type: "column",
    data: data,
  },
];

export const optionOne = {
  chart: chart,
  states: states,
  colors: ["#912e2d", "#e5e9f2"],
  plotOptions: plotOptions,
  stroke: stroke,
  fill: { opacity: 1 },
  tooltip: { enabled: false },
};

export const seriesTwo = [
  {
    type: "column",
    data: [
      [0, 3],
      [1, 4],
      [2, 12],
      [3, 6],
      [4, 16],
      [5, 5],
      [6, 8],
      [7, 4],
      [8, 3],
      [9, 6],
      [10, 4],
      [11, 10],
      [12, 3],
      [13, 7],
      [14, 10],
    ],
  },
  {
    type: "column",
    data: data,
  },
];

export const optionTwo = {
  chart: chart,
  states: states,
  colors: ["#912e2d", "#e5e9f2"],
  plotOptions: plotOptions,
  stroke: stroke,
  fill: { opacity: 1 },
  tooltip: { enabled: false },
};

export const seriesThree = [
  {
    type: "column",
    data: [
      [0, 5],
      [1, 10],
      [2, 20],
      [3, 15],
      [4, 6],
      [5, 10],
      [6, 15],
      [7, 18],
      [8, 7],
      [9, 11],
      [10, 13],
      [11, 15],
      [12, 13],
      [13, 7],
      [14, 5],
    ],
  },
  {
    type: "column",
    data: data,
  },
];

export const optionThree = {
  chart: chart,
  states: states,
  colors: ["#912e2d", "#e5e9f2"],
  plotOptions: plotOptions,
  stroke: stroke,
  fill: { opacity: 1 },
  tooltip: { enabled: false },
};
