import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { useFormik } from "formik";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Swal from "sweetalert2";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { ExtAnnulmentValidationSchema } from "../../validations/annulment";
import { useSelector } from "react-redux";

const AddExternalAnnulmentDetailsModal = ({
  show,
  setShow,
  basicDetails,
  marriageDetails,
  commonAPIRequest,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const { churchDetails } = useSelector((state) => state.church);

  const formik = useFormik({
    initialValues: {
      marriageDate: "",
      annulmentDate: "",
      extAnnulmentChurch: "",
      extAnnulmentCity: "",
      annulmentDateNotSure: false,
    },
    validationSchema: ExtAnnulmentValidationSchema,
    onSubmit: () => {
      callAddAnnulmentDetailsApi();
    },
  });

  // function to call API for Add Annulment start

  const callAddAnnulmentDetailsApi = () => {
    let serviceParams = {
      api: API.EXTERNAL_ANNULMENT_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        annulmentDetail: {
          annulmentDate: formik.values.annulmentDate,
          groomFirstName: marriageDetails?.groomFirstName,
          groomMiddleName: marriageDetails?.groomMiddleName,
          groomLastName: marriageDetails?.groomLastName,
          brideFirstName: marriageDetails?.brideFirstName,
          brideMiddleName: marriageDetails?.brideMiddleName,
          brideLastName: marriageDetails?.brideLastName,
          extAnnulment: true,
          extAnnulmentChurch: formik.values.extAnnulmentChurch,
          extAnnulmentCity: formik.values.extAnnulmentCity,
          annulmentDateAccurate: !formik.values.annulmentDateNotSure,
        },
        marriageId: marriageDetails?.marriageId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Annulment Details Added Successfully.`,
            "success"
          );
          getFamilyMemberDetails();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add Annulment end

  useEffect(() => {
    if (show) {
      formik.setFieldValue(
        "marriageDate",
        new Date(marriageDetails?.marriageDate)
      );
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Annulment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row>
            <p className="text-muted">
              As {basicDetails?.firstName} {basicDetails?.firstName}{" "}
              {basicDetails?.middleName}'s annulment was done{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                outside of {churchDetails?.churchName}.
              </span>{" "}
              <br /> Please Enter these basic annulment details for the records.
            </p>
            <Col sm={12}>
              <DateInput
                label={"Annulment Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"annulmentDate"}
                value={formik.values.annulmentDate}
                error={formik.errors.annulmentDate}
                touched={formik.touched.annulmentDate}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if annulment date is correct"
                onChange={(e) => {
                  formik.setFieldValue(
                    "annulmentDateNotSure",
                    e.target.checked
                  );
                }}
                checked={formik.values.annulmentDateNotSure}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"Annulment Church Name"}
                placeholder={"e.g. St. Mary's Church"}
                setFieldValue={formik.setFieldValue}
                keyword={"extAnnulmentChurch"}
                value={formik.values.extAnnulmentChurch}
                error={formik.errors.extAnnulmentChurch}
                touched={formik.touched.extAnnulmentChurch}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Annulment Church City"}
                setFieldValue={formik.setFieldValue}
                keyword={"extAnnulmentCity"}
                value={formik.values.extAnnulmentCity}
                error={formik.errors.extAnnulmentCity}
                touched={formik.touched.extAnnulmentCity}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddExternalAnnulmentDetailsModal);
