import React from "react";
import { Form } from "react-bootstrap";

const ImageUploadInput = ({ onImageSelected, value }) => {
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    onImageSelected(base64, file);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        // Remove the 'data:image/png;base64,' prefix from the result
        const base64Result = fileReader.result.replace(
          /^data:[a-z]+\/[a-z]+;base64,/,
          ""
        );
        resolve(base64Result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Form.Group controlId="church_image" className="mb-3">
      {/* <Form.Label>Default file input example</Form.Label> */}
      <Form.Control
        type="file"
        id="church_image"
        name="church_image"
        accept="image/png, image/jpeg"
        onChange={(e) => {
          uploadImage(e);
        }}
      />
    </Form.Group>
  );
};

export default ImageUploadInput;
