import React, { useEffect, useState } from "react";
import { Col, Offcanvas, ProgressBar, Row, Button } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { ThreeCircles } from "react-loader-spinner";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import EditMarriageDetailsModal from "../../modals/marriage/EditMarriageDetailsModal";

const MarriageInfoOffset = ({
  show,
  setShow,
  marriageId,
  commonAPIRequest,
  holyConfirmationDetails,
  basicDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentMarriage, setCurrentMarriage] = useState();
  const [showEditMarriageModal, setShowEditMarriageModal] = useState(false);

  const callGetMarriageDetails = () => {
    let serviceParams = {
      api: `${API.MARRIAGE_DETAILS}?marriageId=${marriageId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCurrentMarriage(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      callGetMarriageDetails();
    }
  }, [show]);

  console.log("marriage-setails", currentMarriage);

  return (
    <Offcanvas scroll show={show} onHide={() => setShow(false)} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          Marriage Details <br />
          <p className="fw-bold m-0 text-primary">
            {currentMarriage?.referenceNum}
          </p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Button
        className="mx-3"
        onClick={() => {
          setShowEditMarriageModal(true);
        }}
      >
        {" "}
        Edit
      </Button>
      <hr className="hr" />
      <Offcanvas.Body>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThreeCircles
              height="40"
              width="40"
              color="#303033"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <Row>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's First Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.groomFirstName}
                </p>
              </div>
            </Col>
            {currentMarriage?.groomMiddleName && (
              <Col sm={6}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Groom's Middle Name</strong>
                  </p>
                  <p
                    style={{ fontSize: 18, fontWeight: "lighter" }}
                    className="mb-0"
                  >
                    {currentMarriage?.groomMiddleName}
                  </p>
                </div>
              </Col>
            )}
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Last Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.groomLastName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Father Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.groomFatherName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Mother Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.groomMotherName}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Nationality</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.groomNationality}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Domicile</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.groomDomicile}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Groom's Profession</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.groomProfession}
                </p>
              </div>
            </Col>
            <ProgressBar now="100" className="h-1 my-2" />
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's First Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.brideFirstName}
                </p>
              </div>
            </Col>
            {currentMarriage?.brideMiddleName && (
              <Col sm={6}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Bride's Middle Name</strong>
                  </p>
                  <p
                    style={{ fontSize: 18, fontWeight: "lighter" }}
                    className="mb-0"
                  >
                    {currentMarriage?.brideMiddleName}
                  </p>
                </div>
              </Col>
            )}
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Last Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.brideLastName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Father Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.brideFatherName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Mother Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.brideMotherName}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Nationality</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.brideNationality}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Domicile</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.brideDomicile}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Bride's Profession</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.brideProfession}
                </p>
              </div>
            </Col>

            <ProgressBar now="100" className="h-1 my-2" />

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>1st Banns Date</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {new Date(currentMarriage?.firstBannsDate).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>2nd Banns Date</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {new Date(
                    currentMarriage?.secondBannsDate
                  ).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>3rd Banns Date</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {new Date(currentMarriage?.thirdBannsDate).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Witness 1 First Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.firstWitnessFirstName}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Witness 1 Last Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.firstWitnessLastName}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Witness 1 Domicile</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.firstWitnessDomicile}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Witness 2 First Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.secondWitnessFirstName}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Witness 2 Last Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.secondWitnessLastName}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Witness 2 Domicile</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.secondWitnessDomicile}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Marriage Place</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.marriagePlace}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Minister's Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentMarriage?.ministerName}
                </p>
              </div>
            </Col>
          </Row>
        )}
      </Offcanvas.Body>

      <EditMarriageDetailsModal
        show={showEditMarriageModal}
        setShow={setShowEditMarriageModal}
        holyConfirmationDetails={holyConfirmationDetails}
        currentMarriage={currentMarriage}
        basicDetails={basicDetails}
        callGetMarriageDetails={callGetMarriageDetails}
      />
    </Offcanvas>
  );
};

export default withAPIRequest(MarriageInfoOffset);
