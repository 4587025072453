import React from "react";
import { Alert, Button } from "react-bootstrap";

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const UpdateFamilyHeadFormStep1 = ({
  familyMembers,
  formik,
  setCurrentState,
}) => {
  return (
    <div>
      <div>
        {familyMembers.map((member) => (
          <Alert
            key={member?.memberId}
            variant="primary"
            className="d-flex justify-content-between"
          >
            <p className="m-0">
              <strong>
                {member.middleName
                  ? `${member.firstName} ${member.middleName} ${member.lastName}   `
                  : `${member.firstName} ${member.lastName}  `}
              </strong>
              <span
                className={
                  getAge(member?.dateOfBirth) > 18
                    ? "text-primary fw-bold"
                    : "text-danger fw-bold"
                }
              >
                ({getAge(member?.dateOfBirth) + " Years Old"})
              </span>
              <br />({member?.relationToHead})
              <br />
            </p>

            <Button
              onClick={() => {
                formik.setFieldValue("newHeadMemberId", member?.memberId);
                setCurrentState(2);
              }}
              disabled={getAge(member?.dateOfBirth) < 18}
              variant={getAge(member?.dateOfBirth) > 18 ? "primary" : "danger"}
            >
              Select
            </Button>
          </Alert>
        ))}
      </div>
    </div>
  );
};

export default UpdateFamilyHeadFormStep1;
