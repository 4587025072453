import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { SubZoneValidationSchema } from "../../validations/zones";
import { Button, Modal } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const EditSubZonesModal = ({
  show,
  setShow,
  commonAPIRequest,
  subZone,
  callGetSubZones,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      address: "",
    },
    validationSchema: SubZoneValidationSchema,
    onSubmit: (values) => {
      callEditSubZones(values);
    },
  });

  //   call update subzone api start

  const callEditSubZones = (value) => {
    let serviceParams = {
      api: API.UPDATE_SUB_ZONES,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        subZoneDetail: {
          subZoneId: subZone?.subZoneId,
          address: value.address,
        },
      },
    };

    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Subzone Updated Successfully.`, "success");
          callGetSubZones();

          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call update subzone api end

  useEffect(() => {
    if (show) {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          formik.handleSubmit();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      formik.setFieldValue("address", subZone?.subZoneName);
    }
  }, [show]);

  return (
    <Modal
      className="bg-primary"
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Sub Zone
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <div className="mb-3">
              <TextInput
                label={"Subzone Name"}
                placeholder={"e.g. Sector 11"}
                id={"address"}
                setFieldValue={formik.setFieldValue}
                keyword="address"
                value={formik.values.address}
                error={formik.errors.address}
                touched={formik.touched.address}
              />
            </div>
          </div>
        )}
      </Modal.Body>

      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditSubZonesModal);
