import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const alphabetsOnly = /^[a-zA-Z][a-zA-Z\. ]*$/;

export const ZoneValidationSchema = Yup.object().shape(
  {
    zoneName: Yup.string()
      .required("Required")
      .matches(alphabetsOnly, "Only alphabets are allowed."),
    zoneRepName: Yup.string()
      .matches(alphabetsOnly, "Only alphabets are allowed.")
      .notRequired(),
    zoneRepPhNum: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(10, "Atmost 10 - Digits")
      .min(10, "Atleast 10 - Digits")
      .notRequired(),
    zoneSecyName: Yup.string()
      .matches(alphabetsOnly, "Only alphabets are allowed.")
      .notRequired(),
    zoneCoordPhNum: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(10, "Atmost 10 - Digits")
      .min(10, "Atleast 10 - Digits")
      .notRequired(),
    zoneCoordName: Yup.string()
      .matches(alphabetsOnly, "Only alphabets are allowed.")
      .notRequired(),
    zoneSecyPhNum: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(10, "Atmost 10 - Digits")
      .min(10, "Atleast 10 - Digits")
      .notRequired(),
  },
  [
    // Add Cyclic deps here because when require itself
    ["zoneCoordPhNum"],
  ]
);

export const SubZoneValidationSchema = Yup.object().shape({
  address: Yup.string()
    .required("Required")
    .matches(/^[a-zA-Z\d\-_\s]+$/, "No special character allowed."),
});
