import React from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";

const AnnulmentFormStep2 = ({ formik, edit }) => {
  return (
    <Row className="g-3">
      <Col sm={4}>
        <TextInput
          label={"Groom's First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomFirstName"}
          value={formik.values.groomFirstName}
          error={formik.errors.groomFirstName}
          touched={formik.touched.groomFirstName}
          disabled={true}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Groom's Middle Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomMiddleName"}
          value={formik.values.groomMiddleName}
          error={formik.errors.groomMiddleName}
          touched={formik.touched.groomMiddleName}
          disabled={true}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Groom's Last Name"}
          placeholder={"e.g. Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomLastName"}
          value={formik.values.groomLastName}
          error={formik.errors.groomLastName}
          touched={formik.touched.groomLastName}
          disabled={true}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Groom's Father's Name"}
          placeholder={"e.g. James Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomFatherName"}
          value={formik.values.groomFatherName}
          error={formik.errors.groomFatherName}
          touched={formik.touched.groomFatherName}
          disabled={true}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Groom's Mother's Name"}
          placeholder={"e.g. Julia Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomMotherName"}
          value={formik.values.groomMotherName}
          error={formik.errors.groomMotherName}
          touched={formik.touched.groomMotherName}
          disabled={true}
        />
      </Col>
      <Col sm={12}>
        <TextArea
          label={"Groom's Address"}
          placeholder={"Type Address here ..."}
          setFieldValue={formik.setFieldValue}
          keyword={"groomAddress"}
          value={formik.values.groomAddress}
          error={formik.errors.groomAddress}
          touched={formik.touched.groomAddress}
        />
      </Col>
    </Row>
  );
};

export default AnnulmentFormStep2;
