import { createSlice } from "@reduxjs/toolkit";

const HolyConfirmationsSlice = createSlice({
  name: "holyConfirmations",
  initialState: {
    present: false,
    holyConfirmations: [],
    totalCount: 0,
    pageNumber: 1,
  },
  reducers: {
    getHolyConfirmations(state, action) {
      state.holyConfirmations = action.payload;
      state.present = true;
    },
    getTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    clearHolyCommunions(state, action) {
      state.holyConfirmations = [];
      state.present = false;
    },
  },
});

export const HolyConfirmationsAction = HolyConfirmationsSlice.actions;
export const HolyConfirmationsReducer = HolyConfirmationsSlice.reducer;
