import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import TextArea from "../../components/form/TextArea";
import { useFormik } from "formik";
import { DemiseValidationSchema } from "../../validations/demise";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";

const AddDemiseDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  getFamilyMemberDetails,
  memberId,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      demiseDate: "",
      burialDate: "",
      causeOfDeath: "",
      burialPlace: "",
      ministerName: "",
      remark: "",
    },
    validationSchema: DemiseValidationSchema,
    onSubmit: (values) => {
      callAddDemiseDetails();
    },
  });

  // function to call API for Add Demise start

  const callAddDemiseDetails = () => {
    let serviceParams = {
      api: API.ADD_DEMISE_DETAILS,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        deathDetail: {
          deathDate: formik.values.demiseDate,
          burialDate: formik.values.burialDate,
          deathCause: formik.values.causeOfDeath,
          burialPlace: formik.values.burialPlace,
          ministerName: formik.values.ministerName,
          remark: formik.values.remark,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Demise Details Added Successfully.`, "success");
          getFamilyMemberDetails();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add Demise end

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Demise Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row>
            <Col sm={6}>
              <DateInput
                label={"Demise Date"}
                placeholder={"Select Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"demiseDate"}
                value={formik.values.demiseDate}
                error={formik.errors.demiseDate}
                touched={formik.touched.demiseDate}
              />
            </Col>
            <Col sm={6}>
              <DateInput
                label={"Burial Date"}
                placeholder={"Select Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"burialDate"}
                value={formik.values.burialDate}
                error={formik.errors.burialDate}
                touched={formik.touched.burialDate}
              />
            </Col>
            <Col sm={12}>
              <TextInput
                label={"Cause Of Death"}
                placeholder={"e.g. Accident"}
                setFieldValue={formik.setFieldValue}
                keyword={"causeOfDeath"}
                value={formik.values.causeOfDeath}
                error={formik.errors.causeOfDeath}
                touched={formik.touched.causeOfDeath}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Burial Place"}
                placeholder={"e.g. Noida"}
                setFieldValue={formik.setFieldValue}
                keyword={"burialPlace"}
                value={formik.values.burialPlace}
                error={formik.errors.burialPlace}
                touched={formik.touched.burialPlace}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Minister Name"}
                placeholder={"Father's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"ministerName"}
                value={formik.values.ministerName}
                error={formik.errors.ministerName}
                touched={formik.touched.ministerName}
              />
            </Col>
            <Col col={12}>
              <TextArea
                label={"Remark"}
                placeholder={"Add remark here..."}
                setFieldValue={formik.setFieldValue}
                keyword={"remark"}
                value={formik.values.remark}
                error={formik.errors.remark}
                touched={formik.touched.remark}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddDemiseDetailsModal);
