import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const PreMarriageDetailsModal = ({
  show,
  setShow,
  basicDetails,
  setImportMemberModalVisible,
  setChooseMarriageModalVisible,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
      className="bg-primary"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Pre Marriage Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="fw-bold">
          Is{" "}
          <span className="text-primary">
            {basicDetails?.firstName} {basicDetails?.middleName}{" "}
            {basicDetails?.lastName}'s
          </span>{" "}
          wife a part of his family?
        </h5>
        <p>
          If YES, move on to the next step (By Pressing Yes).
          <br />
          If No, Please add your wife to your family or{" "}
          <span className="fw-bold">import</span> if she is already a member of
          this parish.
        </p>
        <Button
          className="bg-dark"
          onClick={() => {
            if (basicDetails?.relationToHead === "HUSBAND") {
              Swal.fire("Info", "Wife already present in family.", "info");
              return;
            }
            setImportMemberModalVisible(true);
            setShow(false);
          }}
        >
          Import
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            Swal.fire(
              "Info",
              `Please add your wife to your family or import if she is already a member of this parish.`,
              "info"
            );
          }}
        >
          No
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setChooseMarriageModalVisible(true);
            setShow(false);
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreMarriageDetailsModal;
