import React, { useEffect, useState } from "react";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../layouts/Header";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ZoneFamilyTablePlaceholder from "../../components/placeholders/ZoneFamilyTablePlaceholder";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import SubZoneExcelReport from "../../components/excel-reports/SubZoneExcelReport";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";

const SubZonesFamilies = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [families, setFamilies] = useState([]);
  const location = useLocation();

  const getAllFamilyDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY}?pgNum=1&pgSize=1000&subZoneId=${location.state.subZoneId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: null,
    };

    setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setFamilies(
            result.payload.headDetails ? result.payload.headDetails : []
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (location.state) {
      getAllFamilyDetailsApi();
    }
  }, [location.state]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4">
        <h5
          id="section3"
          className="main-subtitle  d-flex justify-content-between align-items-center"
        >
          <div>
            <Button
              onClick={() => navigate("/admin/zones")}
              className="me-2"
              variant="outline"
            >
              <i className="ri-arrow-left-line"></i>
            </Button>
            Families in {location.state.subZoneName}
          </div>
          {families.length !== 0 && (
            <div className="d-none d-lg-inline">
              <SubZoneExcelReport
                subZoneId={location.state.subZoneId}
                subZoneName={location.state.subZoneName}
              />
            </div>
          )}
        </h5>
        {/* <p>
          Add <code>hover</code> props to enable a hover state on table rows
          within a tbody.
        </p> */}

        <Card className="card-example">
          <Card.Body className="d-lg-none pt-0 ">
            <Row className="g-2 g-xxl-3 mb-5">
              {families?.map((data, index) => (
                <Col sm="6" md="4" key={index}>
                  <Card className="card-people shadow mt-3">
                    <Card.Body>
                      <Badge
                        style={{ position: "absolute", top: 10, right: 10 }}
                        pill
                        className="fw-bold"
                        bg="primary"
                      >
                        Head Of Family
                      </Badge>
                      <div className="d-flex align-items-center">
                        <img
                          style={{ maxWidth: 48 }}
                          src={data.gender === "Female" ? femaleImg : maleImg}
                          className="img-fluid text-center"
                          alt="..."
                        />
                        <div className="ms-2">
                          <h6 className="mt-3 fw-bold text-primary">
                            <Link to="">
                              {data.middleName
                                ? `${data.firstName} ${data.middleName} ${data.lastName}`
                                : `${data.firstName} ${data.lastName}`}
                            </Link>
                          </h6>
                          <p className="mb-0 ">
                            {data.emailAddress ? data.emailAddress : "-"}
                          </p>
                          <p>{data?.phNumber ? data?.phNumber : "-"}</p>
                        </div>
                      </div>
                      <hr className="mt-0" />
                      <div>
                        <span style={{ fontSize: 12 }} className="fw-bold">
                          Address:
                        </span>
                        <p className="mb-0">{data?.address?.addressLine1}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
          <Card.Body className="d-none d-lg-inline">
            <Table hover className="mb-0">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email Address</th>
                </tr>
              </thead>
              {loading && <ZoneFamilyTablePlaceholder />}

              <tbody>
                {families.length !== 0 &&
                  !loading &&
                  families.map((data, i) => {
                    return (
                      <tr key={data.memberId}>
                        <th scope="row">{i + 1}</th>
                        <td
                          style={{ cursor: "pointer" }}
                          className="text-primary fw-bold"
                          onClick={() => {
                            navigate("/admin/family-details", {
                              state: {
                                familyId: data.familyId,
                              },
                            });
                            localStorage.setItem(
                              "currentFamilyId",
                              data?.familyId
                            );
                            localStorage.setItem(
                              "currentChurchFamilyId",
                              data?.churchFamilyId
                            );
                          }}
                        >
                          {data?.firstName} {data?.middleName} {data?.lastName}
                        </td>
                        <td>{data?.phNumber}</td>
                        <td>{data?.emailAddress}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {!loading && families.length === 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h3 className="text-center fw-bold">No Families Found</h3>
              </div>
            )}
          </Card.Body>
          {/* <Card.Footer>
            <pre>
              <code className="language-html">{`<Table hover className="mb-0">...</Table`}</code>
            </pre>
          </Card.Footer> */}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withAPIRequest(SubZonesFamilies);
