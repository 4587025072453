import React, { useState } from "react";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { Badge, Button, Spinner } from "react-bootstrap";
import CustomTooltip from "../../../components/CustomTooltip";
import { ThreeCircles } from "react-loader-spinner";
import { Link } from "react-router-dom";
import GenerateAnnulmentCertificateModal from "../../../modals/annulment/GenerateAnnulmentCertificateModal";
import { useDispatch, useSelector } from "react-redux";
import AnnulmentLinkCertificateModal from "../../../modals/annulment/AnnulmentLinkCertificateModal";
import warningimg from "../../../assets/img/warning.png";
import EditOutsidersAnnulmentDetailsModal from "../../../modals/annulment/EditOutsidersAnnulmentDetailsModal";
import Swal from "sweetalert2";

const AnnulmentListRow = ({ commonAPIRequest, annulment, i }) => {
  const [certPdf, setCertPdf] = useState();
  const [loading, setLoading] = useState(false);
  const [generateAnnulmentModal, setGenerateAnnulmentModal] = useState(false);
  const [currentAnnulment, setCurrentAnnulment] = useState();
  const { churchDetails } = useSelector((state) => state?.church);
  const [annulmentLinkCertModal, setAnnulmentLinkCertModal] = useState(false);
  const [showEditAnnulmentModal, setShowEditAnnulmentModal] = useState(false);
  // call generate pdf certificate api for annulment start
  const [loading2, setLoading2] = useState(false);

  const callGetAnnulmentCertificateApi = (id) => {
    let serviceParams = {
      api: `${API.GENERATE_ANNULMENT_CERTIFICATE_API}?annulmentId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCertPdf(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call generate pdf certificate api for annulment end

  const callGetAnnulmentDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.ANNULMENT_DETAILS}?annulmentId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentAnnulment(result?.payload);
          setAnnulmentLinkCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  // const callGetAnnulmentDetailsForPreview = (id) => {
  //   let serviceParams = {
  //     api: `${API.ANNULMENT_DETAILS}?annulmentId=${id}`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     data: {},
  //   };
  //   setLoading(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading(false);
  //       try {
  //         // console.log("result", result.payload);
  //         setCurrentAnnulment(result?.payload);
  //         setGenerateAnnulmentModal(true);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  const callGetAnnulmentDetails = (id) => {
    let serviceParams = {
      api: `${API.ANNULMENT_DETAILS}?annulmentId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentAnnulment(result?.payload);
          setShowEditAnnulmentModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <tr key={annulment?.annulmentId}>
      <th scope="row">{i + 1}</th>
      <td>
        {annulment.groomMemberId ? (
          <Link
            className="fw-bold"
            to={"/admin/member-details"}
            state={{
              memberId: annulment.groomMemberId,
              back: true,
            }}
          >
            {annulment?.groomMiddleName
              ? `${annulment?.groomFirstName} ${annulment?.groomMiddleName} ${annulment?.groomLastName}`
              : `${annulment?.groomFirstName} ${annulment?.groomLastName}`}
          </Link>
        ) : annulment?.groomMiddleName ? (
          `${annulment?.groomFirstName} ${annulment?.groomMiddleName} ${annulment?.groomLastName}`
        ) : (
          `${annulment?.groomFirstName} ${annulment?.groomLastName}`
        )}
        {/* {!annulment?.extAnnulment && (
          <Badge className="ms-2" bg="primary" pill>
            Certificate Available
          </Badge>
        )} */}
      </td>
      <td>
        {annulment.brideMemberId ? (
          <Link
            className="fw-bold"
            to={"/admin/member-details"}
            state={{
              memberId: annulment.brideMemberId,
              back: true,
            }}
          >
            {annulment?.brideMiddleName
              ? `${annulment?.brideFirstName} ${annulment?.brideMiddleName} ${annulment?.brideLastName}`
              : `${annulment?.brideFirstName} ${annulment?.brideLastName}`}
          </Link>
        ) : annulment?.brideMiddleName ? (
          `${annulment?.brideFirstName} ${annulment?.brideMiddleName} ${annulment?.brideLastName}`
        ) : (
          `${annulment?.brideFirstName} ${annulment?.brideLastName}`
        )}
      </td>
      <td
        style={{ height: 55 }}
        className="d-flex align-items-center justify-content-start"
      >
        <span style={{ width: 90 }}>
          {new Date(annulment?.annulmentDate).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>
        <br />
        {!annulment?.annulmentDateAccurate && (
          <CustomTooltip label={"Might be Incorrect"} placement={"top"}>
            <Badge style={{ padding: 5 }} className="ms-1 fw-bold" bg="light">
              <img height={18} src={warningimg} alt="warning" />
            </Badge>
          </CustomTooltip>
        )}
      </td>
      <td>{annulment?.referenceNum}</td>
      <td>
        {/* <Button
          disabled={annulment?.extAnnulment}
          variant="primary"
          onClick={() => {
            callGetAnnulmentDetailsForLink(annulment?.annulmentId);
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-download-2-fill"></i>
          )}
        </Button> */}

        <Button
          className="ms-2"
          disabled={annulment?.extAnnulment}
          variant="warning"
          onClick={() => {
            if (annulment?.groomMemberId || annulment?.brideMemberId) {
              Swal.fire(
                "Update From Profile",
                `Please update Annulment Details from the user's profile. As he/she is the member of the parish.`,
                "warning"
              );
            } else {
              callGetAnnulmentDetails(annulment?.annulmentId);
            }
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-edit-2-fill"></i>
          )}
        </Button>

        {/* <Button
          variant="primary"
          className="ms-2"
          onClick={() => {
            callGetAnnulmentDetailsForPreview(annulment?.annulmentId);
          }}
        >
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <i className="ri-eye-line"></i>
          )}
        </Button> */}
      </td>
      <GenerateAnnulmentCertificateModal
        setShow={setGenerateAnnulmentModal}
        show={generateAnnulmentModal}
        annulmentId={annulment?.annulmentId}
        churchDetails={churchDetails}
        currentAnnulment={currentAnnulment}
      />
      <AnnulmentLinkCertificateModal
        show={annulmentLinkCertModal}
        setShow={setAnnulmentLinkCertModal}
        annulmentId={annulment?.annulmentId}
        churchDetails={churchDetails}
        currentAnnulment={currentAnnulment}
      />
      <EditOutsidersAnnulmentDetailsModal
        show={showEditAnnulmentModal}
        setShow={setShowEditAnnulmentModal}
        currentAnnulment={currentAnnulment}
      />
    </tr>
  );
};

export default withAPIRequest(AnnulmentListRow);
