import { createSlice } from "@reduxjs/toolkit";

const BaptismsSlice = createSlice({
  name: "baptisms",
  initialState: {
    present: false,
    baptisms: [],
    totalCount: 0,
    pageNumber: 1,
  },
  reducers: {
    getBaptisms(state, action) {
      state.baptisms = action.payload;
      state.present = true;
    },
    getTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    clearBaptisms(state, action) {
      state.baptisms = [];
      state.present = false;
    },
  },
});

export const BaptismsAction = BaptismsSlice.actions;
export const BaptismsReducer = BaptismsSlice.reducer;
