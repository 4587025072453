import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";

const MarriageFormStep1 = ({ formik, outsider }) => {
  return (
    <Row className="g-3">
      {!outsider && (
        <Col sm={4}>
          <DateInput
            label={"Confirmation Date"}
            placeholder={"e.g. DD-MM-YYYY"}
            setFieldValue={formik.setFieldValue}
            keyword={"confirmationDate"}
            value={formik.values.confirmationDate}
            error={formik.errors.confirmationDate}
            touched={formik.touched.confirmationDate}
            disabled={true}
          />
        </Col>
      )}

      <Col sm={outsider ? 12 : 8}>
        <TextInput
          label={"Marriage Place"}
          placeholder={"e.g. Noida"}
          setFieldValue={formik.setFieldValue}
          keyword={"marriagePlace"}
          value={formik.values.marriagePlace}
          error={formik.errors.marriagePlace}
          touched={formik.touched.marriagePlace}
        />
      </Col>

      <Col sm={12}>
        <DateInput
          label={"Marriage Date"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"marriageDate"}
          value={formik.values.marriageDate}
          error={formik.errors.marriageDate}
          touched={formik.touched.marriageDate}
        />
      </Col>

      <Col className="mt-2" sm={12}>
        <Form.Check
          type="checkbox"
          label="Not sure if marriage date is correct"
          onChange={(e) => {
            formik.setFieldValue("marriageDateNotSure", e.target.checked);
          }}
          checked={formik.values.marriageDateNotSure}
        />
      </Col>

      <Col sm={4}>
        <DateInput
          label={"Date Of First Banns"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstBannesDate"}
          value={formik.values.firstBannesDate}
          error={formik.errors.firstBannesDate}
          touched={formik.touched.firstBannesDate}
        />
      </Col>
      <Col sm={4}>
        <DateInput
          label={"Date Of Second Banns"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondBannesDate"}
          value={formik.values.secondBannesDate}
          error={formik.errors.secondBannesDate}
          touched={formik.touched.secondBannesDate}
        />
      </Col>
      <Col sm={4}>
        <DateInput
          label={"Date Of Third Banns"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"thirdBannesDate"}
          value={formik.values.thirdBannesDate}
          error={formik.errors.thirdBannesDate}
          touched={formik.touched.thirdBannesDate}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"1st Witness First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstWitnessFirstName"}
          value={formik.values.firstWitnessFirstName}
          error={formik.errors.firstWitnessFirstName}
          touched={formik.touched.firstWitnessFirstName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"1st Witness Last Name"}
          placeholder={"e.g. Kerketta"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstWitnessLastName"}
          value={formik.values.firstWitnessLastName}
          error={formik.errors.firstWitnessLastName}
          touched={formik.touched.firstWitnessLastName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"1st Witness Domicile"}
          placeholder={"e.g. Delhi"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstWitnessDomicile"}
          value={formik.values.firstWitnessDomicile}
          error={formik.errors.firstWitnessDomicile}
          touched={formik.touched.firstWitnessDomicile}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"2nd Witness First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondWitnessFirstName"}
          value={formik.values.secondWitnessFirstName}
          error={formik.errors.secondWitnessFirstName}
          touched={formik.touched.secondWitnessFirstName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"2nd Witness Last Name"}
          placeholder={"e.g. Kerketta"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondWitnessLastName"}
          value={formik.values.secondWitnessLastName}
          error={formik.errors.secondWitnessLastName}
          touched={formik.touched.secondWitnessLastName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"2nd Witness Domicile"}
          placeholder={"e.g. Delhi"}
          setFieldValue={formik.setFieldValue}
          keyword={"secondWitnessDomicile"}
          value={formik.values.secondWitnessDomicile}
          error={formik.errors.secondWitnessDomicile}
          touched={formik.touched.secondWitnessDomicile}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Minister's Name"}
          placeholder={"e.g. Fr. Joseph"}
          setFieldValue={formik.setFieldValue}
          keyword={"ministerName"}
          value={formik.values.ministerName}
          error={formik.errors.ministerName}
          touched={formik.touched.ministerName}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Register Reference Number"}
          placeholder={"Enter Reference Number"}
          setFieldValue={formik.setFieldValue}
          keyword={"registerRefNumber"}
          value={formik.values.registerRefNumber}
          error={formik.errors.registerRefNumber}
          touched={formik.touched.registerRefNumber}
          type={"number"}
        />
      </Col>
      <Col sm={6}>
        <TextArea
          label={"Impediment"}
          placeholder={"Type Impediment (if any)"}
          setFieldValue={formik.setFieldValue}
          keyword={"impediment"}
          value={formik.values.impediment}
          error={formik.errors.impediment}
          touched={formik.touched.impediment}
        />
      </Col>
      <Col sm={6}>
        <TextArea
          label={"Remark"}
          placeholder={"Type Remark (if any)"}
          setFieldValue={formik.setFieldValue}
          keyword={"remark"}
          value={formik.values.remark}
          error={formik.errors.remark}
          touched={formik.touched.remark}
        />
      </Col>
    </Row>
  );
};

export default MarriageFormStep1;
