import axios from "axios";
const dioceseDashboardMenu = [
  {
    label: "Dashboard",
    link: "/diocese/dashboard",
    icon: "ri-pie-chart-2-line",
  },
  {
    label: "Feeds",
    link: "/diocese/feeds",
    icon: "ri-rss-line",
  },
];

const dioceseMainMenu = [
  {
    label: "Parish Directory",
    link: "/diocese/parishes-directory",
    icon: "ri-building-line",
  },
  {
    label: "Priest Directory",
    link: "/diocese/priests-directory",
    icon: "ri-team-line",
  },
];

// const financialsMenu = [
//   {
//     label: "CSF's",
//     link: "/admin/csf",
//     icon: "ri-wallet-3-line",
//   },
//   {
//     label: "Donations",
//     link: "/admin/donations",
//     icon: "ri-hand-coin-line",
//   },
//   {
//     label: "Expenses",
//     link: "/admin/expenses",
//     icon: "ri-function-line",
//   },
// ];

// const certificatesMenu = [
//   {
//     label: "Certificates",
//     // link: "/admin/certificates",
//     icon: "ri-file-user-line",
//     submenu: [
//       {
//         label: "Baptism",
//         link: "/admin/baptism-certificates",
//       },
//       {
//         label: "Holy Communion",
//         link: "/admin/holy-communion-certificates",
//       },
//       {
//         label: "Holy Confirmation",
//         link: "/admin/holy-confirmation-certificates",
//       },
//       {
//         label: "Marriage",
//         link: "/admin/marriage-certificates",
//       },
//       {
//         label: "Annulment",
//         link: "/admin/annulment-certificates",
//       },
//     ],
//   },
//   {
//     label: "Letters",
//     link: "/admin/letters",
//     icon: "ri-file-2-line",
//   },
// ];

// const settingsMenu = [
//   {
//     label: "Profile",
//     link: "/admin/profile",
//     icon: "ri-user-3-line",
//   },
//   {
//     label: "Reset Password",
//     link: "/admin/reset-password",
//     icon: "ri-lock-2-line",
//   },
//   {
//     label: "Logout",
//     link: "/",
//     icon: "ri-logout-box-line",
//     replace: true,
//     clickType: "logout",
//   },
// ];

export {
  dioceseDashboardMenu,
  dioceseMainMenu,
  //   certificatesMenu,
  //   settingsMenu,
  //   financialsMenu,
};
