import React, { useEffect, useState } from "react";
import { Button, Card, Nav, Table } from "react-bootstrap";
import AddCsfDetailsModal from "../modals/csf/AddCsfDetailsModal";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import { withAPIRequest } from "../HOC/withAPIRequest";
import Lottie from "lottie-react";
import noSearchAnimation from "./../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import loadingAnimation from "./../assets/lottie/loading_animation_delhi_theme.json";
import { useDispatch, useSelector } from "react-redux";
import { FamilyAction } from "../store/slices/FamilySlice";
import { useFormik } from "formik";
import SelectInput from "../components/form/SelectInput";
import CustomPopover from "../components/CustomPopover";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

function generateArrayOfYears() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear - 10; year <= currentYear + 10; year++) {
    years.push({ label: year.toString(), value: year });
  }

  return years;
}

const CsfDetails = ({ commonAPIRequest, familyId, active }) => {
  const [addCsfModalVisible, setAddCsfModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastMonthDonated, setlastMonthDonated] = useState();
  const [lastYearDonated, setLastYearDonated] = useState();
  const [lastAmountPaid, setAmountLastPaid] = useState();

  const dispatch = useDispatch();
  const { csfDetails } = useSelector((state) => state.family);

  const formik = useFormik({
    initialValues: {
      currentYear: new Date().getFullYear(),
    },
    onSubmit: () => {},
  });

  // call api for get all donations

  const getAllDonationsApi = () => {
    let serviceParams = {
      api: `${API.GET_FAMILY_DONATION_CSF}?familyId=${familyId}&year=${formik.values.currentYear}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            FamilyAction.setCurrentFamilyCsfDetails(
              result.payload.supportFundsList
                ? result.payload.supportFundsList
                : []
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call api for get all donations

  // call last donated details save api start

  const getLastDonatedYearMonthApi = () => {
    let serviceParams = {
      api: `${API.GET_FAMILY_DONATION_CSF}?familyId=${familyId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          let length = result.payload.supportFundsList.length;
          if (length === 0) {
            setLastYearDonated(undefined);
            setlastMonthDonated(undefined);
          } else if (length === 12) {
            setLastYearDonated(
              result.payload.supportFundsList[length - 1].fundYear
            );
            formik.setFieldValue(
              "currentYear",
              result.payload.supportFundsList[length - 1].fundYear
            );
            setlastMonthDonated(
              result.payload.supportFundsList[length - 1].fundMonth
            );
            setAmountLastPaid(
              result?.payload?.supportFundsList[length - 1]?.fundAmount
            );
          } else {
            formik.setFieldValue(
              "currentYear",
              result.payload.supportFundsList[length - 1].fundYear
            );
            setLastYearDonated(
              result.payload.supportFundsList[length - 1].fundYear
            );
            setlastMonthDonated(
              result.payload.supportFundsList[length - 1].fundMonth
            );
            setAmountLastPaid(
              result?.payload?.supportFundsList[length - 1]?.fundAmount
            );
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call last donated details save api start

  // call status change donation api

  const callDonationPaidApi = (id) => {
    let serviceParams = {
      api: `${API.DONATION_STATUS_CSF}?supportFundId=${id}&status=paid`,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Updated", "CSF Details Updated", "success");
          getAllDonationsApi();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call status change donation api

  useEffect(() => {
    if (familyId && active) {
      getLastDonatedYearMonthApi();
    }
  }, [familyId, active]);

  useEffect(() => {
    if (active) {
      getAllDonationsApi();
    }
  }, [active, formik.values.currentYear]);

  return (
    <Card className="card-csf">
      <Card.Body className="d-lg-none pt-0">
        <div className="d-flex align-items-center justify-content-between">
          <div className="mt-4">
            <h6>
              Last Paid Till:
              <span className="fw-bold text-primary">
                {" "}
                {lastMonthDonated} {lastYearDonated}
              </span>
            </h6>
            <h6 className="mb-0">
              Last Paid Amount:
              <span className="fw-bold text-primary"> ₹ {lastAmountPaid}</span>
            </h6>
          </div>

          <div className="mt-4 d-flex">
            <SelectInput
              selectOptions={generateArrayOfYears()}
              setFieldValue={formik.setFieldValue}
              keyword={"currentYear"}
              value={formik.values.currentYear}
              error={formik.errors.currentYear}
              touched={formik.touched.currentYear}
            />
            <Button
              onClick={() => {
                setAddCsfModalVisible(true);
              }}
              style={{ height: 40 }}
              variant="primary"
              className="ms-2"
            >
              <i className="ri-add-line"></i>
            </Button>
          </div>
        </div>

        <hr className="mb-0" />

        {!loading &&
          csfDetails?.map((donation) => (
            <Card className="card-post mt-4 shadow box">
              <div
                className={
                  donation?.status === "PENDING" ? "ribbon yellow" : "ribbon"
                }
              >
                <span>{donation?.status}</span>
              </div>
              <Card.Body className="pb-1">
                <div className="interest-item">
                  <div className="interest-icon bg-primary">
                    <span style={{ fontSize: 20 }}>{donation?.fundMonth}</span>
                  </div>
                  <div className="interest-body">
                    <h6 className="fw-bold fs-4 text-primary">
                      ₹ {donation?.fundAmount}
                    </h6>
                    <p>
                      Payment Mode:{" "}
                      <span className="fw-bold">{donation?.paymentMode}</span>
                    </p>
                    <p>
                      Paid On:{" "}
                      <span className="fw-bold">
                        {" "}
                        {new Date(donation?.collectionDate).toLocaleDateString(
                          "en-GB",
                          { month: "long", day: "numeric", year: "numeric" }
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className="pt-0">
                <hr className="mb-1" />
                <p style={{ fontSize: 10 }} className="m-0">
                  Added by <strong>{donation?.createdBy}</strong> on{" "}
                  <strong>
                    {new Date(donation?.createTmStmp).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                  </strong>
                </p>
              </Card.Footer>
            </Card>
          ))}

        {loading && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 120, height: 120 }}
                animationData={loadingAnimation}
                loop={true}
              />
            </div>

            <p className="text-center">Loading...</p>
          </div>
        )}

        {csfDetails?.length === 0 && !loading && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center mt-4"
            >
              <Lottie
                style={{ width: 180, height: 180 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
            <h3 className="text-center fw-bold">No CSF Details Found</h3>
            <p className="text-center">Please Add CSF Details.</p>
          </div>
        )}
      </Card.Body>
      <Card.Body className="d-none d-lg-inline">
        <div
          className="mb-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h6>
              Last Paid Till:
              <span className="fw-bold text-primary">
                {lastMonthDonated} {lastYearDonated}
              </span>
            </h6>
            <h6>
              Last Paid Amount:
              <span className="fw-bold text-primary"> {lastAmountPaid}</span>
            </h6>
          </div>
          <div className="d-flex">
            <SelectInput
              selectOptions={generateArrayOfYears()}
              setFieldValue={formik.setFieldValue}
              keyword={"currentYear"}
              value={formik.values.currentYear}
              error={formik.errors.currentYear}
              touched={formik.touched.currentYear}
            />
            <Button
              onClick={() => {
                setAddCsfModalVisible(true);
              }}
              style={{ height: 40 }}
              variant="primary"
              className="ms-3"
            >
              Add CSF
            </Button>
          </div>
        </div>

        <hr className="hr" />

        {loading && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 120, height: 120 }}
                animationData={loadingAnimation}
                loop={true}
              />
            </div>

            <p className="text-center">Loading...</p>
          </div>
        )}

        {csfDetails?.length === 0 && !loading ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 180, height: 180 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
            <h3 className="text-center fw-bold">No CSF Details Found</h3>
            <p className="text-center">Please Add CSF Details.</p>
          </div>
        ) : (
          !loading && (
            <Table variant="primary" hover className="mb-0">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Month</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Paid On</th>
                  <th scope="col">Mode</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {csfDetails?.map((donation, index) => {
                  return (
                    <tr key={donation?.supportFundId}>
                      <th scope="row">{index + 1}</th>
                      <td>{donation?.fundMonth}</td>
                      <td>
                        ₹ {donation?.fundAmount}{" "}
                        <CustomPopover
                          content={
                            <p>
                              Added by <strong>{donation?.createdBy}</strong> on{" "}
                              <strong>
                                {new Date(
                                  donation?.createTmStmp
                                ).toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })}
                              </strong>
                            </p>
                          }
                        >
                          <i className="ri-information-line"></i>
                        </CustomPopover>
                      </td>
                      <td>
                        {new Date(donation?.collectionDate).toLocaleDateString(
                          "en-GB",
                          { month: "long", day: "numeric", year: "numeric" }
                        )}
                      </td>
                      <td>{donation?.paymentMode}</td>
                      <td>
                        {donation?.status}{" "}
                        {donation?.status === "PENDING" && (
                          <CustomPopover
                            header={"Action Required"}
                            content={
                              <>
                                <Button
                                  onClick={() =>
                                    callDonationPaidApi(donation?.supportFundId)
                                  }
                                  className="mx-1"
                                  variant="success"
                                >
                                  Approve
                                </Button>
                                {/* <Button className="mx-1" variant="danger">
                                  Reject
                                </Button> */}
                              </>
                            }
                          >
                            <i className="ri-edit-line"></i>
                          </CustomPopover>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )
        )}
      </Card.Body>
      {/* <Card.Footer>
        <pre className="text-end">
          <h6>
            Last Updated by <span className="fw-bold text-primary">Akshay</span>{" "}
            on <span className="fw-bold text-primary">23/09/2023</span>
          </h6>
        </pre>
      </Card.Footer> */}
      <AddCsfDetailsModal
        show={addCsfModalVisible}
        setShow={setAddCsfModalVisible}
        getAllDonationsApi={getAllDonationsApi}
        familyId={familyId}
        lastMonthDonated={lastMonthDonated}
        lastYearDonated={lastYearDonated}
        getLastDonatedYearMonthApi={getLastDonatedYearMonthApi}
      />
    </Card>
  );
};

export default withAPIRequest(CsfDetails);
