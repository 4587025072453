import React from "react";
import churchLogo from "../../assets/img/logo-1.png";
import waterMark from "../../assets/img/christ.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FontDancingRegular from "../../assets/fonts/dancingscript/DancingScript-Regular.ttf";
import FontMateSCRegular from "../../assets/fonts/matesc/MateSC-Regular.ttf";
import delhidioceselogo from "../../assets/img/delhi-diocese-logo.png";

Font.register({
  family: "Dancing",
  fonts: [
    {
      src: FontDancingRegular,
    },
  ],
});

Font.register({
  family: "Mate",
  fonts: [
    {
      src: FontMateSCRegular,
    },
  ],
});

const AnnulmentCertificatePdf = ({ details, churchDetails }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark */}
        <View style={styles.watermarkContainer}>
          <Image style={{ width: "50%" }} src={delhidioceselogo} />
        </View>

        {/* Certificate Content */}
        <View style={styles.certContainer}>
          <div style={styles.borderGray}>
            <div style={styles.borderRed}>
              {/* Header */}
              <View>
                <View style={styles.header}>
                  {/* <Image style={styles.image} src={churchLogo} /> */}
                  <Image style={styles.image} src={churchLogo} />
                  <View
                    style={{
                      position: "absolute",
                      top: -20,
                      right: -20,
                      //   backgroundColor: "red",
                    }}
                  >
                    <Image
                      style={{ height: 60, width: "auto" }}
                      src={delhidioceselogo}
                    />
                  </View>
                  <View style={styles.headerText}>
                    <Text
                      style={{
                        fontSize: 24,
                        fontFamily: "Mate",
                      }}
                    >
                      {churchDetails?.churchName}
                    </Text>
                    <Text
                      style={{ fontSize: 12, marginTop: 5, fontFamily: "Mate" }}
                    >
                      {churchDetails?.churchAddress?.addressLine1}
                    </Text>
                  </View>
                </View>

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: "2px solid #d4af37",
                      paddingTop: 8,
                      paddingBottom: 4,
                      margin: 0,
                      fontSize: 13,
                      fontFamily: "Mate",
                    }}
                  >
                    Extract from the parochial register of{" "}
                    {churchDetails?.churchName}, Archdiocese Of Delhi
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      fontFamily: "Mate",
                      fontSize: 32,
                    }}
                  >
                    Annulment Certificate
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  {/* Dynamic content here */}

                  <Text style={styles.keyText}>
                    Date of Annulment:{" "}
                    <Text style={styles.valueText}>
                      {new Date(details?.annulmentDate).toLocaleDateString(
                        "en-IN",
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bridegroom's Full Name:{" "}
                    <Text style={styles.valueText}>
                      {details?.groomMiddleName
                        ? `${details?.groomFirstName} ${details?.groomMiddleName} ${details?.groomLastName}`
                        : `${details?.groomFirstName} ${details?.groomLastName}`}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bridegroom's Father's Name:{" "}
                    <Text style={styles.valueText}>
                      {details?.groomFatherName}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bridegroom's Mother's Name:{" "}
                    <Text style={styles.valueText}>
                      {details?.groomMotherName}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bridegroom's Resident:{" "}
                    <Text style={styles.valueText}>
                      {details?.groomAddress}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bride's Full Name:{" "}
                    <Text style={styles.valueText}>
                      {details?.brideMiddleName
                        ? `${details?.brideFirstName} ${details?.brideMiddleName} ${details?.brideLastName}`
                        : `${details?.brideFirstName} ${details?.brideLastName}`}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bride's Father's Name:{" "}
                    <Text style={styles.valueText}>
                      {details?.brideFatherName}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bride's Mother's Name:{" "}
                    <Text style={styles.valueText}>
                      {details?.brideMotherName}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Bride's Resident:{" "}
                    <Text style={styles.valueText}>
                      {details?.brideAddress}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Tribunal Name:{" "}
                    <Text style={styles.valueText}>
                      {details?.tribunalName}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Civil Divorce:{" "}
                    <Text style={styles.valueText}>
                      {details?.civilDivorce ? "Yes" : "No"}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Reference No.:{" "}
                    <Text style={styles.valueText}>
                      {details?.referenceNum}
                    </Text>
                  </Text>

                  <Text style={styles.keyText}>
                    Remark:{" "}
                    <Text style={styles.valueText}>
                      {details?.remarks ? details?.remarks : "-"}
                    </Text>
                  </Text>

                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text style={{ fontSize: 12, fontFamily: "Mate" }}>
                    Parish Priest
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#7e7e7e",
                      fontFamily: "Mate",
                    }}
                  >
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12, fontFamily: "Mate" }}>
                  Seal
                </Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.2, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderGray: {
    padding: 5,
    border: "3px solid #58595b",
    height: "100%",
  },
  borderRed: {
    border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    borderColor: "#d4af37",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    backgroundColor: "rgb(240, 229, 207, 0.5)",
    height: "100%",
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 10,
  },
  headerText: {
    textAlign: "center",
    width: "100%",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  image: {
    maxHeight: 80,
    maxWidth: 80,
    marginRight: 10,
  },
  keyText: {
    fontSize: 14,
    color: "#7e7e7e",
    marginBottom: 10,
    fontFamily: "Mate",
  },
  valueText: {
    fontSize: 14,
    color: "#000",
    textTransform: "capitalize",
    fontFamily: "Mate",
  },
});

export default AnnulmentCertificatePdf;
