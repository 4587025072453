import * as Yup from "yup";

const alphabetsOnly = /^[a-zA-Z][a-zA-Z\. ]*$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const ExtHolyCommunionValidationSchema = Yup.object().shape({
  baptismDateForValidation: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  holyCommunionDate: Yup.date()
    .min(
      Yup.ref("baptismDateForValidation"),
      "Holy Communion date can't be before Baptism Date"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  // holyCommunionChurchName: Yup.string().required("Required"),
  // holyCommunionChurchCity: Yup.string().required("Required"),
});

export const HolyCommunionValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  // lastName: Yup.string().required("Required"),
  dob: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  baptismDateForValidation: Yup.date()
    .min(Yup.ref("dob"), "Baptism date can't be before Date Of Birth")
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  holyCommunionDate: Yup.date()
    .min(
      Yup.ref("baptismDateForValidation"),
      "Holy Communion date can't be before Date Of Baptism"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  fatherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  motherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  gender: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  mobileNum: Yup.string()
    .required("Required")
    .matches(phoneRegExp, "Phone number is not valid")
    .max(10, "Atmost 10 - Digits")
    .min(10, "Atleast 10 - Digits"),
});

export const EditHolyCommunionValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  // lastName: Yup.string().required("Required"),
  dob: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  dateOfBaptism: Yup.date()
    .min(Yup.ref("dob"), "Can't be before Date Of Birth")
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  holyCommunionDate: Yup.date()
    .min(
      Yup.ref("dateOfBaptism"),
      "Holy Communion date can't be before Date Of Baptism"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  fatherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  motherName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  gender: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  mobileNum: Yup.string()
    .required("Required")
    .matches(phoneRegExp, "Phone number is not valid")
    .max(10, "Atmost 10 - Digits")
    .min(10, "Atleast 10 - Digits"),
});
