import { createSlice } from "@reduxjs/toolkit";

const AnalyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    analyticsPresent: false,
    analytics: {},
  },
  reducers: {
    setAnalytics(state, action) {
      state.analyticsPresent = true;
      state.analytics = action.payload;
    },
    clearAnalytics(state, action) {
      state.analyticsPresent = false;
      state.analytics = {};
    },
  },
});

export const AnalyticsAction = AnalyticsSlice.actions;
export const AnalyticsReducer = AnalyticsSlice.reducer;
