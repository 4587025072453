import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";

const AnnulmentFormStep1 = ({ formik, marriageDetails, edit }) => {
  return (
    <Row className="g-3">
      {!edit && (
        <Col sm={6}>
          <DateInput
            label={"Marriage Date"}
            placeholder={"e.g. DD-MM-YYYY"}
            setFieldValue={formik.setFieldValue}
            keyword={"marriageDate"}
            value={formik.values.marriageDate}
            error={formik.errors.marriageDate}
            touched={formik.touched.marriageDate}
            disabled={marriageDetails?.marriageDate ? true : false}
          />
        </Col>
      )}
      <Col sm={edit ? 12 : 6}>
        <DateInput
          label={"Annulment Date"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"annulmentdate"}
          value={formik.values.annulmentdate}
          error={formik.errors.annulmentdate}
          touched={formik.touched.annulmentdate}
        />
      </Col>

      <Col className="mt-2" sm={12}>
        <Form.Check
          type="checkbox"
          label="Not sure if annulment date is correct"
          onChange={(e) => {
            formik.setFieldValue("annulmentDateNotSure", e.target.checked);
          }}
          checked={formik.values.annulmentDateNotSure}
        />
      </Col>

      <Col sm={12}>
        <TextInput
          label={"Tribunal Name"}
          placeholder={"Type here..."}
          setFieldValue={formik.setFieldValue}
          keyword={"tribunalName"}
          value={formik.values.tribunalName}
          error={formik.errors.tribunalName}
          touched={formik.touched.tribunalName}
        />
      </Col>
      <Col sm={12}>
        <Form.Check
          type="switch"
          checked={formik.values.civilDivorce}
          label="Civil Divorce?"
          onChange={(e) => {
            formik.setFieldValue("civilDivorce", e.target.checked);
          }}
        />
      </Col>
      <Col sm={12}>
        <TextArea
          label={"Remark"}
          placeholder={"Type remark here..."}
          setFieldValue={formik.setFieldValue}
          keyword={"remark"}
          value={formik.values.remark}
          error={formik.errors.remark}
          touched={formik.touched.remark}
        />
        <Col sm={12}>
          <TextInput
            label={"Register Reference Number"}
            setFieldValue={formik.setFieldValue}
            keyword={"registerRefNumber"}
            value={formik.values.registerRefNumber}
            error={formik.errors.registerRefNumber}
            touched={formik.touched.registerRefNumber}
            type={"number"}
          />
        </Col>
      </Col>
    </Row>
  );
};

export default AnnulmentFormStep1;
