import * as Yup from "yup";

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const ExtAnnulmentValidationSchema = Yup.object().shape({
  marriageDate: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  annulmentDate: Yup.date()
    .min(
      Yup.ref("marriageDate"),
      `Annulment Date can't be on or before Marriage Date`
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  extAnnulmentChurch: Yup.string().required("Required"),
  extAnnulmentCity: Yup.string().required("Required"),
});

export const AnnulmentFormStep1ValidationSchema = Yup.object().shape({
  marriageDate: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  annulmentdate: Yup.date()
    .min(
      Yup.ref("marriageDate"),
      "Annulment date can't be before Marriage Date"
    )
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  tribunalName: Yup.string().required("Required"),
});

export const AnnulmentFormStep2ValidationSchema = Yup.object().shape({
  groomFirstName: Yup.string().required("Required"),
  groomLastName: Yup.string().required("Required"),
  groomFatherName: Yup.string().required("Required"),
  groomMotherName: Yup.string().required("Required"),
});

export const AnnulmentFormStep3ValidationSchema = Yup.object().shape({
  brideFirstName: Yup.string().required("Required"),
  brideLastName: Yup.string().required("Required"),
  brideFatherName: Yup.string().required("Required"),
  brideMotherName: Yup.string().required("Required"),
});

export const OutsiderExtAnnulmentValidationSchema = Yup.object().shape({
  annulmentdate: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  tribunalName: Yup.string().required("Required"),
});
