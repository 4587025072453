import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import ZonePlaceholder from "../../components/placeholders/ZonePlaceholder";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FamilyAction } from "../../store/slices/FamilySlice";
import familyImg from "../../assets/img/family_delhi_diocese_theme.png";

const familyRegexOnly = /^[a-zA-Z0-9.]*$'/;

const FamilyValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .min(3, "Required Atleast 3 Characters / Digits to Search"),
});

const SearchFamilyModal = ({ show, setShow, commonAPIRequest }) => {
  const [loading, setLoading] = useState(false);
  const [families, setFamilies] = useState([]);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: FamilyValidationSchema,
    onSubmit: (values) => {
      if (isNaN(values?.name)) {
        const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
          return e.trim().length > 0;
        });
        fullNameArray.length === 1
          ? callSearchFamilyApi(fullNameArray[0], "")
          : callSearchFamilyApi(
              fullNameArray[0],
              fullNameArray[fullNameArray.length - 1]
            );
      } else {
        callSearchFamilyByNumberApi(+values?.name);
      }
    },
  });

  const callSearchFamilyApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${API.SEARCH_MEMBER}?firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }&filterBy=head`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setFamilies(
            result.payload.searchSuggestions
              ? result.payload.searchSuggestions
              : []
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callSearchFamilyByNumberApi = (famId) => {
    let serviceParams = {
      api: `${API.SEARCH_MEMBER}?churchFamilyId=${famId}&filterBy=head`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setFamilies(
            result.payload.searchSuggestions
              ? result.payload.searchSuggestions
              : []
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Search Family
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Label className="fw-bold">Full Name OR Family ID</Form.Label>
            <p className="text-muted m-0">
              Enter the name of the{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                Head Of The Family
              </span>{" "}
              or{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                Last 3 digits of the Family-Id
              </span>{" "}
              .
            </p>
            <div className="form-search py-2  ">
              <i className="ri-search-line"></i>
              <Form.Control
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                type="text"
                placeholder="Search here..."
              />
              <Button onClick={formik.handleSubmit} variant="primary">
                Search
              </Button>
            </div>
            <div className="">
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger mt-2 mb-0">*{formik.errors.name}</p>
              )}
            </div>
          </Col>

          <hr className="hr mt-4" />

          {loading && <ZonePlaceholder />}

          {!loading &&
            families.map((member, index) => (
              <Col lg={6} key={index}>
                {
                  <Card className="card-people">
                    <Card.Body>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="mb-4"
                      >
                        {/* <Avatar img={familyImg} size="xl" /> */}
                        <img
                          style={{ maxWidth: 84 }}
                          src={familyImg}
                          className="img-fluid"
                          alt="..."
                        />
                        <div className="ms-3">
                          <h6 className="mt-3">
                            <Link to="">
                              {member.firstName} {member.lastName}
                              {member?.active ? (
                                <Badge
                                  className="ms-1 fw-bold"
                                  bg="success"
                                  pill
                                >
                                  Active
                                </Badge>
                              ) : (
                                <Badge
                                  className="ms-1 fw-bold"
                                  bg="danger"
                                  pill
                                >
                                  Inactive
                                </Badge>
                              )}
                            </Link>
                          </h6>
                          <p className="m-0 fw-bold text-primary">
                            {member.churchFamilyId}
                          </p>
                          <p className="m-0">{member?.emailAddress}</p>
                          {member?.familyHead ? (
                            <Badge pill bg="primary">
                              Head Of Family
                            </Badge>
                          ) : (
                            <Badge pill bg="warning">
                              Member
                            </Badge>
                          )}
                        </div>
                      </div>

                      <div className="d-grid">
                        <Link
                          className=" btn btn-primary align-items-center justify-content-center d-flex"
                          onClick={() => {
                            dispatch(FamilyAction.setFamilyId(member.familyId));
                            localStorage.setItem(
                              "currentFamilyId",
                              member?.familyId
                            );
                            localStorage.setItem(
                              "currentChurchFamilyId",
                              member?.churchFamilyId
                            );
                          }}
                          state={{
                            familyId: member.familyId,
                          }}
                          to={"/admin/family-details"}
                        >
                          <i
                            style={{ fontSize: 18 }}
                            className="ri-team-line me-2 align-self-center"
                          ></i>
                          Family Details
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                }
              </Col>
            ))}

          {!loading && families.length === 0 && (
            <div className="mb-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="text-center"
              >
                <Lottie
                  style={{ width: 180, height: 180 }}
                  animationData={noSearchAnimation}
                  loop={true}
                />
              </div>
              <h3 className="text-center fw-bold">No Family Found</h3>
              <h5 className="text-center fw-400">
                Please Try With Another Name.
              </h5>
            </div>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default withAPIRequest(SearchFamilyModal);
