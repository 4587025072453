import { createSlice } from "@reduxjs/toolkit";

const MarriagesSlice = createSlice({
  name: "marriages",
  initialState: {
    present: false,
    marriages: [],
    totalCount: 0,
    pageNumber: 1,
  },
  reducers: {
    getMarriages(state, action) {
      state.marriages = action.payload;
      state.present = true;
    },
    getTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    clearMarriages(state, action) {
      state.marriages = [];
      state.present = false;
    },
  },
});

export const MarriagesAction = MarriagesSlice.actions;
export const MarriagesReducer = MarriagesSlice.reducer;
