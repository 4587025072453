import React from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";

const AddFamilyFormStep3 = ({ formik }) => {
  return (
    <Row className="g-3">
      <Col sm={12}>
        <TextInput
          label={"Mobile Number"}
          placeholder={"e.g. 987XXXXXXX"}
          setFieldValue={formik.setFieldValue}
          keyword={"phone"}
          value={formik.values.phone}
          error={formik.errors.phone}
          touched={formik.touched.phone}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"WhatsApp Number"}
          placeholder={"e.g. 987XXXXXXX"}
          setFieldValue={formik.setFieldValue}
          keyword={"whatsappNumber"}
          value={formik.values.whatsappNumber}
          error={formik.errors.whatsappNumber}
          touched={formik.touched.whatsappNumber}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Email Address"}
          placeholder={"e.g. abc@gmail.com"}
          setFieldValue={formik.setFieldValue}
          keyword={"email"}
          value={formik.values.email}
          error={formik.errors.email}
          touched={formik.touched.email}
        />
      </Col>
    </Row>
  );
};

export default AddFamilyFormStep3;
