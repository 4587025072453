import Lottie from "lottie-react";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import DateInput from "../../components/form/DateInput";
import TextArea from "../../components/form/TextArea";
import { useFormik } from "formik";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Swal from "sweetalert2";
import { ExpenseValidationSchema } from "../../validations/financials";

const paymentModeArray = [
  { label: "UPI", value: "UPI" },
  { label: "CASH", value: "CASH" },
  { label: "CHEQUE", value: "CHEQUE" },
];

const AddExpenseModal = ({
  show,
  setShow,
  commonAPIRequest,
  getAllExpensesApi,
  expenses,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      totalExpenseAmount: "",
      paymentMode: "",
      paidOn: "",
      purpose: "",
      remark: "",
    },
    validationSchema: ExpenseValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "info",
        html: `
          Amount Spent: <b>₹ ${values?.totalExpenseAmount}</b> <br />
          Expense Made On: <b>${new Date(values?.paidOn).toLocaleDateString(
            "en-GB",
            { month: "long", day: "numeric", year: "numeric" }
          )}</b> <br />
          Mode Of Payment: <b>${values?.paymentMode}</b> <br />
          Purpose: <b>${values?.purpose}</b> <br />`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          addExpenseApi(values);
        }
      });
    },
  });

  // ADD EXPENSE API FUNCTION START

  const addExpenseApi = (values) => {
    let serviceParams = {
      api: API.ADD_EXPENSE,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        expenseDate: values?.paidOn,
        description: values?.remark,
        amount: +values?.totalExpenseAmount,
        paymentMode: values.paymentMode,
        category: values?.purpose,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          getAllExpensesApi();
          setShow(false);
          formik.resetForm();
          Swal.fire("Success", `Expense Added Successfully.`, "success");
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // ADD EXPENSE API FUNCTION END

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        formik.resetForm();
      }}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Expense Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row>
            <Col className="mt-2" sm={12} lg={6}>
              <TextInput
                label={"Amount"}
                placeholder={"e.g. 5000"}
                setFieldValue={formik.setFieldValue}
                keyword={"totalExpenseAmount"}
                value={formik.values.totalExpenseAmount}
                error={formik.errors.totalExpenseAmount}
                touched={formik.touched.totalExpenseAmount}
              />
            </Col>
            <Col className="mt-2" sm={12} lg={6}>
              <SelectInput
                label={"Mode Of Payment"}
                selectOptions={paymentModeArray}
                setFieldValue={formik.setFieldValue}
                keyword={"paymentMode"}
                value={formik.values.paymentMode}
                error={formik.errors.paymentMode}
                touched={formik.touched.paymentMode}
              />
            </Col>
            <Col className="mt-2" sm={12}>
              <DateInput
                label={"Expense Made On"}
                setFieldValue={formik.setFieldValue}
                keyword={"paidOn"}
                value={formik.values.paidOn}
                error={formik.errors.paidOn}
                touched={formik.touched.paidOn}
              />
            </Col>

            <Col sm={12}>
              <SelectInput
                selectOptions={expenses}
                label={"Purpose Of Expense"}
                setFieldValue={formik.setFieldValue}
                keyword={"purpose"}
                value={formik.values.purpose}
                error={formik.errors.purpose}
                touched={formik.touched.purpose}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <TextArea
                label={"Remark"}
                setFieldValue={formik.setFieldValue}
                keyword={"remark"}
                value={formik.values.remark}
                error={formik.errors.remark}
                touched={formik.touched.remark}
                placeholder={"Any Remark...."}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withAPIRequest(AddExpenseModal);
