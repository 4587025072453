import React from "react";
import churchLogo from "../../assets/img/logo-1.png";
import waterMark from "../../assets/img/marriage.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FontDancingRegular from "../../assets/fonts/dancingscript/DancingScript-Regular.ttf";
import FontMateSCRegular from "../../assets/fonts/matesc/MateSC-Regular.ttf";
import delhidioceselogo from "../../assets/img/logo-for-cert.png";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

Font.register({
  family: "Dancing",
  fonts: [
    {
      src: FontDancingRegular,
    },
  ],
});

Font.register({
  family: "Mate",
  fonts: [
    {
      src: FontMateSCRegular,
    },
  ],
});
const MarriageCertificatePdf = ({ details, churchDetails }) => {
  console.log("details", details);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark */}
        {churchDetails?.watermarkUrl && (
          <View style={styles.watermarkContainer}>
            <Image style={{ width: "50%" }} src={churchDetails?.watermarkUrl} />
          </View>
        )}

        {/* Certificate Content */}
        <View style={styles.certContainer}>
          <div style={styles.borderGray}>
            <div style={styles.borderRed}>
              {/* Header */}
              <View>
                <View style={styles.header}>
                  {/* <Image style={styles.image} src={churchLogo} /> */}
                  {churchDetails?.logoUrl && (
                    <Image style={styles.image} src={churchDetails?.logoUrl} />
                  )}
                  <View
                    style={{
                      position: "absolute",
                      top: -20,
                      right: -20,
                      //   backgroundColor: "red",
                    }}
                  >
                    <Image
                      style={{ height: 72, width: "auto" }}
                      src={delhidioceselogo}
                    />
                  </View>
                  <View style={styles.headerText}>
                    <Text
                      style={{
                        fontSize: 24,
                        fontFamily: "Mate",
                      }}
                    >
                      {churchDetails?.churchName}
                    </Text>
                    <Text
                      style={{ fontSize: 12, marginTop: 5, fontFamily: "Mate" }}
                    >
                      {churchDetails?.churchAddress?.addressLine1}
                    </Text>
                  </View>
                </View>

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: "2px solid #d4af37",
                      paddingTop: 8,
                      paddingBottom: 4,
                      margin: 0,
                      fontSize: 13,
                      fontFamily: "Mate",
                    }}
                  >
                    Extract from the Parochial Register of St Mary Church,
                    Archdiocese Of Delhi
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      fontFamily: "Dancing",
                      fontSize: 32,
                    }}
                  >
                    Marriage Certificate
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  {/* Dynamic content here */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Marriage Date:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {new Date(details?.marriageDate).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Marriage Place:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {details?.marriagePlace}
                      </Text>
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom Full Name:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {details?.groomMiddleName
                          ? `${details?.groomFirstName} ${details?.groomMiddleName} ${details?.groomLastName}`
                          : `${details?.groomFirstName} ${details?.groomLastName}`}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride Full Name:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {details?.brideMiddleName
                          ? `${details?.brideFirstName} ${details?.brideMiddleName} ${details?.brideLastName}`
                          : `${details?.brideFirstName} ${details?.brideLastName}`}
                      </Text>
                    </Text>
                  </View>

                  {/* Father Name */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom Father Name:{" "}
                      <Text style={styles.valueText}>
                        {details?.groomFatherName}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride Father Name:{" "}
                      <Text style={styles.valueText}>
                        {details?.brideFatherName}
                      </Text>
                    </Text>
                  </View>

                  {/* Mother Name */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom Mother Name:{" "}
                      <Text style={styles.valueText}>
                        {details?.groomMotherName}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride Mother Name:{" "}
                      <Text style={styles.valueText}>
                        {details?.brideMotherName}
                      </Text>
                    </Text>
                  </View>

                  {/* DOB */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom D.O.B:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {new Date(details?.groomDob).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride D.O.B:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {new Date(details?.brideDob).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Text>
                    </Text>
                  </View>

                  {/* Nationality */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom Nationality:{" "}
                      <Text style={styles.valueText}>
                        {details?.groomNationality}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride Nationality:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {details?.brideNationality}
                      </Text>
                    </Text>
                  </View>

                  {/* Domicile */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom Domicile:{" "}
                      <Text style={styles.valueText}>
                        {details?.groomDomicile}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride Domicile:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {details?.brideDomicile}
                      </Text>
                    </Text>
                  </View>

                  {/* Profession */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom Profession:{" "}
                      <Text style={styles.valueText}>
                        {details?.groomProfession}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride Profession:{" "}
                      <Text style={styles.valueText}>
                        {details?.brideProfession}
                      </Text>
                    </Text>
                  </View>

                  {/* MAritial Status */}
                  {/* 
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom's Marital Name:{" "}
                      <Text style={styles.valueText}>
                        {capitalizeText(details?.groomMarriageStatus)}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride's Marital Name:{" "}
                      <Text style={styles.valueText}>
                        {capitalizeText(details?.brideMarriageStatus)}
                      </Text>
                    </Text>
                  </View> */}

                  {/* Past Partner Name */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <Text style={styles.keyText}>
                      Groom's Past Partner Name:{" "}
                      <Text style={styles.valueText}>
                        {capitalizeText(details?.pastWifeName)}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Bride's Past Partner Name:{" "}
                      <Text style={styles.valueText}>
                        {capitalizeText(details?.pastHusbandName)}
                      </Text>
                    </Text>
                  </View>

                  {/* Witness Name */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      1st Witness Full Name:{" "}
                      <Text style={styles.valueText}>
                        {`${details?.firstWitnessFirstName} ${details?.firstWitnessLastName}`}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      2nd Witness Full Name:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {`${details?.secondWitnessFirstName} ${details?.secondWitnessLastName}`}
                      </Text>
                    </Text>
                  </View>

                  {/* Witness Domicile */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      1st Witness Domicile:{" "}
                      <Text style={styles.valueText}>
                        {details?.firstWitnessDomicile}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      2nd Witness Domicile:{" "}
                      <Text style={styles.valueText}>
                        {details?.secondWitnessDomicile}
                      </Text>
                    </Text>
                  </View>

                  {/*  */}

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      1st Banns Date:{" "}
                      <Text style={styles.valueText}>
                        {new Date(details?.firstBannsDate).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Ref Number:{" "}
                      <Text style={styles.valueText}>DEL/SMC/MRG/0009</Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      2nd Banns Date:{" "}
                      <Text style={styles.valueText}>
                        {" "}
                        {new Date(details?.secondBannsDate).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Impediment Dispensed From:{" "}
                      <Text style={styles.valueText}>
                        {details?.impediment}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.keyText}>
                      3rd Banns Date:{" "}
                      <Text style={styles.valueText}>
                        {new Date(details?.thirdBannsDate).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Text>
                    </Text>
                    <Text style={styles.keyText}>
                      Minister:{" "}
                      <Text style={styles.valueText}>
                        {details?.ministerName}
                      </Text>
                    </Text>
                  </View>

                  {/* remark */}
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#7e7e7e",
                      width: "100%",
                      marginTop: 10,
                      fontFamily: "Mate",
                    }}
                  >
                    Remark:
                  </Text>
                  <Text style={styles.valueText}>{details?.remark}</Text>
                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text style={{ fontSize: 12 }}>Parish Priest</Text>
                  <Text style={{ fontSize: 10, color: "#7e7e7e" }}>
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12 }}>Seal</Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.1, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderGray: {
    padding: 5,
    border: "3px solid #58595b",
    height: "100%",
  },
  borderRed: {
    border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    borderColor: "#d4af37",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    backgroundColor: "rgb(240, 229, 207, 0.5)",
    height: "100%",
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 10,
  },
  headerText: {
    textAlign: "center",
    width: "100%",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  image: {
    maxHeight: 100,
    maxWidth: 100,
    marginRight: 10,
  },
  keyText: {
    fontSize: 12,
    color: "#7e7e7e",
    marginBottom: 10,
    width: "50%",
    fontFamily: "Mate",
  },
  valueText: {
    fontSize: 12,
    color: "#000",
    textTransform: "capitalize",
    fontFamily: "Mate",
  },
});

export default MarriageCertificatePdf;
