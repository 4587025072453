export const transferDefaultTemplate = (churchDetails) => `

<div style="max-width: 600px; margin: 20px">
    <p>To</p>
    <br />
    <p>
      <strong style="color: red">
      The Parish Priest
      </strong><br/>
      
      [Church Name] <br/>
      [Church Address]<br/>
      [City, State, Zip Code]<br/>
    </p>
     <br />
    <p><strong>Subject: Transfer of Membership.</strong></p>
     <br />
    <p>Dear Rev. Father,</p>
    <br />
    <p>This is to certify that Mr. _______________ and Mrs. _______________, R/o, _______________, <b>[City], [Pincode], [State]</b> - are the registered members of our parish ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }. They are regular to the church and the church activities. They along with their sons/daughters Master/Miss _______________ and Master/Miss _______________ are shifting their residence to your parish. May I request you to kindly do the needful and register them.
    </p>
    <br />
    <p>
     Wishing them God’s Choicest blessings.
    </p>
    <br />
     <p>Thanking You</p>
    <p>Yours in Christ</p>
    <div style="margin-top: 30px;">
        <p>Fr. ${churchDetails?.fatherName}</p>
        <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>`;

export const characterDefaultTemplate = (churchDetails) => `
<div style="max-width: 600px; margin: 20px">
    <p>
      <strong>[Church Name]</strong><br/>
      [Church Address]<br/>
      [City, State, Zip Code]<br />
      [Date]
    </p>
    <br/>

     <p>
      <strong>[Parishioner's Name]</strong><br/>
      [Parishioner's Address]<br/>
      [City, State, Zip Code]<br />
    </p>
    <br/>

    <p>
    <strong>
    Subject: Character Reference Letter
    </strong>
    </p>

    <br/>

    <p>
     Dear [Parishioner's Name],
    </p>
    <br/>
    <p>
    I trust this letter finds you in good health and high spirits. It is with great joy and honor that I write this character reference letter on behalf of [Parishioner's Name].
    </p>
    <br/>
    <p>
      [Parishioner's Name] has been an active and devoted member of our church community here at ${churchDetails?.churchName}, ${churchDetails?.churchAddress?.city}, ${churchDetails?.churchAddress?.state}. Over the years, we have had the privilege of witnessing their unwavering commitment to their faith, their fellow parishioners, and the greater community.
    </p>
    <br/>
    <p>
    As [his/her] pastor, I can attest to [Parishioner's Name]'s exemplary character. He/She consistently demonstrates qualities such as compassion, kindness, and a genuine love for others. [Parishioner's Name] actively participates in various church activities, contributing time and talents to [specific activities, ministries, or events].
    </p>

    <p>
    I am confident that [Parishioner's Name] will continue to be a positive and uplifting influence in any community [he/she] becomes a part of. He/She possesses a strong moral compass, a genuine spirit of service, and a sincere desire to live according to the teachings of our faith.
    </p>
    <br/>
    <p>
    May the grace of our Lord be with [Parishioner's Name] as [he/she] embarks on new endeavors. We send him/her forth with our blessings and prayers for continued success and happiness.
    </p>

    <br/>
    <p>
    May God continue to guide and bless you abundantly.
    </p>
    <p>In Christ's love,</p>
    <br />
      <strong> 
        <p>Fr. ${churchDetails?.fatherName}</p>
      </strong>
      <p>[Contact Information]</p>
</div>
`;

export const membershipCertificateLetter = (churchDetails) => `
<div>
    <h2 style="text-align: center;">To Whomsoever It May Concern</h2>
    <br/>
    <p><strong>Subject: Membership Certificate.</strong></p>
    <p>Dear Rev. Father,</p>
    <br/>
    <p>This is to state that Master/Miss. _______________ S/o D/o Mr. _______________ is the registered member of this Parish, ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }. I am writing this to you to confirm his/her unity and regularity to the church and the church activities.</p>
    <p>Wishing him/her God’s choicest blessings.</p>
    <div style="margin-top: 30px;">
    <strong>    
      <p>Fr. ${churchDetails?.fatherName}</p>
    </strong>
        <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const marriagePreparationLetter = (churchDetails) => `
<div>
    <h2 style="text-align: center;">To Whomsoever It May Concern</h2>
    <br/>
    <p>Dear Rev. Father,</p>
    <br/>
    <p>
    This is to certify that Master/Miss. _______________ S/o D/o Mr. _______________ and Mrs. _______________, R/o _______________, ___________, <b>[City], [Pincode], [State]</b> - is a registered member of this Parish, ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }.
    </p>
    <p>He/She wishes to attend the required marriage preparation course before his/her marriage in your Centre. Kindly do the needful. </p>
    <p>I wish him/her all the best.</p>
    <p>Thanking You</p>
    <p>Yours in Christ</p>
    <br/>
    <div style="margin-top: 30px;">
      <strong>    
        <p>Fr. ${churchDetails?.fatherName}</p>
      </strong>
        <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const recommendationLetter = (churchDetails) => `
<div>
    <h2 style="text-align: center;">To Whomsoever It May Concern</h2>
    <br/>
    <p>Dear Sir,</p>
    <br/>
    <p>
    This is to certify that Master/Miss. _______________ S/o / D/o Mr _______________ and Mrs. _______________, R/o _______________, <b>[City]-[Pincode], [State]</b> - is a registered member of ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }.
    </p>
    <p>Our prayerful support and your kind assistance will help him/her to do better in future. We recommend him/her to get admitted in your institution. We will remain highly obliged to you.</p>
    <br/>
    <p>I wish him/her all the best.</p>
    <br/>
    <p>Thanking You</p>
    <p>Yours in Christ</p>
    <br/>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
         <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const admissionLetter = (churchDetails) => `
<div>
    <p>To</p>
    <br/>
    <p>
      <strong style="color: red">
      The Director
      </strong><br/>
      
      [Establishment Name] <br/>
      [Establishment Address]<br/>
      [City, State, Zip Code]<br/>     
    </p>
    <br/>
    <p><strong>Subject: Request for the Admission in the Hostel.</strong></p>
    <br/>
    <p>Dear Rev. Father/Sister,</p>
    <br/>
    <p>
    This is to state that Mr/Mrs. _______________, R/o _______________, <b>[City]-[Pincode], [State]</b> - is a registered member of this Parish, ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }. He/She wishes to get his/her child Master/Miss _______________ admitted in your _______________ Hostel.
    </p>
    <br/>
    <p>Our prayerful support and your kind assistance will help him/her to do better in future. We recommend him/her to get admitted in your institution. We will remain highly obliged to you.</p>
    <br/>
    <p>I wish him/her all the best.</p>
    <br/>
    <p>Thanking You</p>
    <p>Yours in Christ</p>
    <br/>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
        <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const marriageNOCLetter = (churchDetails) => `
<div>
    <h2 style="text-align: center;">To Whomsoever It May Concern</h2>
    <br/>
    <p>Dear Sir,</p>
    <br/>
    <p>
    This is to certify that Master/Miss _______________ S/o D/o Mr. _______________ and Mrs. _______________, R/o _______________, <b>[City]-[Pincode], [State]</b> - is a registered member of ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    } - is free to enter into the Matrimony with God’s blessings.

    </p>
    <p>This NOC is given based on his/her answers given in the Prenuptial enquiry under the obligation of an Oath about the truth of his/her answers.</p>
    <br/>
    <p>With Regards.</p>
    <br/>
    <p>Thanking You</p>
    <p>Yours in Christ</p>
    <br/>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
        <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const burialRequestToCemetery = (churchDetails) => `
<div>
    <p>To</p>
    <br/>
    <p>
      <strong style="color: red">
      The Chairman,
      </strong><br/>
      
      [Cemetery Name] <br/>
      [Cemetery Address]<br/>
      [City, State, Zip Code]<br/>     
    </p>
    <br/>
    <p><strong>Subject: Request for Burial.</strong></p>
    <br/>
    <p>Dear Sir,</p>
    <br/>
    <p>
    This is to state that Mrs. _______________ is a member of this Parish, ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }. He/She slept in the Lord on <b>[DD-MM-YYYY]</b>. They would like to have the burial on <b>[DD-MM-YYYY]</b> at <b>[HH:MM am/pm]</b> Kindly do the Needful.
    </p>
    <br/>
    <p>Thanking You</p>
    <p>Yours in Christ</p>
    <br/>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
        <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const burialRequestToAnotherParishPriest = (churchDetails) => `
<div>
    <p>To</p>
    <br/>
    <p>
      <strong style="color: red">
      The Parish Priest,
      </strong><br/>
      
      [Church Name] <br/>
      [Church Address]<br/>
      [City, State, Zip Code]<br/>     
    </p>
    <br/>
    <p><strong>Subject: Request for Burial.</strong></p>
    <br/>
    <p>Respected Father,</p>
    <br/>
    <p>
    This is to state that Mrs. _______________ is a member of our Parish, ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }. He/She breathed his last this morning <b>[DD-MM-YYYY]</b> at <b>[HH:MM am/pm]</b> at XXXX Hospital, Delhi. May I request you to Kindly do the needful at your Parish.
    </p>
    <br/>
    <p>Thanking You</p>
    <p>With Regards</p>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
        <p>Parish Priest</p>
        <p>${churchDetails?.churchName}</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const nocAfterMarriageBanns = (churchDetails) => `
<div>
    <p>To</p>
    <br/>
    <p>
      <strong style="color: red">
      The Parish Priest,
      </strong><br/>
      
      [Church Name] <br/>
      [Church Address]<br/>
      [City, State, Zip Code]<br/>     
    </p>
    <br/>
    <p><strong>Subject: Request for Burial.</strong></p>
    <br/>
    <p>Dear Father,</p>
    <br/>
    <p>
    Master/Miss. _______________ S/o D/o. Mr. _______________ and Mrs. _______________, presently R/o _______________, <b>[City]-[Pincode], [State]</b> - is a member of this parish (${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }) and Master/Miss. _______________ S/o D/o Mr. _______________ and Mrs. _______________ of _______________ Church, whose marriage banns were published in ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    } on DD-MM-YYYY , DD-MM-YYYY and DD-MM-YYYY, till date there has been no objection raised in this regard. Therefore, Master/Miss. _______________ is free to enter into the matrimony with God’s blessings. 
    </p>
    <br/>
    <p>With Regards</p>
    <p>Yours in Christ</p>
    <br/>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
        <p>Parish Priest</p>
        <p>${churchDetails?.churchName}</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const confirmationLetter = (churchDetails) => `
<div>
    <h2 style="text-align: center;">To Whomsoever It May Concern</h2>
    <br/>
    <p>Dear Rev. Father,</p>
    <br/>
    <p>
    This is to certify that Master/Miss/Mr./Mrs. _______________, S/o/D/o Mr. _______________ was Baptized in this church on DD-MM-YYYY and received the Sacrament of Confirmation on DD-MM-YYYY.
    </p>
    <p>This NOC is given based on his/her answers given in the Prenuptial enquiry under the obligation of an Oath about the truth of his/her answers.</p>
    <br/>
    <p>Wishing him God’s choicest blessings.</p>
    <br/>
    <p>With Regards,</p>
    <br/>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
        <p>Parish Priest</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;

export const recommendationLetterToArchbishop = (churchDetails) => `
<div>
    <p>To</p>
    <br/>
    <p>
      <strong style="color: red">
      Most. Rev. Anil J.T. Couto,
      </strong><br/>
      
      Archbishop of Delhi, <br/>
      Cathedral House, Delhi.<br/>
      [City, State, Zip Code]<br/>     
    </p>
    <br/>
    <p><strong>Subject: Request for the recommendation for the Admission in ______________.</strong></p>
    <br/>
    <p>Your Grace,</p>
    <br/>
    <p>
    This is to state that Master/Miss. _______________ S/o D/o Mr. _______________ is a registered member of this Parish, ${
      churchDetails?.churchName
    }, ${churchDetails?.churchAddress?.city}, ${
      churchDetails?.churchAddress?.state
    }. He/She is a very promising and studious boy/girl. He/She also participates in all the church activities. 
    </p>
    <p>He/She is seeking for the admission for __________ in __________. </p>
    <p>May I, therefore, request Your Grace to kindly recommend his/her application with your signature.
    </p>
    <br/>
    <p>Thanking you and seeking for your blessings.</p>
    <p>Yours in Christ</p>
    <br/>
    <div style="margin-top: 30px;">
        <strong>    
          <p>Fr. ${churchDetails?.fatherName}</p>
        </strong>
        <p>Parish Priest</p>
        <p>${churchDetails?.churchName}</p>
        <p>${new Date().toLocaleDateString("en-GB", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })}</p>
    </div>
</div>
`;
