import React from "react";
import { Placeholder } from "react-bootstrap";

const ZoneFamilyTablePlaceholder = () => {
  return (
    <tbody>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((p) => (
        <tr key={p}>
          <th>
            <Placeholder className="customPlaceholder" as="p" animation="glow">
              <Placeholder className="placeholderMarginBottom" xs="7" />
              <Placeholder className="placeholderMarginBottom" xs="4" />
            </Placeholder>
          </th>

          <td>
            <Placeholder className="customPlaceholder" as="p" animation="glow">
              <Placeholder className="placeholderMarginBottom" xs="7" />
              <Placeholder className="placeholderMarginBottom" xs="4" />
              <Placeholder className="placeholderMarginBottom" xs="6" />
            </Placeholder>
          </td>
          <td>
            <Placeholder className="customPlaceholder" as="p" animation="glow">
              <Placeholder className="placeholderMarginBottom" xs="7" />
              <Placeholder className="placeholderMarginBottom" xs="4" />
              <Placeholder className="placeholderMarginBottom" xs="6" />
            </Placeholder>
          </td>
          <td>
            <Placeholder className="customPlaceholder" as="p" animation="glow">
              <Placeholder className="placeholderMarginBottom" xs="7" />
              <Placeholder className="placeholderMarginBottom" xs="4" />
              <Placeholder className="placeholderMarginBottom" xs="6" />
            </Placeholder>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default ZoneFamilyTablePlaceholder;
