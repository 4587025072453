import { createSlice } from "@reduxjs/toolkit";

const HolyCommunionsSlice = createSlice({
  name: "holyCommunions",
  initialState: {
    present: false,
    holyCommunions: [],
    totalCount: 0,
    pageNumber: 1,
  },
  reducers: {
    getHolyCommunions(state, action) {
      state.holyCommunions = action.payload;
      state.present = true;
    },
    getTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    clearHolyCommunions(state, action) {
      state.holyCommunions = [];
      state.present = false;
    },
  },
});

export const HolyCommunionsAction = HolyCommunionsSlice.actions;
export const HolyCommunionsReducer = HolyCommunionsSlice.reducer;
