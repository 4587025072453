import * as Yup from "yup";

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const digitsOnly = (value) => /^\d+$/.test(value);

export const ExpenseValidationSchema = Yup.object().shape({
  totalExpenseAmount: Yup.string()
    .required("Required")
    .test("Digits only", "Digits only", digitsOnly),
  paymentMode: Yup.string().required("Required"),
  paidOn: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  purpose: Yup.string().required("Required"),
});

export const DonationValidationSchema = Yup.object().shape({
  donatedBy: Yup.string().required("Required"),
  totalDonationAmount: Yup.string()
    .required("Required")
    .test("Digits only", "Digits only", digitsOnly),
  paymentMode: Yup.string().required("Required"),
  paidOn: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  purpose: Yup.string().required("Required"),
});
