import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import SelectInput from "../../components/form/SelectInput";
import DateInput from "../../components/form/DateInput";
import { ZonesAction } from "../../store/slices/ZonesSlice";
import { useDispatch, useSelector } from "react-redux";

const AddFamilyFormStep1 = ({ commonAPIRequest, formik }) => {
  const [loading, setLoading] = useState(false);
  const [subZones, setSubZones] = useState([]);
  const { zones, present } = useSelector((state) => state.zones);
  const dispatch = useDispatch();

  const callGetAllZones = () => {
    let serviceParams = {
      api: API.GET_ALL_ZONES,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            ZonesAction.getZones(
              result?.payload.map((zone) => {
                return {
                  label: zone.zoneName,
                  value: zone.zoneId,
                  zoneDetails: zone,
                };
              })
            )
          );
          // formik1.setFieldValue("zone", result?.payload[0].zoneId);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetSubZones = () => {
    let serviceParams = {
      api: `${API.GET_ALL_SUB_ZONES}?zoneDetailId=${formik.values.zone}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setSubZoneLoader(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setSubZoneLoader(false);
        try {
          setSubZones(
            result?.payload.map((subZone) => {
              return {
                label: subZone.address,
                value: subZone.subZoneId,
              };
            })
          );
          if (result?.payload.length === 0) {
            formik.setFieldValue("subZone", "");
          } else {
            formik.setFieldValue("subZone", result?.payload[0].subZoneId);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setSubZoneLoader(false);
      }
    });
  };

  useEffect(() => {
    if (!present) {
      callGetAllZones();
    }
  }, []);

  useEffect(() => {
    if (formik.values.zone) {
      callGetSubZones();
    }
  }, [formik.values.zone]);

  return (
    <Row className="g-3">
      <Col sm={6}>
        <SelectInput
          selectOptions={zones}
          label={"Zone Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"zone"}
          id={"zone"}
          value={formik.values.zone}
          error={formik.errors.zone}
          touched={formik.touched.zone}
        />
      </Col>

      <Col sm={6}>
        <SelectInput
          selectOptions={subZones}
          label={"Subzone Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"subZone"}
          id={"subZone"}
          value={formik.values.subZone}
          error={formik.errors.subZone}
          touched={formik.touched.subZone}
          // disabled={subZones.length !== 0 ? false : true}
        />
      </Col>

      <Col sm={6}>
        <TextInput
          label={"Home Parish"}
          placeholder={"e.g. St. Marys, Delhi"}
          setFieldValue={formik.setFieldValue}
          keyword={"homeParish"}
          id={"homeParish"}
          value={formik.values.homeParish}
          error={formik.errors.homeParish}
          touched={formik.touched.homeParish}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Last Parish"}
          placeholder={"e.g. St. Joseph, Mumbai"}
          setFieldValue={formik.setFieldValue}
          keyword={"lastParish"}
          id={"lastParish"}
          value={formik.values.lastParish}
          error={formik.errors.lastParish}
          touched={formik.touched.lastParish}
        />
      </Col>

      <Col sm={12}>
        <DateInput
          label={"Date Of Registration"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"dateOfRegistration"}
          id={"dateOfRegistration"}
          value={formik.values.dateOfRegistration}
          error={formik.errors.dateOfRegistration}
          touched={formik.touched.dateOfRegistration}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Average Monthly Contribution"}
          placeholder={"e.g. 500"}
          setFieldValue={formik.setFieldValue}
          keyword={"monthlyDonation"}
          id={"monthlyDonation"}
          value={formik.values.monthlyDonation}
          error={formik.errors.monthlyDonation}
          touched={formik.touched.monthlyDonation}
        />
      </Col>
      <Col sm={12}>
        <TextArea
          label={"Address"}
          placeholder={"Add Address"}
          id={"addressLine1"}
          setFieldValue={formik.setFieldValue}
          keyword={"addressLine1"}
          value={formik.values.addressLine1}
          error={formik.errors.addressLine1}
          touched={formik.touched.addressLine1}
        />
      </Col>
    </Row>
  );
};

export default withAPIRequest(AddFamilyFormStep1);
