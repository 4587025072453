import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import NotFound from "./pages/NotFound";

// import css
import "./assets/css/react-datepicker.min.css";
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import Main from "./layouts/Main";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes, {
  dioceseProtectedRoutes,
} from "./routes/ProtectedRoutes";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import TranslucentLoader from "./components/TranslucentLoader";

import { AuthContext } from "react-oauth2-code-pkce";
import DioceseMain from "./layouts/DioceseMain";

function App() {
  const { loading, translucentLoader } = useSelector((state) => state.loading);

  const { token, tokenData, logOut } = useContext(AuthContext);

  return (
    <React.Fragment>
      <BrowserRouter>
        {translucentLoader && <TranslucentLoader />}
        {loading ? (
          <Loader />
        ) : (
          <Routes>
            <Route
              path="/admin"
              element={token ? <Main /> : <Navigate replace to={"/"} />}
            >
              {protectedRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                );
              })}
            </Route>
            <Route
              path="/diocese"
              element={token ? <DioceseMain /> : <Navigate replace to={"/"} />}
            >
              {dioceseProtectedRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                );
              })}
            </Route>
            {publicRoutes.map((route, index) => {
              return (
                <Route path={route.path} element={route.element} key={index} />
              );
            })}
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
