import React, { useEffect, useState } from "react";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import AnnulmentFormStep1 from "../../forms/annulment/AnnulmentFormStep1";
import AnnulmentFormStep2 from "../../forms/annulment/AnnulmentFormStep2";
import AnnulmentFormStep3 from "../../forms/annulment/AnnulmentFormStep3";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import Swal from "sweetalert2";

const EditAnnulmentDetailsModal = ({
  show,
  setShow,
  currentAnnulment,
  commonAPIRequest,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(1);

  const formik1 = useFormik({
    initialValues: {
      marriageDate: "",
      annulmentdate: "",
      tribunalName: "",
      civilDivorce: false,
      remark: "",
      registerRefNumber: null,
      annulmentDateNotSure: false,
    },
    //    validationSchema: AnnulmentFormStep1ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      groomFirstName: "",
      groomMiddleName: "",
      groomLastName: "",
      groomFatherName: "",
      groomMotherName: "",
      groomAddress: "",
    },
    //    validationSchema: AnnulmentFormStep2ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik3 = useFormik({
    initialValues: {
      brideFirstName: "",
      brideMiddletName: "",
      brideLastName: "",
      brideFatherName: "",
      brideMotherName: "",
      brideAddress: "",
    },
    //    validationSchema: AnnulmentFormStep3ValidationSchema,
    onSubmit: () => {
      callEditAnnulmentDetailsApi();
    },
  });

  // function to call API for Edit Annulment start

  const callEditAnnulmentDetailsApi = () => {
    let serviceParams = {
      api: API.EXTERNAL_ANNULMENT_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        annulmentDetail: {
          annulmentId: currentAnnulment?.annulmentId,
          annulmentDate: formik1.values.annulmentdate,
          groomFirstName: formik2.values.groomFirstName.trim(),
          groomMiddleName: formik2.values.groomMiddleName.trim(),
          groomLastName: formik2.values.groomLastName.trim(),
          groomFatherName: formik2.values.groomFatherName.trim(),
          groomMotherName: formik2.values.groomMotherName.trim(),
          groomAddress: formik2.values.groomAddress.trim(),
          brideFirstName: formik3.values.brideFirstName.trim(),
          brideMiddleName: formik3.values.brideMiddletName.trim(),
          brideLastName: formik3.values.brideLastName.trim(),
          brideFatherName: formik3.values.brideFatherName.trim(),
          brideMotherName: formik3.values.brideMotherName.trim(),
          brideAddress: formik3.values.brideAddress.trim(),
          tribunalName: formik1.values.tribunalName.trim(),
          civilDivorce: formik1.values.civilDivorce,
          remark: formik1.values.remark.trim(),
          active: true,
          regReferenceNum: formik1.values?.registerRefNumber
            ? +formik1.values?.registerRefNumber
            : null,
          annulmentDateAccurate: !formik1.values.annulmentDateNotSure,
        },
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Annulment Details Updated Successfully.`,
            "success"
          );
          //   getFamilyMemberDetails();
          setShow(false);
          formik1.resetForm();
          formik2.resetForm();
          formik3.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Edit Annulment end

  useEffect(() => {
    if (show) {
      //   formik1.setFieldValue("marriageDate", marriageDetails?.marriageDate);
      formik1.setFieldValue(
        "annulmentdate",
        new Date(currentAnnulment?.annulmentDate)
      );
      formik1.setFieldValue("tribunalName", currentAnnulment?.tribunalName);
      formik1.setFieldValue("civilDivorce", currentAnnulment?.civilDivorce);
      formik1.setFieldValue("remark", currentAnnulment?.remark);
      formik1.setFieldValue(
        "registerRefNumber",
        currentAnnulment?.registerRefNumber
      );
      formik1.setFieldValue(
        "annulmentDateNotSure",
        !currentAnnulment?.annulmentDateAccurate
      );

      //   grooms details
      formik2.setFieldValue("groomFirstName", currentAnnulment?.groomFirstName);
      formik2.setFieldValue(
        "groomMiddleName",
        currentAnnulment?.groomMiddleName
      );
      formik2.setFieldValue("groomLastName", currentAnnulment?.groomLastName);
      formik2.setFieldValue(
        "groomFatherName",
        currentAnnulment?.groomFatherName
      );
      formik2.setFieldValue(
        "groomMotherName",
        currentAnnulment?.groomMotherName
      );
      formik2.setFieldValue("groomAddress", currentAnnulment?.groomAddress);

      //   bride details
      formik3.setFieldValue("brideFirstName", currentAnnulment?.brideFirstName);
      formik3.setFieldValue(
        "brideMiddleName",
        currentAnnulment?.brideMiddleName
      );
      formik3.setFieldValue("brideLastName", currentAnnulment?.brideLastName);
      formik3.setFieldValue(
        "brideFatherName",
        currentAnnulment?.brideFatherName
      );
      formik3.setFieldValue(
        "brideMotherName",
        currentAnnulment?.brideMotherName
      );
      formik3.setFieldValue("brideAddress", currentAnnulment?.brideAddress);
    }
  }, [show]);

  //   console.log("aasasa", marriageDetails);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Annulment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span
              style={{ marginLeft: -54 }}
              className="fs-18 text-primary fw-bold"
            >
              Updating...
            </span>
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 3
                      ? "current last"
                      : currentState > 3
                      ? "done last"
                      : "last"
                  }
                  aria-disabled="true"
                >
                  <a id="wizard1-t-2" href="#" aria-controls="wizard1-p-2">
                    <span className="number">3</span>{" "}
                    <span className="title">Step 3</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <AnnulmentFormStep1
                    formik={formik1}
                    edit
                    // marriageDetails={marriageDetails}
                  />
                </div>
              )}
              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Groom's Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <AnnulmentFormStep2 formik={formik2} edit />
                </div>
              )}

              {currentState === 3 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Bride's Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <AnnulmentFormStep3 formik={formik3} edit />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (!(currentState <= 1)) {
                setCurrentState((prevValue) => prevValue - 1);
              }
            }}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (currentState === 1) {
                formik1.handleSubmit();
              } else if (currentState === 2) {
                formik2.handleSubmit();
              } else if (currentState === 3) {
                formik3.handleSubmit();
              }
            }}
          >
            {currentState === 3 ? "Save" : "Next"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditAnnulmentDetailsModal);
