import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  optionFour,
  optionOne,
  optionThree,
  optionTwo,
  seriesOne,
  seriesThree,
  seriesTwo,
} from "../../data/DashboardData";
import { Link, useNavigate } from "react-router-dom";

// -------------------------- HELPER FUNCTION ------------------------------

function formatNumber(number) {
  if (number >= 10000000) {
    // Convert to crores
    let crores = (number / 10000000).toFixed(2);
    return crores + " Cr";
  } else if (number >= 100000) {
    // Convert to lakhs
    let lakhs = (number / 100000).toFixed(2);
    return lakhs + " L";
  } else if (number >= 1000) {
    // Convert to thousands
    let thousands = (number / 1000).toFixed(2);
    return thousands + " K";
  } else {
    // If the number is less than 1000, return it as is
    return number.toString();
  }
}

// -------------------------- HELPER FUNCTION ------------------------------

const FinanceSection = ({ data }) => {
  const navigate = useNavigate();
  const [showDonation, setShowDonation] = useState(false);
  const [showCsf, setShowCsf] = useState(false);
  const [showDonationMonthly, setShowDonationMonthly] = useState(false);
  const [showCsfMonthly, setShowCsfMonthly] = useState(false);
  return (
    <Row className="m-0 p-0">
      <Col xl="6">
        <Card className="card-one card-ticket-earnings">
          <Card.Body className="d-flex flex-column p-4">
            <div className="d-flex justify-content-start align-items-center">
              <h1
                style={
                  showDonation
                    ? {}
                    : {
                        textShadow: "0px 0px 16px black",
                        color: "transparent",
                      }
                }
                className="card-value mb-3 fs-40 ls--2"
              >
                <span>₹</span>{" "}
                {data?.totalDonation
                  ? formatNumber(data?.totalDonation).toLocaleString("en-IN")
                  : 0}
              </h1>
              <Button
                className="ms-3 mb-3"
                onClick={() => setShowDonation(!showDonation)}
                variant="light"
              >
                {showDonation ? (
                  <i className="fs-24 ri-eye-fill"></i>
                ) : (
                  <i className="fs-24 ri-eye-off-fill"></i>
                )}
              </Button>
            </div>

            <label className="card-label fw-semibold text-dark mb-1">
              Total Donation
            </label>

            <p className="w-75 fs-sm text-secondary mb-4">
              This is Donation's Received up until now in{" "}
              {new Date().getFullYear()}.
            </p>
            <p className="mb-5">
              <Button
                onClick={() => navigate("/admin/donations")}
                as="a"
                variant="primary"
                className="fs-sm"
              >
                View Statements
              </Button>
            </p>

            <div className="d-flex gap-4 fs-sm mt-auto text-primary-dark lh-1 opacity-75">
              <span className="fs-12 fw-bold">
                <strong className="fw-bold fs-18 text-danger ">
                  ₹{" "}
                  {data?.totalExpense
                    ? formatNumber(data?.totalExpense).toLocaleString("en-IN")
                    : "0"}
                </strong>{" "}
                Total Expenses
              </span>
              <span className="fs-12 fw-bold">
                <strong className="fw-bold fs-18 text-danger ">
                  ₹
                  {data?.expensesThisMonth
                    ? formatNumber(data?.expensesThisMonth).toLocaleString(
                        "en-IN"
                      )
                    : "0"}
                </strong>{" "}
                Expenses This Month
              </span>
            </div>
          </Card.Body>
          <ReactApexChart
            series={seriesOne}
            options={optionOne}
            type="area"
            height={390}
            className="apex-chart-two d-flex align-items-end"
          />
        </Card>
      </Col>
      <Col xl="6">
        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one card-ticket-available">
              <Card.Body className="p-4">
                <div className="d-flex justify-content-end">
                  <div className="w-45">
                    <div className="d-flex justify-content-start align-items-center">
                      <h1
                        style={
                          showCsf
                            ? {}
                            : {
                                textShadow: "0px 0px 16px black",
                                color: "transparent",
                              }
                        }
                        className="card-value fs-24 mb-2 ls--1"
                      >
                        ₹{" "}
                        {data?.totalSupportFund
                          ? formatNumber(data?.totalSupportFund).toLocaleString(
                              "en-IN"
                            )
                          : 0}
                      </h1>
                      <Button
                        className="ms-3 mb-3"
                        onClick={() => setShowCsf(!showCsf)}
                        variant="light"
                      >
                        {showCsf ? (
                          <i className="fs-20 ri-eye-fill"></i>
                        ) : (
                          <i className="fs-20 ri-eye-off-fill"></i>
                        )}
                      </Button>
                    </div>
                    <label className="card-label fw-semibold text-dark mb-1">
                      Total Csf Collected
                    </label>
                    <p className="text-secondary fs-sm mb-0">
                      This is Support Fund's Received up until now in{" "}
                      {new Date().getFullYear()}.
                    </p>
                  </div>
                </div>
              </Card.Body>
              <ReactApexChart
                series={seriesTwo}
                options={optionTwo}
                type="bar"
                height={170}
                className="apex-chart"
              />
            </Card>
          </Col>
          <Col sm="6">
            <Card className="card-one card-ticket-sold">
              <Card.Body className="p-4">
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex justify-content-end align-items-center">
                    <h1
                      style={
                        showDonationMonthly
                          ? {}
                          : {
                              textShadow: "0px 0px 16px black",
                              color: "transparent",
                            }
                      }
                      className="card-value fs-24 mb-2 ls--1"
                    >
                      ₹{" "}
                      {data?.donationsThisMonth
                        ? formatNumber(data?.donationsThisMonth).toLocaleString(
                            "en-IN"
                          )
                        : 0}
                    </h1>
                    <Button
                      className="ms-1 mb-2"
                      onClick={() =>
                        setShowDonationMonthly(!showDonationMonthly)
                      }
                      variant="light"
                    >
                      {showDonationMonthly ? (
                        <i className="fs-18 ri-eye-fill"></i>
                      ) : (
                        <i className="fs-18 ri-eye-off-fill"></i>
                      )}
                    </Button>
                  </div>
                  <label className="card-label fw-semibold text-dark mb-1">
                    Donation Collected
                  </label>
                  <p className="text-secondary text-center fs-sm mb-0">
                    in{" "}
                    {new Date().toLocaleDateString("en-IN", {
                      month: "long",
                      year: "numeric",
                    })}{" "}
                  </p>
                </div>
              </Card.Body>
              <ReactApexChart
                series={seriesThree}
                options={optionThree}
                type="area"
                height={210}
                className="apex-chart"
              />
            </Card>
          </Col>
          <Col sm="6">
            <Card className="card-one card-ticket-sold">
              <Card.Body className="p-4">
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex justify-content-end align-items-center">
                    <h1
                      style={
                        showCsfMonthly
                          ? {}
                          : {
                              textShadow: "0px 0px 16px black",
                              color: "transparent",
                            }
                      }
                      className="card-value fs-24 mb-2 ls--1"
                    >
                      ₹{" "}
                      {data?.supportFundsThisMonth
                        ? formatNumber(
                            data?.supportFundsThisMonth
                          ).toLocaleString("en-IN")
                        : 0}
                    </h1>
                    <Button
                      className="ms-1 mb-2"
                      onClick={() => setShowCsfMonthly(!showCsfMonthly)}
                      variant="light"
                    >
                      {showCsfMonthly ? (
                        <i className="fs-18 ri-eye-fill"></i>
                      ) : (
                        <i className="fs-18 ri-eye-off-fill"></i>
                      )}
                    </Button>
                  </div>
                  <label className="card-label fw-semibold text-dark mb-1">
                    CSF Collected
                  </label>
                  <p className="text-secondary text-center fs-sm mb-0">
                    in{" "}
                    {new Date().toLocaleDateString("en-GB", {
                      month: "long",
                      year: "numeric",
                    })}{" "}
                  </p>
                </div>
              </Card.Body>
              <ReactApexChart
                series={seriesThree}
                options={optionFour}
                type="area"
                height={210}
                className="apex-chart"
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FinanceSection;
