import React from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const ImportMemberCreateNewFamilyModal = ({
  show,
  setShow,
  basicDetails,
  setPreMarriageModalVisible,
  setNewFamilyDetailsModalVisible,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      className="bg-primary"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Create New Family
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="fw-bold">
          Do you want to create a new family for{" "}
          <span className="text-primary">
            {basicDetails?.firstName} {basicDetails?.middleName}{" "}
            {basicDetails?.lastName}
          </span>
          .
        </h5>
        <p>
          {" "}
          <span className="text-primary">
            {basicDetails?.firstName} {basicDetails?.middleName}{" "}
            {basicDetails?.lastName}
          </span>{" "}
          will be the Head of Family in his new family.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            if (
              basicDetails?.relationToHead === "OTHERS" ||
              basicDetails?.relationToHead === "COUSIN" ||
              basicDetails?.relationToHead === "NEPHEW"
            ) {
              Swal.fire(
                "Warning",
                "Please Create a new family for Relation like Cousin, Nephew & Others.",
                "warning"
              );
            } else {
              setPreMarriageModalVisible(true);
              setShow(false);
            }
          }}
        >
          NO
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setNewFamilyDetailsModalVisible(true);
            setShow(false);
          }}
        >
          YES
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportMemberCreateNewFamilyModal;
