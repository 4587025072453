import React, { useState } from "react";
import Header from "../layouts/Header";
import Lottie from "lottie-react";
import commingSoonAnimation from "./../assets/lottie/coming_soon_lottie_delhi_diocese_theme.json";
import { Button } from "react-bootstrap";
import ExportPDFComponent from "../components/sacrament-certificates/TrialPdf";
import { BlobProvider, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import CSFReceiptPdf from "../components/donation-receipt/CSFReceiptPdf";
import { useSelector } from "react-redux";
import DonationReciptPdf from "../components/donation-receipt/DonationReceiptPdf";

const Feeds = () => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { churchDetails } = useSelector((state) => state?.church);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center mb-4">
          <Lottie
            style={{ width: 240, height: 240 }}
            animationData={commingSoonAnimation}
            loop={true}
          />
          <h3 className="text-center fw-bold">
            Feeds is{" "}
            <span style={{ fontSize: 32 }} className="fw-light text-primary">
              coming soon
            </span>
            .{" "}
          </h3>
        </div>
        {/* <PDFViewer
          style={{
            width: "100%", // Set width
            height: "100vh", // Set height
          }}
        >
          <DonationReciptPdf churchDetails={churchDetails} />
        </PDFViewer> */}

        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
};

export default Feeds;
