import React from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";

const BaptismFormStep2 = ({ formik }) => {
  return (
    <Row className="g-3">
      <Col sm={6}>
        <TextInput
          label={"Father's Nationality"}
          setFieldValue={formik.setFieldValue}
          keyword={"fatherNationality"}
          value={formik.values.fatherNationality}
          error={formik.errors.fatherNationality}
          touched={formik.touched.fatherNationality}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Father's Profession"}
          setFieldValue={formik.setFieldValue}
          keyword={"fatherProfession"}
          value={formik.values.fatherProfession}
          error={formik.errors.fatherProfession}
          touched={formik.touched.fatherProfession}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Father's Domicile"}
          setFieldValue={formik.setFieldValue}
          keyword={"fatherDomicile"}
          value={formik.values.fatherDomicile}
          error={formik.errors.fatherDomicile}
          touched={formik.touched.fatherDomicile}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"God Father's First Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"godFatherFirstName"}
          value={formik.values.godFatherFirstName}
          error={formik.errors.godFatherFirstName}
          touched={formik.touched.godFatherFirstName}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"God Father's Last Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"godFatherLastName"}
          value={formik.values.godFatherLastName}
          error={formik.errors.godFatherLastName}
          touched={formik.touched.godFatherLastName}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"God Father's Domicile"}
          setFieldValue={formik.setFieldValue}
          keyword={"godFatherDomicile"}
          value={formik.values.godFatherDomicile}
          error={formik.errors.godFatherDomicile}
          touched={formik.touched.godFatherDomicile}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"God Mother's First Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"godMotherFirstName"}
          value={formik.values.godMotherFirstName}
          error={formik.errors.godMotherFirstName}
          touched={formik.touched.godMotherFirstName}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"God Mother's Last Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"godMotherLastName"}
          value={formik.values.godMotherLastName}
          error={formik.errors.godMotherLastName}
          touched={formik.touched.godMotherLastName}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"God Mother's Domicile"}
          setFieldValue={formik.setFieldValue}
          keyword={"godMotherDomicile"}
          value={formik.values.godMotherDomicile}
          error={formik.errors.godMotherDomicile}
          touched={formik.touched.godMotherDomicile}
        />
      </Col>
    </Row>
  );
};

export default BaptismFormStep2;
