import React, { useState } from "react";
import Header from "../layouts/Header";
import Lottie from "lottie-react";
import commingSoonAnimation from "./../assets/lottie/animation_lna9lsvn.json";

const DioceseFeeds = () => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center mb-4">
          <Lottie
            style={{ width: 240, height: 240 }}
            animationData={commingSoonAnimation}
            loop={true}
          />
          <h3 className="text-center fw-bold">
            Feeds is{" "}
            <span style={{ fontSize: 32 }} className="fw-light text-primary">
              coming soon
            </span>
            .{" "}
          </h3>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DioceseFeeds;
