import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const ActiveStatusParishModal = ({
  show,
  setShow,
  churchId,
  status,
  commonAPIRequest,
  callGetAllChurches,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      active: false,
    },

    onSubmit: (values) => {
      if (values.active) {
        callUpdateChurchStatusToEnable();
        console.log("Enable");
      } else {
        callUpdateChurchStatusToDisable();
        console.log("Disable");
      }
    },
  });

  const callUpdateChurchStatusToEnable = () => {
    let serviceParams = {
      api: `${API.CHURCH_ENABLE}?churchId=${churchId}`,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {},
    };
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setShow(false);
        // dispatch(LoadingAction.endTranslucentLoader());
        try {
          //  setParishes(result?.payload?.churchList);
          callGetAllChurches();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  const callUpdateChurchStatusToDisable = () => {
    let serviceParams = {
      api: `${API.CHURCH_DISABLE}?churchId=${churchId}`,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {},
    };
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setShow(false);
        // dispatch(LoadingAction.endTranslucentLoader());
        try {
          //  setParishes(result?.payload?.churchList);
          callGetAllChurches();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  useEffect(() => {
    if (status === "Y") {
      formik.setFieldValue("active", true);
    } else if (status === "N") {
      formik.setFieldValue("active", false);
    }
  }, [status]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Parish Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              <p>
                Current Status Of Parish:{" "}
                <span className="fw-bold text-primary">Active</span>
              </p>
              <Col
                sm={12}
                className="justify-content-end align-items-end d-flex"
              >
                <Form.Check
                  type="switch"
                  checked={formik.values.active}
                  label="Active?"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    formik.setFieldValue("active", e.target.checked);
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(ActiveStatusParishModal);
