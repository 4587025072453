import React from "react";
import { Card, Col, Placeholder, Row } from "react-bootstrap";

const ZonePlaceholder = () => {
  return (
    <Row>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((zone) => (
        <Col key={zone} md={4}>
          <Card md="4">
            <Card.Body>
              <Card.Title>
                <Placeholder xs="6" animation="glow"></Placeholder>
              </Card.Title>
              <div>
                <Placeholder as="p" animation="glow">
                  <Placeholder xs="7" />
                  <Placeholder xs="4" />
                  <Placeholder xs="6" />
                  <Placeholder xs="8" />
                </Placeholder>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ZonePlaceholder;
