import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const CustomPopover = ({ placement, children, header, content }) => {
  return (
    <OverlayTrigger
      trigger="focus"
      key={placement}
      placement={placement}
      overlay={
        <Popover id={`popover-positioned-${placement}`}>
          {header && (
            <Popover.Header className="bg-primary text-white" as="h3">
              {header}
            </Popover.Header>
          )}
          <Popover.Body>{content}</Popover.Body>
        </Popover>
      }
    >
      <Button variant="outline">{children}</Button>
    </OverlayTrigger>
  );
};

export default CustomPopover;
