import React from "react";
import { Button } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { downloadExcel } from "react-export-table-to-excel";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const CsfExcelReport = ({ totalCount, currentMonth, commonAPIRequest }) => {
  const header = [
    "S.No",
    "Family ID",
    "Name",
    "Amount",
    "Paid On",
    "Mode",
    "Transaction Id",
  ];

  // call api for get all csf

  const getAllCsfsApi = () => {
    let serviceParams = {
      api: `${
        API.GET_CSF_SUMMARY
      }?pgNum=1&pgSize=${totalCount}&month=${currentMonth}&year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          const data = result.payload?.consolidatedSupportFundDetailList.map(
            (csf, i) => {
              return {
                sno: i + 1,
                familyId: csf?.churchFamilyId,
                name: `${csf?.firstName} ${csf?.lastName}`,
                amount: csf?.fundAmount,
                donatedOn: new Date(csf?.collectionDate).toLocaleDateString(
                  "en-IN"
                ),
                mode: csf?.paymentMode,
                txn: csf?.sysTransactionId,
              };
            }
          );
          downloadExcel({
            fileName: `Csf_MONTH_${currentMonth}_${new Date().getFullYear()}`,
            sheet: "CSF",
            tablePayload: {
              header,
              // accept two different data structures
              body: data,
            },
          });
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  function handleDownloadExcel() {
    getAllCsfsApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(CsfExcelReport);
