import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ZonePlaceholder from "../../components/placeholders/ZonePlaceholder";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import img10 from "../../assets/img/img10.jpg";
import { useDispatch } from "react-redux";
import { LoadingAction } from "../../store/slices/LoadingSlice";
import Swal from "sweetalert2";

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required").min(3),
});

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const ImportMemberModal = ({
  show,
  setShow,
  commonAPIRequest,
  basicDetails,
  familyDetails,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [members, setMembers] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchUsersApi(fullNameArray[0], "")
        : callSearchUsersApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  const callSearchUsersApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${API.SEARCH_MEMBER}?firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }&filterBy=All`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setMembers(
            result.payload.searchSuggestions.length !== 0
              ? result.payload.searchSuggestions
              : []
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //   call api to transfer member as wife from 1 family to another start

  const callTransferMemberApi = (memberId, familyId) => {
    let serviceParams = {
      api: API.TRANSFER_MEMBER,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        familyId: familyDetails?.familyId,
        memberId: memberId,
        relationToHead: basicDetails?.familyHead
          ? "WIFE"
          : basicDetails?.relationToHead === "SON"
          ? "DAUGHTER IN LAW"
          : basicDetails?.relationToHead === "BROTHER"
          ? "SISTER IN LAW"
          : basicDetails?.relationToHead === "UNCLE"
          ? "AUNT"
          : basicDetails?.relationToHead === "GRAND SON"
          ? "GRAND DAUGHTER IN LAW"
          : basicDetails?.relationToHead === "FATHER"
          ? "MOTHER"
          : basicDetails?.relationToHead === "GRAND FATHER"
          ? "GRAND MOTHER"
          : "OTHERS",
      },
    };
    if (familyId !== familyDetails?.familyId) {
      dispatch(LoadingAction.startTranslucentLoader());
      commonAPIRequest(serviceParams, async (result) => {
        if (result) {
          dispatch(LoadingAction.endTranslucentLoader());
          try {
            Swal.fire("Imported!", "Successfully Imported.", "success");
            getFamilyMemberDetails();
            setShow(false);
          } catch (error) {
            console.log("error", error);
          }
        } else {
          dispatch(LoadingAction.endTranslucentLoader());
        }
      });
    } else {
      Swal.fire("Error", "Member already present.", "warning");
    }
  };

  //   call api to transfer member as wife from 1 family to another end

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setMembers([]);
      }}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Import Member Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 500, overflowY: "auto" }}>
        <h5 className="fw-bold">
          Search for {basicDetails?.firstName}'s wife from the parish.
        </h5>
        <p>
          Type the name of{" "}
          <span className="text-primary fw-bold">
            {" "}
            {basicDetails?.firstName} {basicDetails?.middleName}{" "}
            {basicDetails?.lastName}'s
          </span>{" "}
          wife and import her to his family, Provided she is already a member of
          this parish.
          <br />
        </p>

        <div className="form-search py-2  ">
          <i className="ri-search-line"></i>
          <Form.Control
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            type="text"
            placeholder="Type wife name here..."
          />
          <Button onClick={formik.handleSubmit} variant="primary">
            Search
          </Button>
        </div>
        <div className="mb-5">
          {formik.errors.name && formik.touched.name && (
            <p className="text-danger">*{formik.errors.name}</p>
          )}
        </div>
        {loading && <ZonePlaceholder />}

        {!loading && (
          <Row className="g-2 g-xxl-3 mb-5">
            {members.map(
              (member, index) =>
                !(
                  member?.familyHead ||
                  member?.gender === "Male" ||
                  member?.marriageStatus === "MARRIED" ||
                  member?.relationToHead === "WIFE" ||
                  member?.relationToHead === "DAUGHTER IN LAW" ||
                  member?.relationToHead === "SISTER IN LAW" ||
                  member?.relationToHead === "GRAND DAUGHTER IN LAW" ||
                  member?.relationToHead === "MOTHER" ||
                  member?.relationToHead === "GRAND MOTHER" ||
                  getAge(member?.dateOfBirth) < 18
                ) && (
                  <Col lg={6} key={index}>
                    {
                      <Card className="card-people">
                        <Card.Body>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="mb-4"
                          >
                            <Avatar img={img10} size="xl" />
                            <div className="ms-3">
                              <h6 className="mt-3">
                                <Link to="">
                                  {member.firstName} {member.lastName}
                                </Link>
                              </h6>
                              <p className="m-0 fw-bold text-primary">
                                {member.churchFamilyId}
                              </p>
                              <p className="m-0">{member?.emailAddress}</p>
                              {member?.familyHead ? (
                                <Badge pill bg="success">
                                  Head Of Family
                                </Badge>
                              ) : (
                                <Badge pill bg="warning">
                                  Member
                                </Badge>
                              )}
                            </div>
                          </div>

                          <div className="d-grid">
                            <Button
                              disabled={
                                member?.familyHead ||
                                member?.gender === "Male" ||
                                member?.marriageStatus === "MARRIED"
                              }
                              className="align-items-center justify-content-center d-flex"
                              onClick={() => {
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, Import",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    callTransferMemberApi(
                                      member?.memberId,
                                      member?.familyId
                                    );
                                  }
                                });
                              }}
                            >
                              <i
                                style={{ fontSize: 18 }}
                                className="ri-user-line me-2 align-self-center"
                              ></i>
                              Import
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    }
                  </Col>
                )
            )}
          </Row>
        )}

        {!loading && members.length === 0 && (
          <div className="mb-5">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 120, height: 120 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
            <h3 className="text-center fw-bold">No Parishioner Found</h3>
            <h5 className="text-center fw-400">
              Please Try With Another Name.
            </h5>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default withAPIRequest(ImportMemberModal);
