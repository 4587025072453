import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import AddHolyOrderDetailsDetailsModal from "../../modals/holy-order/AddHolyOrderDetailsDetailsModal";

const HolyOrderDetails = ({
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  holyConfirmationDetails,
  holyOrderDetails,
  deathDetails,
  marriageDetails,
}) => {
  const [addHolyOrderModal, setAddHolyOrderModal] = useState(false);
  return (
    <div>
      {holyOrderDetails ? (
        <Card className="card-post mt-4 ">
          <Card.Header>
            <h3 className="fw-bold text-primary">Holy Order Information</h3>
          </Card.Header>
          <hr className="hr m-0" />
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Holy Order Done?</strong>
                      </p>
                      <p className="mb-0">Yes</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Date Of Ordination</strong>
                      </p>
                      <p className="mb-0">
                        {" "}
                        {new Date(
                          holyOrderDetails?.ordinationDate
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Date Of Final Vows</strong>
                      </p>
                      <p className="mb-0">
                        {new Date(
                          holyOrderDetails?.finalVowsDate
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Diocese</strong>
                      </p>
                      <p className="mb-0">{holyOrderDetails?.diocese}</p>
                    </div>
                  </Col>
                  <Col sm={10}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Remarks</strong>
                      </p>
                      <p className="mb-0">{holyOrderDetails?.remark}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <Card.Body className="text-center m-4">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 150, height: 150 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
          </div>
          <Card.Title className="text-dark">
            No Holy Order Details Found
          </Card.Title>
          <Card.Text>
            Please add holy order details for{" "}
            <span className="fw-bold text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </span>{" "}
            and generate certificate.
            <br />
            <span className="text-danger fw-bold">
              NOTE: *Holy Confirmation is required for adding Holy order
              details.
            </span>
          </Card.Text>
          <Button
            disabled={
              holyConfirmationDetails?.confirmationId &&
              !deathDetails &&
              !marriageDetails
                ? false
                : true
            }
            variant="primary"
            onClick={() => {
              setAddHolyOrderModal(true);
            }}
          >
            Add
          </Button>
        </Card.Body>
      )}
      <AddHolyOrderDetailsDetailsModal
        setShow={setAddHolyOrderModal}
        show={addHolyOrderModal}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
    </div>
  );
};

export default HolyOrderDetails;
