import { useFormik } from "formik";
import Lottie from "lottie-react";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import ImageUploadInput from "../../components/form/ImageUploadInput";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const UpdatePriestImagesModal = ({
  show,
  setShow,
  commonAPIRequest,
  callGetAllPriests,
  priestId,
}) => {
  const [loading, setLoading] = useState(false);

  const callUpdatePriestImageApi = (priestId, base64, type) => {
    let serviceParams = {
      api: API.PRIEST_IMAGE,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        base64: base64,
        format: type.split("/").pop().toLowerCase(),
        imageType: "image",
        priestDetailId: priestId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          callGetAllPriests();
          setShow(false);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callDeletePriestImageApi = (priestId) => {
    let serviceParams = {
      api: `${API.PRIEST_IMAGE}?priestDetailId=${priestId}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          callGetAllPriests();
          setShow(false);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Priest Image Update
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              {/* <p>
                Current Status Of Parish:{" "}
                <span className="fw-bold text-primary">Active</span>
              </p> */}
              {/* <Col
                sm={12}
                className="justify-content-end align-items-end d-flex"
              >
                <Form.Check
                  type="switch"
                  checked={formik.values.active}
                  label="Active?"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    formik.setFieldValue("active", e.target.checked);
                  }}
                />
              </Col> */}

              <Col xxl={12} className="p-2">
                <ImageUploadInput
                  onImageSelected={(base64, file) =>
                    callUpdatePriestImageApi(priestId, base64, file.type)
                  }
                />
              </Col>

              <Col xxl={12} className="p-2 my-4 justify-content-end d-flex">
                <Button
                  onClick={() => callDeletePriestImageApi(priestId)}
                  variant="danger"
                >
                  Remove Current Image
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {/* {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )} */}
    </Modal>
  );
};

export default withAPIRequest(UpdatePriestImagesModal);
