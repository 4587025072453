import React, { useState } from "react";
import Header from "../layouts/Header";
import { Button, Card, Nav } from "react-bootstrap";
import FamilyMembersTable from "../tables/FamilyMembersTable";
import { useLocation, useNavigate } from "react-router-dom";
import CsfDetails from "./CsfDetails";
import BasicFamilyDetails from "./BasicFamilyDetails";
import { useDispatch } from "react-redux";
import { FamilyAction } from "../store/slices/FamilySlice";

const FamilyDetails = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [currentOption, setCurrentOption] = useState("2");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex">
          <Button
            onClick={() => {
              if (location?.state?.back) {
                navigate("/admin/families");
                dispatch(FamilyAction.clearFamilies());
              } else {
                navigate(-1);
                dispatch(FamilyAction.clearFamilies());
              }
            }}
            className="me-2"
            variant="outline"
          >
            <i className="ri-arrow-left-line"></i>
          </Button>
          <div>
            <h5 id="section3" className="main-subtitle mb-0">
              <span className="m-0">Registered Family Details</span>
            </h5>
            {localStorage.getItem("currentChurchFamilyId") !== "undefined" && (
              <div className="">
                CHURCH FAMILY ID -{" "}
                <strong className="text-primary">
                  {localStorage.getItem("currentChurchFamilyId")}
                </strong>
              </div>
            )}
          </div>
        </div>

        {/* <p>
          Add <code>hover</code> props to enable a hover state on table rows
          within a tbody.
        </p> */}

        <Card className="card-family-details">
          <Card.Body className="pb-0">
            <Nav variant="pills" activeKey={currentOption}>
              <Nav.Item onClick={() => setCurrentOption("1")}>
                <Nav.Link eventKey="1">Basic Information</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setCurrentOption("2")}>
                <Nav.Link eventKey="2" title="Item">
                  Family Members
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setCurrentOption("3")}>
                <Nav.Link eventKey="3">CSF (Church Support Fund)</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Body>
          {/* <Card.Footer>
            <pre> */}
          {currentOption == 1 && (
            <BasicFamilyDetails
              familyId={localStorage.getItem("currentFamilyId")}
              active={currentOption == 1}
            />
          )}
          {currentOption == 2 && (
            <FamilyMembersTable
              familyId={localStorage.getItem("currentFamilyId")}
              active={currentOption == 2}
            />
          )}
          {currentOption == 3 && (
            <CsfDetails
              familyId={localStorage.getItem("currentFamilyId")}
              active={currentOption == 3}
            />
          )}
          {/* </pre>
          </Card.Footer> */}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default FamilyDetails;
