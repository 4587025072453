import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import DateInput from "../../components/form/DateInput";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import TextArea from "../../components/form/TextArea";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const EditFamilyMemberFormStep1 = ({ formik, commonAPIRequest }) => {
  const [relations, setRelations] = useState([]);
  const [baptismNameDifferent, setBaptismNameDifferent] = useState(false);

  const getAllRelationsList = (gender) => {
    let serviceParams = {
      api: `${API.GET_RELATION}?gender=${gender}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          setRelations(
            result.payload.map((relation) => {
              return {
                label: relation.relation,
                value: relation.relation,
              };
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (formik.values.gender) {
      getAllRelationsList(formik.values.gender);
    }
  }, [formik.values.gender]);

  useEffect(() => {
    if (formik.values.baptismName) {
      setBaptismNameDifferent(true);
    }
  }, [formik.values.baptismName]);

  return (
    <Row>
      <Col sm={4}>
        <TextInput
          label={"First Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstName"}
          value={formik.values.firstName}
          error={formik.errors.firstName}
          touched={formik.touched.firstName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Middle Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"middleName"}
          value={formik.values.middleName}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Last Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"lastName"}
          value={formik.values.lastName}
          error={formik.errors.lastName}
          touched={formik.touched.lastName}
        />
      </Col>

      <Col sm={12} className="mt-3">
        <Form.Check
          className="fw-bold"
          type="switch"
          label="Baptism name different from the name entered above?"
          checked={baptismNameDifferent}
          onChange={(e) => {
            setBaptismNameDifferent(e.target.checked);
          }}
        />
      </Col>

      {baptismNameDifferent && (
        <Col sm={12}>
          <TextInput
            label={"Baptism Name"}
            placeholder={"e.g. John"}
            setFieldValue={formik.setFieldValue}
            keyword={"baptismName"}
            value={formik.values.baptismName}
            error={formik.errors.baptismName}
            touched={formik.touched.baptismName}
          />
        </Col>
      )}

      <Col sm={6}>
        <TextInput
          label={"Father's Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"fatherName"}
          value={formik.values.fatherName}
          error={formik.errors.fatherName}
          touched={formik.touched.fatherName}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Mother's Name"}
          placeholder={"e.g. Nikhil"}
          setFieldValue={formik.setFieldValue}
          keyword={"motherName"}
          value={formik.values.motherName}
          error={formik.errors.motherName}
          touched={formik.touched.motherName}
        />
      </Col>

      <Col sm={6}>
        <DateInput
          label={"Date Of Birth"}
          setFieldValue={formik.setFieldValue}
          keyword={"dob"}
          value={formik.values.dob}
          error={formik.errors.dob}
          touched={formik.touched.dob}
          placeholder={"D.O.B"}
        />
      </Col>
      <Col sm={6}>
        <SelectInput
          selectOptions={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          label={"Gender"}
          placeholder={"Dropdown"}
          setFieldValue={formik.setFieldValue}
          keyword={"gender"}
          value={formik.values.gender}
          error={formik.errors.gender}
          touched={formik.touched.gender}
        />
      </Col>

      {!formik.values.headOfFamily && (
        <Col sm={12}>
          <SelectInput
            selectOptions={relations}
            label={"Relation To Head of Family"}
            setFieldValue={formik.setFieldValue}
            keyword={"relationToHead"}
            value={formik.values.relationToHead}
            error={formik.errors.relationToHead}
            touched={formik.touched.relationToHead}
            disabled={formik.values.gender ? false : true}
          />
        </Col>
      )}
      <Col sm={12}>
        <SelectInput
          selectOptions={[
            { label: "A+", value: "A+" },
            { label: "A-", value: "A-" },
            { label: "B+", value: "B+" },
            { label: "B-", value: "B-" },
            { label: "AB+", value: "AB+" },
            { label: "AB-", value: "AB-" },
            { label: "O+", value: "O+" },
            { label: "O-", value: "O-" },
            { label: "N/A", value: "N/A" },
          ]}
          label={"Blood Group"}
          setFieldValue={formik.setFieldValue}
          keyword={"bloodGroup"}
          value={formik.values.bloodGroup}
          error={formik.errors.bloodGroup}
          touched={formik.touched.bloodGroup}
        />
      </Col>
      <Col sm={12}>
        <Form.Check
          type="checkbox"
          label="Willing to Donate Blood"
          onChange={(e) => {
            formik.setFieldValue("willingToDonate", e.target.checked);
          }}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Occupation"}
          placeholder={"e.g. Service"}
          setFieldValue={formik.setFieldValue}
          keyword={"occupation"}
          value={formik.values.occupation}
          error={formik.errors.occupation}
          touched={formik.touched.occupation}
        />
      </Col>
      <Col sm={12}>
        <TextArea
          label={"Remark"}
          placeholder={"Add any remark..."}
          id={"remark"}
          setFieldValue={formik.setFieldValue}
          keyword={"remark"}
          value={formik.values.remark}
          error={formik.errors.remark}
          touched={formik.touched.remark}
        />
      </Col>
    </Row>
  );
};

export default withAPIRequest(EditFamilyMemberFormStep1);
