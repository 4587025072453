import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ChooseHolyConfirmationTypeModal = ({
  show,
  setShow,
  setExtHolyConfirmationModalVisible,
  setHolyConfirmationModalVisible,
  basicDetails,
}) => {
  const { churchDetails } = useSelector((state) => state.church);
  return (
    <Modal
      className="bg-primary"
      show={show}
      onHide={() => setShow(false)}
      size="md"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Holy Confirmation Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="text-center fw-regular">
            Is{" "}
            <strong className="text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </strong>{" "}
            holy confirmation done in{" "}
            <strong className="text-primary">
              {" "}
              {churchDetails?.churchName}
            </strong>
            ?
          </h5>
          <h6 className="text-center">
            Note: Click "NO", if you don't have all Holy Confirmation Details.
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          variant="primary"
          onClick={() => {
            setHolyConfirmationModalVisible(true);
            setShow(false);
          }}
        >
          YES
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setExtHolyConfirmationModalVisible(true);
            setShow(false);
          }}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChooseHolyConfirmationTypeModal;
