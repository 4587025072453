import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = ({ children, placement, label }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{label}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
