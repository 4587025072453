import React, { useEffect, useState } from "react";
import { Button, Col, Offcanvas, ProgressBar, Row } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { ThreeCircles } from "react-loader-spinner";
import EditHolyCommunionDetailsModal from "../../modals/holy-communion/EditHolyCommunionDetailsModal";
import HolyCommunionCertificatePdf from "../../components/sacrament-certificates/HolyCommunionCertificatePdf";
import { useSelector } from "react-redux";
import { BlobProvider } from "@react-pdf/renderer";
import ExportPDFComponent from "../../components/sacrament-certificates/TrialPdf";

const HolyCommunionInfoOffset = ({
  show,
  setShow,
  commonAPIRequest,
  holyCommunionId,
  basicDetails,
  memberId,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentHolyCommunion, setCurrentHolyCommunion] = useState();
  const [editHolyCommunion, setEditHolyCommunion] = useState(false);
  const { churchDetails } = useSelector((state) => state?.church);

  const callGetHolyCommunionDetails = () => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${holyCommunionId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      callGetHolyCommunionDetails();
    }
  }, [show]);

  console.log("holy-communion-setails", currentHolyCommunion);

  return (
    <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          Holy Communion Details <br />
          <p className="fw-bold m-0 text-primary">
            {currentHolyCommunion?.referenceNum}
          </p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <hr className="hr m-0" />
      <Offcanvas.Body>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThreeCircles
              height="40"
              width="40"
              color="#303033"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <Row>
            <Col sm={12} className="mb-1 d-flex justify-content-end">
              <BlobProvider
                document={
                  <HolyCommunionCertificatePdf
                    details={currentHolyCommunion}
                    churchDetails={churchDetails}
                  />
                }
              >
                {({ blob, url, loading, error }) => {
                  // Handle loading state
                  if (loading) {
                    return <button disabled>Loading...</button>;
                  }
                  // Handle error state
                  if (error) {
                    return <p>Error generating PDF</p>;
                  }
                  // Function to open PDF in a new tab
                  const openPdf = () => {
                    const pdfWindow = window.open();
                    pdfWindow.location.href = url;
                  };
                  return (
                    <Button className="mx-3" onClick={openPdf}>
                      Generate PDF{" "}
                    </Button>
                  );
                }}
              </BlobProvider>

              <Button onClick={() => setEditHolyCommunion(true)}>Edit</Button>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>First Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentHolyCommunion?.firstName}
                </p>
              </div>
            </Col>
            {currentHolyCommunion?.middleName && (
              <Col sm={6}>
                <div className="my-2">
                  <p className="mb-0">
                    <strong>Middle Name</strong>
                  </p>
                  <p
                    style={{ fontSize: 18, fontWeight: "lighter" }}
                    className="mb-0"
                  >
                    {currentHolyCommunion?.middleName}
                  </p>
                </div>
              </Col>
            )}
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Last Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentHolyCommunion?.lastName}
                </p>
              </div>
            </Col>
            <ProgressBar now="100" className="h-1 my-2" />

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Holy Communion Date</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {new Date(
                    currentHolyCommunion?.holyCommunionDate
                  ).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Baptism Date</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {new Date(
                    currentHolyCommunion?.baptismDate
                  ).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Date Of Birth</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {new Date(currentHolyCommunion?.dob).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </p>
              </div>
            </Col>

            <ProgressBar now="100" className="h-1 my-2" />
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Father's Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentHolyCommunion?.fatherName}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Mothers's Name</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentHolyCommunion?.motherName}
                </p>
              </div>
            </Col>

            <ProgressBar now="100" className="h-1 my-2" />

            <Col sm={12}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Mobile Number</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentHolyCommunion?.mobileNum}
                </p>
              </div>
            </Col>

            <Col sm={12}>
              <div className="my-2">
                <p className="mb-0">
                  <strong>Address</strong>
                </p>
                <p
                  style={{ fontSize: 18, fontWeight: "lighter" }}
                  className="mb-0"
                >
                  {currentHolyCommunion?.address}
                </p>
              </div>
            </Col>
          </Row>
        )}
      </Offcanvas.Body>
      <EditHolyCommunionDetailsModal
        currentHolyCommunion={currentHolyCommunion}
        setShow={setEditHolyCommunion}
        show={editHolyCommunion}
        basicDetails={basicDetails}
        memberId={memberId}
        callGetHolyCommunionDetails={callGetHolyCommunionDetails}
      />
    </Offcanvas>
  );
};

export default withAPIRequest(HolyCommunionInfoOffset);
