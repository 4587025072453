import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Lottie from "lottie-react";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { useFormik } from "formik";
import ZoneFamilyTablePlaceholder from "../../components/placeholders/ZoneFamilyTablePlaceholder";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import MarriageListRow from "./marriage-component/MarriageListRow";
import MarriageCertificateFilterModal from "./marriage-component/MarriageCertificateFilterModal";
import { useDispatch, useSelector } from "react-redux";
import { MarriagesAction } from "../../store/slices/MarriagesSlice";
import AddOutsiderMarriageDetailsModal from "../../modals/marriage/AddOutsiderMarriageDetailsModal";
import CustomTooltip from "../../components/CustomTooltip";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import warningimg from "../../assets/img/warning.png";
import coupleImg from "../../assets/img/couple_delhi_diocese_theme.png";

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

const MarriageList = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);
  const [marriageFilterModalVisible, setMarriageFilterModalVisible] =
    useState(false);
  const [marriageOutsiderModal, setMarriageOutsiderModal] = useState(false);
  const dispatch = useDispatch();
  const { marriages, totalCount, present, pageNumber } = useSelector(
    (state) => state.marriages
  );

  const { churchDetails } = useSelector((state) => state?.church);
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    // validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchMarriageApi(fullNameArray[0], "")
        : callSearchMarriageApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  // api to call marriage cert api start

  const callGetAllMarriage = () => {
    let serviceParams = {
      api: `${API.MARRIAGE_LIST}?pgNum=${pageNumber}&pgSize=10`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // setMarriageList(result?.payload?.basicMarriageDetailList);
          dispatch(
            MarriagesAction.getMarriages(
              result?.payload?.basicMarriageDetailList
                ? result?.payload?.basicMarriageDetailList
                : []
            )
          );
          dispatch(MarriagesAction.getTotalCount(result?.payload.totalCount));
          setCurrentPageNumber(pageNumber);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to call marriage cert api end

  // api to call mariage cert api on date start

  const callGetAllMarriageByDate = (startDate, endDate) => {
    let serviceParams = {
      api: `${API.MARRIAGE_LIST}?pgNum=${pageNumber}&pgSize=10&startDate=${startDate}&endDate=${endDate}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // setMarriageList(result?.payload?.basicMarriageDetailList);
          dispatch(
            MarriagesAction.getMarriages(
              result?.payload?.basicMarriageDetailList
                ? result?.payload?.basicMarriageDetailList
                : []
            )
          );
          dispatch(MarriagesAction.getTotalCount(result?.payload.totalCount));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to call marriage cert api on date end

  // api to search marriage details by name start

  const callSearchMarriageApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${
        API.SEARCH_MARRIAGE_DETAILS
      }?firstName=${firstName.trim()}${`&lastName=${lastName.trim()}`}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // setMarriageList(result?.payload);
          dispatch(
            MarriagesAction.getMarriages(result?.payload ? result?.payload : [])
          );
          dispatch(MarriagesAction.getTotalCount(0));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search marriage details by name end

  useEffect(() => {
    if (!present) {
      callGetAllMarriage();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== pageNumber) {
      callGetAllMarriage();
    }
  }, [pageNumber]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 ">
        <div className="d-flex mb-3 justify-content-between align-items-center">
          <div>
            <h5 id="section3" className="main-subtitle m-0">
              Marriage Certificate Archive
            </h5>
            <p>
              {" "}
              Recent Marriages Details in{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                {churchDetails?.churchName}
              </span>
            </p>
          </div>

          <Button
            variant="light"
            onClick={() => {
              callGetAllMarriage();
            }}
            style={{ height: 40 }}
          >
            <i className="ri-refresh-line"></i>
          </Button>
        </div>
        {/* <p>
          Add <code>hover</code> props to enable a hover state on table rows
          within a tbody.
        </p> */}

        <div className="form-search py-2  ">
          <i className="ri-search-line"></i>
          <Form.Control
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            type="text"
            placeholder="e.g. Joseph George"
            value={formik.values.name}
          />
          <Button onClick={formik.handleSubmit} variant="primary">
            Search
          </Button>
        </div>
        <hr className="hr" />

        {/*  */}
        <div className="d-lg-none">
          <Row className="g-1 g-sm-2 g-xl-3 mb-5">
            <div className="main-label-group mb-3">
              <h3 className="fw-bold text-primary">Results</h3>
              <div>
                <Button
                  onClick={() => {
                    setMarriageFilterModalVisible(true);
                  }}
                  variant="outline-primary"
                >
                  Filter
                </Button>
              </div>
            </div>
            {marriages.map((marriage) => (
              <Col xs="6" sm="4" md="3" xl key={marriage?.marriageId}>
                <Card className="card-file">
                  <div
                    onClick={() => {
                      Swal.fire({
                        title: "Warning!",
                        text: "Certificates pdf's can only be generated / edited on the desktop.",
                        imageUrl: require("../../assets/img/on-desktop.png"),
                        imageWidth: 300,
                        imageHeight: 250,
                        imageAlt: "Custom image",
                      });
                    }}
                    className={"card-file-icon primary"}
                  >
                    {!marriage?.groomMemberId && (
                      <Badge
                        style={{ position: "absolute", top: 18, right: 18 }}
                        className="ms-2 fw-bold"
                        bg="secondary"
                        pill
                      >
                        Non Member
                      </Badge>
                    )}

                    <div style={{ position: "absolute", top: 64, right: 18 }}>
                      {marriage?.active ? (
                        <Badge className="ms-2 fw-bold" bg="success" pill>
                          Married
                        </Badge>
                      ) : (
                        <Badge className="ms-2 fw-bold" bg="danger" pill>
                          Annulled
                        </Badge>
                      )}
                    </div>

                    <i className="ri-file-pdf-line"></i>
                  </div>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <img
                        style={{ height: 48, width: 48 }}
                        src={coupleImg}
                        className="img-fluid"
                        alt="..."
                      />

                      <h6 className="ms-2 mt-2 fw-bold">
                        -{" "}
                        {marriage.groomMemberId ? (
                          <Link
                            to={"/admin/member-details"}
                            state={{
                              memberId: marriage.groomMemberId,
                              back: true,
                            }}
                            className="link-02 fw-bold text-primary"
                          >
                            {marriage?.groomMiddleName
                              ? `${marriage?.groomFirstName} ${marriage?.groomMiddleName} ${marriage?.groomLastName}`
                              : `${marriage?.groomFirstName} ${marriage?.groomLastName}`}
                          </Link>
                        ) : marriage.middleName ? (
                          `${marriage.groomFirstName} ${marriage.groomMiddleName} ${marriage.groomLastName}`
                        ) : (
                          `${marriage.groomFirstName} ${marriage.groomLastName}`
                        )}
                        <br />-{" "}
                        {marriage.brideMemberId ? (
                          <Link
                            to={"/admin/member-details"}
                            state={{
                              memberId: marriage.brideMemberId,
                              back: true,
                            }}
                            className="link-02 fw-bold text-primary"
                          >
                            {marriage?.brideMiddleName
                              ? `${marriage?.brideFirstName} ${marriage?.brideMiddleName} ${marriage?.brideLastName}`
                              : `${marriage?.brideFirstName} ${marriage?.brideLastName}`}
                          </Link>
                        ) : marriage?.brideMiddleName ? (
                          `${marriage?.brideFirstName} ${marriage?.brideMiddleName} ${marriage?.brideLastName}`
                        ) : (
                          `${marriage?.brideFirstName} ${marriage?.brideLastName}`
                        )}
                      </h6>
                    </div>

                    {/* <span>{file.size}</span> */}
                  </Card.Body>
                  <hr className="my-1" />
                  <Card.Footer className=" pt-2 ps-2 bg-light">
                    <span>Married On</span>
                    <div className="d-flex">
                      <span className="fw-bold text-primary">
                        {new Date(marriage?.marriageDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </span>
                      {!marriage?.marriageDateAccurate && (
                        <CustomTooltip
                          label={"Date Might be Incorrect"}
                          placement={"top"}
                        >
                          <Badge className="ms-2 fw-bold p-0" bg="light">
                            <img height={18} src={warningimg} alt="warning" />
                          </Badge>
                        </CustomTooltip>
                      )}
                    </div>
                    <hr className="my-1" />
                    <span>Reference Number: </span>
                    <p className="text-primary fw-bold m-0">
                      {marriage?.referenceNum}
                    </p>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
            <pre>
              {Math.ceil(totalCount / 10) > 1 && (
                <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                  <Pagination.Item
                    disabled={pageNumber === 1}
                    onClick={() => dispatch(MarriagesAction.setPageNumber(1))}
                  >
                    First
                  </Pagination.Item>

                  {getPaginationRange(
                    Math.ceil(totalCount / 10),
                    pageNumber,
                    3
                  ).map((n, i) => {
                    return (
                      <Pagination.Item
                        key={i}
                        onClick={() => {
                          dispatch(MarriagesAction.setPageNumber(n));
                        }}
                        active={pageNumber === n}
                      >
                        {n}
                      </Pagination.Item>
                    );
                  })}

                  <Pagination.Item
                    onClick={() => {
                      dispatch(
                        MarriagesAction.setPageNumber(
                          Math.ceil(totalCount / 10)
                        )
                      );
                    }}
                  >
                    Last
                  </Pagination.Item>
                </Pagination>
              )}
            </pre>
            {!loading && marriages.length === 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h3
                  style={{ backgroundColor: "#fff" }}
                  className="text-center py-3 fw-bold"
                >
                  No Holy Communion's Found
                </h3>
              </div>
            )}
          </Row>
        </div>
        {/*  */}

        <Card.Footer className="d-none d-lg-inline">
          <pre>
            <div className="main-label-group mb-3">
              <label>Results</label>
              <div>
                <Button
                  onClick={() => {
                    setMarriageFilterModalVisible(true);
                  }}
                  className="mx-3"
                  variant="outline-primary"
                >
                  Filter
                </Button>
                {/* <Button
                  className="mx-3"
                  onClick={() => {
                    callGetAllMarriage();
                    formik.resetForm();
                  }}
                  variant="outline-primary"
                >
                  Reset
                </Button> */}
                <Button
                  onClick={() => {
                    setMarriageOutsiderModal(true);
                  }}
                  variant="primary"
                >
                  Add
                </Button>
              </div>
            </div>
            <Card className="card-marriage">
              <Card.Body>
                <Table hover className="mb-0">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Groom's Name</th>
                      <th scope="col">Bride's Name</th>
                      <th scope="col">Marriage Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Ref. Num</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {loading && <ZoneFamilyTablePlaceholder />}
                  {!loading && (
                    <tbody>
                      {marriages?.map((marriage, i) => (
                        <MarriageListRow
                          marriage={marriage}
                          i={(pageNumber - 1) * 10 + i}
                          key={i}
                          callGetAllMarriage={callGetAllMarriage}
                        />
                      ))}
                    </tbody>
                  )}
                </Table>

                <pre>
                  {Math.ceil(totalCount / 10) > 1 && (
                    <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                      <Pagination.Item
                        disabled={pageNumber === 1}
                        onClick={() =>
                          dispatch(MarriagesAction.setPageNumber(1))
                        }
                      >
                        First
                      </Pagination.Item>

                      {getPaginationRange(
                        Math.ceil(totalCount / 10),
                        pageNumber,
                        3
                      ).map((n, i) => {
                        return (
                          <Pagination.Item
                            key={i}
                            onClick={() => {
                              dispatch(MarriagesAction.setPageNumber(n));
                            }}
                            active={pageNumber === n}
                          >
                            {n}
                          </Pagination.Item>
                        );
                      })}

                      <Pagination.Item
                        onClick={() => {
                          dispatch(
                            MarriagesAction.setPageNumber(
                              Math.ceil(totalCount / 10)
                            )
                          );
                        }}
                      >
                        Last
                      </Pagination.Item>
                    </Pagination>
                  )}
                </pre>

                {!loading && marriages?.length === 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3
                      style={{ backgroundColor: "#fff" }}
                      className="text-center p-3 fw-bold"
                    >
                      No Marriage's Found
                    </h3>
                  </div>
                )}
              </Card.Body>
            </Card>
          </pre>
        </Card.Footer>
      </div>
      <MarriageCertificateFilterModal
        show={marriageFilterModalVisible}
        setShow={setMarriageFilterModalVisible}
        callGetAllMarriageByDate={callGetAllMarriageByDate}
      />
      <AddOutsiderMarriageDetailsModal
        show={marriageOutsiderModal}
        setShow={setMarriageOutsiderModal}
        callGetAllMarriage={callGetAllMarriage}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(MarriageList);
