import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Form, Button, Badge } from "react-bootstrap";
import Avatar from "../components/Avatar";

import img10 from "../assets/img/img10.jpg";
import maleImg from "../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../assets/img/women_delhi_diocese_theme.png";
import Header from "../layouts/Header";
import { AXIOS_METHOD_TYPES, API } from "../constants/api";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { useDispatch, useSelector } from "react-redux";
import { SearchAction } from "../store/slices/SearchSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import ZonePlaceholder from "../components/placeholders/ZonePlaceholder";
import Lottie from "lottie-react";
import noSearchAnimation from "../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { FamilyAction } from "../store/slices/FamilySlice";
import BloodGroupDonationModal from "../modals/members/BloodGroupDonationModal";

const alphabetsOnly = /^[a-zA-Z. ,]*$/;

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed.")
    .min(3),
});

const SearchMembers = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);
  const { members } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const { churchDetails } = useSelector((state) => state?.church);
  const [bloodGroupModal, setBloodGroupModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchUsersApi(fullNameArray[0], "")
        : callSearchUsersApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  const callSearchUsersApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${API.SEARCH_MEMBER}?firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }&filterBy=All`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            SearchAction.getMembers(
              result.payload.searchSuggestions.length !== 0
                ? result.payload.searchSuggestions
                : []
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(FamilyAction.clearFamilies());
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h2 className="main-title mb-1">Search Members</h2>
            <p className="text-secondary mb-4">
              A Global Search for all the Parishioner in{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                {churchDetails?.churchName}
              </span>
            </p>
          </div>

          <Button
            onClick={() => setBloodGroupModal(true)}
            style={{ maxHeight: 40 }}
          >
            Blood Group Filter
          </Button>
        </div>

        <Row className="g-5">
          <Col xl>
            <div className="form-search py-2  ">
              <i className="ri-search-line"></i>
              <Form.Control
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                type="text"
                placeholder="e.g. Joseph George"
              />
              <Button onClick={formik.handleSubmit} variant="primary">
                Search
              </Button>
            </div>
            <div className="mb-5">
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger">*{formik.errors.name}</p>
              )}
            </div>
          </Col>
        </Row>
        {
          <Row className="g-5">
            <Col xl>
              {/* <h2 className="main-title">Search All Member's</h2> */}
              <div className="main-label-group mb-3">
                <label>Results</label>
                <Button
                  onClick={() => {
                    dispatch(SearchAction.getMembers([]));
                  }}
                  variant="outline-primary"
                >
                  Reset
                </Button>
              </div>
              <Row className="g-2 g-xxl-3 mb-5">
                {loading && <ZonePlaceholder />}
                {members.map((member, index) => (
                  <Col lg={4} key={index}>
                    {
                      <Card className="card-people shadow-smmd">
                        <Card.Body>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="mb-4"
                          >
                            <Avatar
                              // initial={`${member?.firstName
                              //   .charAt(0)
                              //   .toUpperCase()} ${member?.lastName
                              //   .charAt(0)
                              //   .toUpperCase()}`}
                              img={
                                member.gender === "Female" ? femaleImg : maleImg
                              }
                              size="xl"
                            />
                            <div className="ms-3">
                              <h6 className="mt-3">
                                <Link
                                  to={"/admin/member-details"}
                                  state={{
                                    memberId: member.memberId,
                                    back: true,
                                  }}
                                >
                                  {member.middleName
                                    ? `${member.firstName} ${member.middleName} ${member.lastName}`
                                    : `${member.firstName} ${member.lastName}`}
                                </Link>
                                {member?.active ? (
                                  <Badge
                                    className="ms-1 fw-bold"
                                    bg="success"
                                    pill
                                  >
                                    Active
                                  </Badge>
                                ) : (
                                  <Badge
                                    className="ms-1 fw-bold"
                                    bg="danger"
                                    pill
                                  >
                                    Inactive
                                  </Badge>
                                )}
                              </h6>
                              <p className="m-0 fw-bold text-primary">
                                {member.churchFamilyId}
                              </p>
                              <p className="m-0">
                                {member?.emailAddress
                                  ? member?.emailAddress
                                  : "-"}
                              </p>
                              {member?.familyHead ? (
                                <Badge pill bg="primary">
                                  Head Of Family
                                </Badge>
                              ) : (
                                <Badge pill bg="warning">
                                  Member
                                </Badge>
                              )}
                            </div>
                          </div>

                          <div className="d-grid">
                            <Link
                              className=" btn btn-primary align-items-center justify-content-center d-flex"
                              onClick={() => {
                                dispatch(
                                  FamilyAction.setFamilyId(member?.familyId)
                                );
                                localStorage.setItem(
                                  "currentFamilyId",
                                  member?.familyId
                                );
                                localStorage.setItem(
                                  "currentChurchFamilyId",
                                  member?.churchFamilyId
                                );
                              }}
                              state={{
                                familyId: member?.familyId,
                              }}
                              to={"/admin/family-details"}
                            >
                              <i
                                style={{ fontSize: 18 }}
                                className="ri-team-line me-2 align-self-center"
                              ></i>
                              Family Details
                            </Link>
                          </div>
                        </Card.Body>
                      </Card>
                    }
                  </Col>
                ))}
                {!loading && members.length === 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3 className="text-center pt-3 fw-bold">
                      No Parishioner Found
                    </h3>
                    <h5 className="text-center fw-400">
                      Please Try With Another Name.
                    </h5>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        }

        {/* <Footer /> */}
      </div>
      <BloodGroupDonationModal
        setShow={setBloodGroupModal}
        show={bloodGroupModal}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(SearchMembers);
