import { createSlice } from "@reduxjs/toolkit";

const ZonesSlice = createSlice({
  name: "zones",
  initialState: {
    present: false,
    zones: [],
    zoneFamilies: [],
    zoneFamililiesTotalCount: 0,
    zoneFamiliesPageNumber: 1,
  },
  reducers: {
    getZones(state, action) {
      state.zones = action.payload;
      state.present = true;
    },
    setZonesFamilies(state, action) {
      state.zoneFamilies = action.payload;
    },
    setZoneFamiliesTotalCount(state, action) {
      state.zoneFamililiesTotalCount = action.payload;
    },
    setZoneFamiliesPageNumber(state, action) {
      state.zoneFamiliesPageNumber = action.payload;
    },
  },
});

export const ZonesAction = ZonesSlice.actions;
export const ZonesReducer = ZonesSlice.reducer;
