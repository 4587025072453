import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Lottie from "lottie-react";
import commingSoonAnimation from "./../assets/lottie/animation_lna9lsvn.json";
import { API, AXIOS_METHOD_TYPES } from "../constants/api";
import { withAPIRequest } from "../HOC/withAPIRequest";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import img6 from "../assets/img/img1.jpg";
import { useNavigate } from "react-router-dom";
import AddParishModal from "../modals/parishes/AddParishModal";
import EditParishModal from "../modals/parishes/EditParishModal";
import ActiveStatusParishModal from "../modals/parishes/ActiveStatusParishModal";
import UpdateParishImagesModal from "../modals/parishes/UpdateParishImagesModal";
import loadingAnimation from "../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../assets/lottie/not_found_lottie_delhi_diocese_theme.json";

const DioceseParishDirectory = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [parishes, setParishes] = useState([]);
  const [showAddParishModal, setShowAddParishModal] = useState(false);
  const [showEditParishModal, setShowEditParishModal] = useState(false);
  const [showEditStatusModal, setShowEditStatusModal] = useState(false);
  const [showEditImageModal, setShowEditImageModal] = useState(false);
  const [currentChurchId, setCurrentChurchId] = useState();
  const [currentStatus, setCurrentStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const callGetAllChurches = () => {
    let serviceParams = {
      api: `${API.GET_ALL_CHURCH_RESOURCE}?pgNum=1&pgSize=100`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setParishes(result?.payload?.churchList);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    callGetAllChurches();
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4 ">
        {loading && (
          <div
            className=" d-flex align-items-center justify-content-center "
            style={{ height: "90vh" }}
          >
            <Lottie
              style={{ width: 150, height: 150 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span style={{ marginLeft: -40 }}>Loading Parishes...</span>
          </div>
        )}
        {!loading && (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="text-primary fw-bold m-0">
                  All Registered Parishes In Delhi Diocese
                </h4>
                <p className="text-dark m-0">
                  Listed below are all registered parishes.
                </p>
              </div>
              <Button
                onClick={() => {
                  setShowAddParishModal(true);
                }}
              >
                Add Parish
              </Button>
            </div>
            <hr className="hr" />
          </div>
        )}

        {parishes.length === 0 && (
          <div className="my-5 py-5">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 180, height: 180 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
            <h3 className="text-center mt-3 fw-bold">No Parishes Found</h3>
          </div>
        )}

        {parishes.length !== 0 && !loading && (
          <>
            <Row>
              {parishes?.map((parish) => (
                <Col
                  key={parish?.registeredChurchId}
                  className="my-4"
                  md={6}
                  sm={12}
                  lg={4}
                >
                  <Card>
                    <Card.Img
                      style={{ height: 400 }}
                      src={parish.imageUrl ? parish.imageUrl : img6}
                      variant="top"
                    />

                    {/* <ImageUploadInput
                  onImageSelected={(base64, file) =>
                    callUpdateChurchImageApi(
                      parish?.churchId,
                      base64,
                      file.type
                    )
                  }
                /> */}
                    <Card.Body>
                      <Card.Title className="text-dark">
                        {parish?.churchName ? parish?.churchName : "-"}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {parish?.address?.addressLine1
                          ? parish?.address?.addressLine1
                          : "-"}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2 text-primary fs-18 fw-bold">
                        Parish Priest:{" "}
                        {parish?.fatherName ? parish?.fatherName : "-"}
                      </Card.Subtitle>
                      <Card.Text className="text-dark text-truncate">
                        {parish?.description ? parish?.description : "-"}
                      </Card.Text>
                      <Button
                        className="mx-1"
                        onClick={() => {
                          navigate("/diocese/parish-details", {
                            state: {
                              churchId: parish?.churchId,
                              churchName: parish?.churchName,
                              churchAddress: parish?.address?.addressLine1,
                            },
                          });
                        }}
                      >
                        <i className="ri-info-i"></i> Details
                      </Button>

                      <Button
                        variant="secondary"
                        className="mx-1"
                        onClick={() => {
                          setCurrentChurchId(parish?.churchId);
                          setShowEditParishModal(true);
                        }}
                      >
                        <i className="ri-edit-line"></i>
                      </Button>

                      <Button
                        style={{ position: "absolute", top: 10, right: 60 }}
                        variant="light"
                        className="mx-1 align-items-center"
                        onClick={() => {
                          setCurrentChurchId(parish?.churchId);
                          setShowEditStatusModal(true);
                          setCurrentStatus(parish?.isActive);
                        }}
                      >
                        <i className="ri-stop-circle-line"></i>
                      </Button>

                      <Button
                        style={{ position: "absolute", top: 10, right: 10 }}
                        variant="light"
                        className="mx-1"
                        onClick={() => {
                          setCurrentChurchId(parish?.churchId);
                          setShowEditImageModal(true);
                        }}
                      >
                        <i className="ri-image-line"></i>
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <EditParishModal
              setShow={setShowEditParishModal}
              show={showEditParishModal}
              churchId={currentChurchId}
              setCurrentChurchId={setCurrentChurchId}
              callGetAllChurches={callGetAllChurches}
            />

            <ActiveStatusParishModal
              show={showEditStatusModal}
              setShow={setShowEditStatusModal}
              churchId={currentChurchId}
              status={currentStatus}
              callGetAllChurches={callGetAllChurches}
            />

            <UpdateParishImagesModal
              show={showEditImageModal}
              setShow={setShowEditImageModal}
              churchId={currentChurchId}
              callGetAllChurches={callGetAllChurches}
            />
          </>
        )}
      </div>

      <AddParishModal
        setShow={setShowAddParishModal}
        show={showAddParishModal}
        callGetAllChurches={callGetAllChurches}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(DioceseParishDirectory);
