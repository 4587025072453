import React, { useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GenerateHolyCommunionCertificateModal from "../../../modals/holy-communion/GenerateHolyCommunionCertificateModal";
import HolyCommunionLinkCertificateModal from "../../../modals/holy-communion/HolyCommunionLinkCertificateModal";
import EditOutsiderHolyCommunionDetailsModal from "../../../modals/holy-communion/EditOutsiderHolyCommunionDetailsModal";
import warningimg from "../../../assets/img/warning.png";
import CustomTooltip from "../../../components/CustomTooltip";
import Swal from "sweetalert2";

const HolyCommunionListRow = ({
  communion,
  i,
  commonAPIRequest,
  callGetAllHolyCommunion,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentHolyCommunion, setCurrentHolyCommunion] = useState();
  const [generateHolyCommunionModal, setGenerateHolyCommunionModal] =
    useState(false);
  const { churchDetails } = useSelector((state) => state?.church);
  const [loading2, setLoading2] = useState(false);
  const [holyCommunionCertModal, setHolyCommunionCertModal] = useState(false);

  const [editHolyCommunionOursiderModal, setEditHolyCommunionOursiderModal] =
    useState(false);

  // call generate pdf certificate api for holy communion start

  // const callGetHolyCommunionCertificateApi = (id) => {
  //   let serviceParams = {
  //     api: `${API.GENERATE_HOLY_COMMUNION_CERTIFICATE}?holyCommunionId=${id}`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     data: {},
  //   };
  //   setLoading(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading(false);
  //       try {
  //         setCertPdf(result?.payload);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  // call generate pdf certificate api for holy communion end

  const callGetHolyCommunionDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setHolyCommunionCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetHolyCommunionDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setGenerateHolyCommunionModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetHolyCommunionDetails = (id) => {
    let serviceParams = {
      api: `${API.HOLY_COMMUNION_DETAILS}?holyCommunionId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentHolyCommunion(result?.payload);
          setEditHolyCommunionOursiderModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <tr key={communion?.holyCommunionId}>
      <th scope="row">{i + 1}</th>
      <td className="fw-bold">
        {communion.memberId ? (
          <Link
            to={"/admin/member-details"}
            state={{
              memberId: communion.memberId,
              back: true,
            }}
          >
            {communion.middleName
              ? `${communion.firstName} ${communion.middleName} ${communion.lastName}`
              : `${communion.firstName} ${communion.lastName}`}
          </Link>
        ) : communion.middleName ? (
          `${communion.firstName} ${communion.middleName} ${communion.lastName}`
        ) : (
          `${communion.firstName} ${communion.lastName}`
        )}
        {!communion?.memberId && (
          <Badge className="ms-2" bg="secondary" pill>
            Non Member
          </Badge>
        )}
      </td>
      <td
        style={{ height: 55 }}
        className="d-flex align-items-center justify-content-start"
      >
        <span style={{ width: 90 }}>
          {new Date(communion?.holyCommunionDate).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>

        {!communion?.holyCommunionDateAccurate && (
          <CustomTooltip label={"Might be Incorrect"} placement={"top"}>
            <Badge style={{ padding: 5 }} className="ms-1 fw-bold" bg="light">
              <img height={18} src={warningimg} alt="warning" />
            </Badge>
          </CustomTooltip>
        )}
      </td>
      <td>{communion?.referenceNum}</td>
      <td>
        <Button
          disabled={communion?.extHolyCommunion}
          variant="primary"
          onClick={() => {
            callGetHolyCommunionDetailsForLink(communion?.holyCommunionId);
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-download-2-fill"></i>
          )}
        </Button>

        <Button
          className="ms-2"
          disabled={communion?.extHolyCommunion}
          variant="warning"
          onClick={() => {
            if (communion?.memberId) {
              Swal.fire(
                "Update From Profile",
                `Please update Holy Communion Details from the user's profile. As he/she is the member of the parish.`,
                "warning"
              );
            } else {
              callGetHolyCommunionDetails(communion?.holyCommunionId);
            }
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-edit-2-fill"></i>
          )}
        </Button>
        {/* <Button
          variant="primary"
          className="ms-2"
          onClick={() => {
            callGetHolyCommunionDetailsForPreview(communion?.holyCommunionId);
          }}
        >
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <i className="ri-eye-line"></i>
          )}
        </Button> */}
        {/*  */}
      </td>
      <GenerateHolyCommunionCertificateModal
        setShow={setGenerateHolyCommunionModal}
        show={generateHolyCommunionModal}
        holyCommunionId={communion?.holyCommunionId}
        churchDetails={churchDetails}
        currentHolyCommunion={currentHolyCommunion}
      />
      <HolyCommunionLinkCertificateModal
        show={holyCommunionCertModal}
        setShow={setHolyCommunionCertModal}
        holyCommunionId={communion?.holyCommunionId}
        churchDetails={churchDetails}
        currentHolyCommunion={currentHolyCommunion}
      />
      <EditOutsiderHolyCommunionDetailsModal
        show={editHolyCommunionOursiderModal}
        setShow={setEditHolyCommunionOursiderModal}
        holyCommunionId={communion?.holyCommunionId}
        currentHolyCommunion={currentHolyCommunion}
        callGetAllHolyCommunion={callGetAllHolyCommunion}
      />
    </tr>
  );
};

export default withAPIRequest(HolyCommunionListRow);
