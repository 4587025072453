import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Accordion, Button, Card, Pagination, Table } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import SelectInput from "../../components/form/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import AddDonationModal from "../../modals/donation/AddDonationModal";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { useFormik } from "formik";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { FinancialsAction } from "../../store/slices/FinancialsSlice";
import CustomPopover from "../../components/CustomPopover";
import DonationExcelReport from "../../components/excel-reports/DonationExcelReport";
import DonationLinkReceiptModal from "../../modals/csf/DonationLinkReceiptModal";

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

function sortByMonthWithZeroesFor12Months(arr) {
  // Create a new array with the sorted values
  const sortedArray = arr.slice().sort((a, b) => a.month - b.month);

  const result = [];

  for (let monthIndex = 1; monthIndex <= 12; monthIndex++) {
    const matchingObj = sortedArray.find((obj) => obj.month === monthIndex);
    result.push(matchingObj ? matchingObj.totalDonation : 0);
  }

  return result;
}

const monthArray = [
  { label: "JAN", value: 1 },
  { label: "FEB", value: 2 },
  { label: "MAR", value: 3 },
  { label: "APR", value: 4 },
  { label: "MAY", value: 5 },
  { label: "JUN", value: 6 },
  { label: "JUL", value: 7 },
  { label: "AUG", value: 8 },
  { label: "SEP", value: 9 },
  { label: "OCT", value: 10 },
  { label: "NOV", value: 11 },
  { label: "DEC", value: 12 },
];

//  ---------------------- DATA ---------------------

const optionLine = {
  chart: {
    toolbar: {
      show: true,
    },

    zoom: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 4,
  },
  title: {
    text: "Contribution's by Month",
    align: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"],
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  colors: ["#912e2d"],
  tooltip: {
    y: {
      formatter: function (val) {
        return "₹ " + val + "";
      },
    },
  },
};

//  --------------------- DATA ---------------------

const DonationsList = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { churchDetails } = useSelector((state) => state?.church);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [donationReceiptModal, setDonationReceiptModal] = useState(false);
  const [currentDonationDetails, setCurrentDonationDetails] = useState();
  const dispatch = useDispatch();

  const {
    donationList,
    donationTotalCount,
    donationListPresent,
    donationPageNumber,
    donationMonth,
    donationGraphData,
    donationGraphDataPresent,
  } = useSelector((state) => state.financial);

  const [currentPageNumber, setCurrentPageNumber] =
    useState(donationPageNumber);

  const formik = useFormik({
    initialValues: {
      month: donationMonth,
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  // call api for get all donations

  const getAllDonationsApi = () => {
    let serviceParams = {
      api: `${
        API.DONATION_SUMMARY
      }?pgNum=${donationPageNumber}&pgSize=10&month=${
        formik.values.month
      }&year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // setDonations(result?.payload?.donationDetailList);
          dispatch(
            FinancialsAction.getDonationList(
              result?.payload?.donationDetailList
                ? result?.payload?.donationDetailList
                : []
            )
          );
          dispatch(
            FinancialsAction.getDonationTotalCount(result?.payload.totalCount)
          );
          setCurrentPageNumber(donationPageNumber);
          dispatch(FinancialsAction.setDonationMonth(formik.values?.month));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call api for get all donations

  // call api for get all donations graph data

  const getAllDonationsGraphDataApi = () => {
    let serviceParams = {
      api: `${API.GET_DONATION_GRAPH_SUMMARY}?year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            FinancialsAction.setDonationGraphData(
              result?.payload?.donationDetailConsolidationList
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call api for get all donations graph data

  useEffect(() => {
    if (!donationGraphDataPresent) {
      getAllDonationsGraphDataApi();
    }
  }, []);

  useEffect(() => {
    if (!donationListPresent) {
      getAllDonationsApi();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== donationPageNumber) {
      getAllDonationsApi();
    }
  }, [donationPageNumber]);

  useEffect(() => {
    if (formik.values?.month !== donationMonth) {
      if (donationPageNumber !== 1) {
        dispatch(FinancialsAction.setDonationPageNumber(1));
      } else {
        getAllDonationsApi();
      }
    }
  }, [formik.values?.month]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h5 id="section3" className="main-subtitle m-0">
              All Contribution's
            </h5>
            <p>
              List of all the Contribution's of{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                {churchDetails?.churchName}
              </span>
              .
            </p>
          </div>
          <Button
            variant="light"
            onClick={() => {
              getAllDonationsGraphDataApi();
              getAllDonationsApi();
            }}
            style={{ height: 40 }}
          >
            <i className="ri-refresh-line"></i>
          </Button>
        </div>

        <Card className="card-donation">
          <Card.Body>
            <ReactApexChart
              series={[
                {
                  name: "Donations",
                  data: sortByMonthWithZeroesFor12Months(donationGraphData),
                },
              ]}
              options={optionLine}
              type="bar"
              height={350}
            />
          </Card.Body>
          <Card.Footer>
            <pre>
              <p>Contribution's Collected For Each Month</p>
            </pre>
          </Card.Footer>
        </Card>

        <Card className="card-donation">
          <Card.Body className="d-lg-none pt-0 ">
            <div
              className="mb-3 mt-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "50%" }}>
                <h4 style={{ fontSize: 16 }} className="fw-bold">
                  Contribution's for the month of{" "}
                  {
                    monthArray?.find(
                      (month) => month.value == formik.values.month
                    )?.label
                  }
                </h4>
              </div>
              <div
                style={{ width: "40%" }}
                className="d-flex justify-content-end"
              >
                <SelectInput
                  selectOptions={monthArray}
                  setFieldValue={formik.setFieldValue}
                  keyword={"month"}
                  value={formik.values.month}
                  error={formik.errors.month}
                  touched={formik.touched.month}
                />
                <Button
                  onClick={() => {
                    setShowDonationModal(true);
                  }}
                  variant="primary"
                  className="ms-2"
                >
                  <i className="ri-add-line"></i>
                </Button>
              </div>
            </div>

            <hr />

            <Accordion defaultActiveKey="0">
              {donationList?.map((donation, index) => {
                return (
                  <Accordion.Item
                    style={{
                      border: "1px solid #A05152",
                      // backgroundColor: "#F1F0F4",
                    }}
                    className="mb-2"
                    eventKey={index}
                  >
                    <Accordion.Header>
                      <div>
                        <h5 className="fw-bold mb-0">₹ {donation?.amount}</h5>
                        <p style={{ fontSize: 12 }} className="m-0">
                          {" "}
                          by {donation?.donatedBy}
                        </p>
                      </div>
                    </Accordion.Header>
                    <hr className="m-0" />
                    <Accordion.Body>
                      <p className="mb-2 mt-2">
                        <strong>Donated By:</strong>
                        <span className="text-primary">
                          {" "}
                          {donation?.donatedBy}
                        </span>
                      </p>
                      <p className="mb-2 mt-2">
                        <strong>Amount Donated:</strong>
                        <span className="text-primary">
                          {" "}
                          ₹ {donation?.amount}
                        </span>
                      </p>
                      <p className="mb-2 mt-2">
                        <strong>Payment Mode:</strong>
                        <span className="text-primary">
                          {" "}
                          {donation?.paymentMode}
                        </span>
                      </p>
                      <p className="mb-2 mt-2">
                        <strong>Donated On:</strong>
                        <span className="text-primary">
                          {" "}
                          {new Date(donation?.donationDate).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                        </span>
                      </p>
                      <p className=" mb-2 mt-2">
                        <strong>Txn. ID:</strong>
                        <span className="text-primary">
                          {" "}
                          {donation?.sysTransactionId}
                        </span>
                      </p>
                      <p className="mb-3 mt-2">
                        <strong>Purpose:</strong>
                        <span className="text-primary">
                          {" "}
                          {donation?.category}{" "}
                          {donation?.description &&
                            `(${donation?.description})`}
                        </span>
                      </p>

                      <p
                        style={{
                          fontSize: 11,
                          position: "absolute",
                          right: 25,
                          // top: 30,
                        }}
                        className="text-danger fw-bold"
                      >
                        *Receipt can only be generated on desktop.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>

            {donationList?.length === 0 && !loading && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h3 className="text-center fw-bold">No Contribution's Found</h3>
                <p className="text-center">
                  Please Add Contribution's Details.
                </p>
              </div>
            )}

            {loading && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 120, height: 120 }}
                    animationData={loadingAnimation}
                    loop={true}
                  />
                </div>

                <p className="text-center">Loading...</p>
              </div>
            )}

            {Math.ceil(donationTotalCount / 10) > 1 && (
              <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                <Pagination.Item
                  disabled={donationPageNumber === 1}
                  onClick={() => dispatch(FinancialsAction.setCsfPageNumber(1))}
                >
                  First
                </Pagination.Item>

                {getPaginationRange(
                  Math.ceil(donationTotalCount / 10),
                  donationPageNumber,
                  3
                ).map((n, i) => {
                  return (
                    <Pagination.Item
                      key={i}
                      onClick={() => {
                        dispatch(FinancialsAction.setCsfPageNumber(n));
                      }}
                      active={donationPageNumber === n}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}

                <Pagination.Item
                  onClick={() => {
                    dispatch(
                      FinancialsAction.setCsfPageNumber(
                        Math.ceil(donationTotalCount / 10)
                      )
                    );
                  }}
                >
                  Last
                </Pagination.Item>
              </Pagination>
            )}
          </Card.Body>

          <Card.Body className="d-none d-lg-inline">
            <div
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h4 className="fw-bold">
                  Contribution's for the month of{" "}
                  {
                    monthArray?.find(
                      (month) => month.value === formik.values.month
                    )?.label
                  }
                </h4>
              </div>
              <div className="d-flex">
                <SelectInput
                  selectOptions={monthArray}
                  setFieldValue={formik.setFieldValue}
                  keyword={"month"}
                  value={formik.values.month}
                  error={formik.errors.month}
                  touched={formik.touched.month}
                />
                {donationList?.length !== 0 && (
                  <DonationExcelReport
                    totalCount={donationTotalCount}
                    currentMonth={formik.values.month}
                  />
                )}

                <Button
                  onClick={() => {
                    setShowDonationModal(true);
                  }}
                  variant="primary"
                  className="ms-2"
                >
                  Add Donation
                </Button>
              </div>
            </div>

            <hr />

            {donationList?.length === 0 && !loading ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h3 className="text-center fw-bold">No Contribution's Found</h3>
                <p className="text-center">
                  Please Add Contribution's Details.
                </p>
              </div>
            ) : loading ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 120, height: 120 }}
                    animationData={loadingAnimation}
                    loop={true}
                  />
                </div>

                <p className="text-center">Loading...</p>
              </div>
            ) : (
              <Table hover className="mb-0">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Donated On</th>
                    <th scope="col">Mode</th>
                    <th scope="col">Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  {donationList?.map((donation, index) => {
                    return (
                      <tr key={donation?.donationId}>
                        <th scope="row">
                          {" "}
                          {(donationPageNumber - 1) * 10 + (index + 1)}
                        </th>
                        <td>{donation?.donatedBy}</td>
                        <td>
                          ₹ {donation?.amount}
                          <CustomPopover
                            content={
                              <p className="m-0 fs-16 text-primary">
                                <strong>{donation?.category}</strong>
                              </p>
                            }
                          >
                            <i className="ri-information-line text-primary fw-bold"></i>{" "}
                          </CustomPopover>
                        </td>
                        <td>
                          {new Date(donation?.donationDate).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                          <CustomPopover
                            content={
                              <p>
                                Added by <strong>{donation?.createdBy}</strong>{" "}
                                on{" "}
                                <strong>
                                  {new Date(
                                    donation?.createTmStmp
                                  ).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  })}
                                </strong>
                              </p>
                            }
                          >
                            <i className="ri-information-line text-primary fw-bold"></i>
                          </CustomPopover>
                        </td>
                        <td>{donation?.paymentMode}</td>
                        <td>
                          <Button
                            onClick={() => {
                              setCurrentDonationDetails(donation);
                              setDonationReceiptModal(true);
                            }}
                            variant="light"
                            className="align-items-center justify-content-center"
                          >
                            <i className="ri-file-list-2-line"></i> Receipt
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <pre>
              {Math.ceil(donationTotalCount / 10) > 1 && (
                <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                  <Pagination.Item
                    disabled={donationPageNumber === 1}
                    onClick={() =>
                      dispatch(FinancialsAction.setDoantionPageNumber(1))
                    }
                  >
                    First
                  </Pagination.Item>

                  {getPaginationRange(
                    Math.ceil(donationTotalCount / 10),
                    donationPageNumber,
                    3
                  ).map((n, i) => {
                    return (
                      <Pagination.Item
                        key={i}
                        onClick={() => {
                          dispatch(FinancialsAction.setDoantionPageNumber(n));
                        }}
                        active={donationPageNumber === n}
                      >
                        {i + 1}
                      </Pagination.Item>
                    );
                  })}

                  <Pagination.Item
                    onClick={() => {
                      dispatch(
                        FinancialsAction.setDoantionPageNumber(
                          Math.ceil(donationTotalCount / 10)
                        )
                      );
                    }}
                  >
                    Last
                  </Pagination.Item>
                </Pagination>
              )}
            </pre>
          </Card.Body>
        </Card>

        {/* <Footer /> */}
      </div>
      <AddDonationModal
        show={showDonationModal}
        setShow={setShowDonationModal}
        getAllDonationsApi={getAllDonationsApi}
        getAllDonationsGraphDataApi={getAllDonationsGraphDataApi}
        donations={
          churchDetails
            ? churchDetails?.configurations
                .find((config) => config.name === "DONATION_CONFIGURATIONS")
                .value?.donationCategories?.map((donation) => {
                  return {
                    label: donation,
                    value: donation,
                  };
                })
            : []
        }
      />
      <DonationLinkReceiptModal
        show={donationReceiptModal}
        setShow={setDonationReceiptModal}
        donationDetails={currentDonationDetails}
        churchDetails={churchDetails}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(DonationsList);
