import React from "react";
import { Col, Row } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";

const BaptismFormStep4 = ({ formik }) => {
  return (
    <Row className="g-3">
      <Col sm={12}>
        <DateInput
          label={"Date Of Baptism"}
          setFieldValue={formik.setFieldValue}
          keyword={"dateOfBaptismForValidation"}
          value={formik.values.dateOfBaptismForValidation}
          error={formik.errors.dateOfBaptismForValidation}
          touched={formik.touched.dateOfBaptismForValidation}
          disabled={true}
        />
      </Col>
      <Col sm={12}>
        <DateInput
          label={"Date Of Holy Communion"}
          setFieldValue={formik.setFieldValue}
          keyword={"dateOfholyCommunion"}
          value={formik.values.dateOfholyCommunion}
          error={formik.errors.dateOfholyCommunion}
          touched={formik.touched.dateOfholyCommunion}
        />
      </Col>

      <Col sm={12}>
        <DateInput
          label={"Date Of Holy Confirmation"}
          setFieldValue={formik.setFieldValue}
          keyword={"dateOfholyConfirmation"}
          value={formik.values.dateOfholyConfirmation}
          error={formik.errors.dateOfholyConfirmation}
          touched={formik.touched.dateOfholyConfirmation}
        />
      </Col>

      <Col sm={12}>
        <DateInput
          label={"Date Of Marriage"}
          setFieldValue={formik.setFieldValue}
          keyword={"dateOfMarriage"}
          value={formik.values.dateOfMarriage}
          error={formik.errors.dateOfMarriage}
          touched={formik.touched.dateOfMarriage}
        />
      </Col>
    </Row>
  );
};

export default BaptismFormStep4;
