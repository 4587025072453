import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { useSelector } from "react-redux";
import SelectInput from "../../components/form/SelectInput";
import TextArea from "../../components/form/TextArea";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { EditOutsidersBaptismValidationSchema } from "../../validations/baptism";

const EditBaptismDetailsModal = ({
  show,
  setShow,
  basicDetails,
  currentBaptism,
  commonAPIRequest,
  memberId,
  callGetBaptismDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const { churchDetails } = useSelector((state) => state.church);
  const [baptismName, setBaptismName] = useState(true);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      dob: "",
      gender: "",
      dateOfBaptism: "",
      rite: "",
      fatherNationality: "",
      fatherProfession: "",
      fatherDomicile: "",
      godFatherFirstName: "",
      godFatherLastName: "",
      godFatherDomicile: "",
      godMotherFirstName: "",
      godMotherLastName: "",
      godMotherDomicile: "",
      baptismPlace: "",
      ministerName: "",
      remark: "",
      dateOfholyCommunion: "",
      dateOfholyConfirmation: "",
      dateOfMarriage: "",
      baptismDateNotSure: false,
      registerRefNumber: null,
    },
    validationSchema: EditOutsidersBaptismValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Birth</dt>
          <dd>${new Date(formik.values?.dob).toLocaleDateString("en-GB", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}</dd>
          <dt>Date Of Baptism </dt>
          <dd>${new Date(formik.values?.dateOfBaptism).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callUpdatedBaptismDetailsApi();
        }
      });
    },
  });

  useEffect(() => {
    if (show) {
      formik.setFieldValue("firstName", currentBaptism?.firstName);
      formik.setFieldValue("middleName", currentBaptism?.middleName);
      formik.setFieldValue("lastName", currentBaptism?.lastName);
      formik.setFieldValue("fatherName", basicDetails?.fatherName);
      formik.setFieldValue("motherName", basicDetails?.motherName);
      formik.setFieldValue("gender", basicDetails?.gender);
      formik.setFieldValue("rite", currentBaptism?.rite);

      formik.setFieldValue("dob", new Date(basicDetails?.dateOfBirth));
      if (currentBaptism?.holyCommunionDate) {
        formik.setFieldValue(
          "dateOfholyCommunion",
          new Date(currentBaptism?.holyCommunionDate)
        );
      }

      if (currentBaptism?.confirmationDate) {
        formik.setFieldValue(
          "dateOfholyConfirmation",
          new Date(currentBaptism?.confirmationDate)
        );
      }

      if (currentBaptism?.marriageDate) {
        formik.setFieldValue(
          "dateOfMarriage",
          new Date(currentBaptism?.marriageDate)
        );
      }

      formik.setFieldValue(
        "dateOfBaptism",
        new Date(currentBaptism?.baptismDate)
      );

      formik.setFieldValue(
        "fatherNationality",
        currentBaptism?.fatherNationality
      );
      formik.setFieldValue(
        "fatherProfession",
        currentBaptism?.fatherProfession
      );
      formik.setFieldValue("fatherDomicile", currentBaptism?.fatherDomicile);
      formik.setFieldValue("godFatherFirstName", currentBaptism?.godFatherName);
      formik.setFieldValue(
        "godFatherLastName",
        currentBaptism?.godFatherSurName
      );
      formik.setFieldValue(
        "godFatherDomicile",
        currentBaptism?.godFatherDomicile
      );
      formik.setFieldValue("godMotherFirstName", currentBaptism?.godMotherName);
      formik.setFieldValue(
        "godMotherLastName",
        currentBaptism?.godMotherSurName
      );
      formik.setFieldValue(
        "godMotherDomicile",
        currentBaptism?.godMotherDomicile
      );
      formik.setFieldValue("baptismPlace", currentBaptism?.placeOfBaptism);

      formik.setFieldValue("ministerName", currentBaptism?.ministerName);

      formik.setFieldValue("remark", currentBaptism?.remarks);
      formik.setFieldValue(
        "baptismDateNotSure",
        !currentBaptism?.baptismDateAccurate
      );
      formik.setFieldValue(
        "registerRefNumber",
        currentBaptism?.regReferenceNum
      );
    }
  }, [show]);

  useEffect(() => {
    if (baptismName) {
      if (basicDetails?.baptismName) {
        formik.setFieldValue("firstName", basicDetails?.baptismName);
      }
    } else {
      formik.setFieldValue("firstName", basicDetails?.firstName);
    }
  }, [baptismName]);

  // function to call API for Update New Baptism start

  const callUpdatedBaptismDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_BAPTISM_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        baptismDetail: {
          baptismId: currentBaptism?.baptismId,
          firstName: formik.values?.firstName.trim(),
          middleName: formik.values?.middleName.trim(),
          lastName: formik.values?.lastName.trim(),
          baptismDate: new Date(formik.values.dateOfBaptism),
          dob: new Date(formik.values.dob),
          gender: formik.values?.gender,
          fatherName: formik.values?.fatherName.trim(),
          motherName: formik.values?.motherName.trim(),
          fatherNationality: formik.values?.fatherNationality.trim(),
          fatherDomicile: formik.values?.fatherDomicile.trim(),
          fatherProfession: formik.values?.fatherProfession.trim(),
          godFatherName: formik.values?.godFatherFirstName.trim(),
          godFatherSurName: formik.values?.godFatherLastName.trim(),
          godFatherDomicile: formik.values?.godFatherDomicile.trim(),
          godMotherName: formik.values?.godMotherFirstName.trim(),
          godMotherSurName: formik.values?.godMotherLastName.trim(),
          godMotherDomicile: formik.values?.godMotherDomicile.trim(),
          placeOfBaptism: formik.values?.baptismPlace.trim(),
          ministerName: formik.values?.ministerName.trim(),
          remarks: formik.values?.remark.trim(),
          rite: formik.values?.rite.trim(),
          baptismDateAccurate: !formik.values.baptismDateNotSure,
           regReferenceNum: formik.values?.registerRefNumber
            ? +formik.values?.registerRefNumber
            : null,
          holyCommunionDate: formik.values.dateOfholyCommunion
            ? new Date(formik.values.dateOfholyCommunion)
            : null,
          confirmationDate: formik.values.dateOfholyConfirmation
            ? new Date(formik.values.dateOfholyConfirmation)
            : null,
          marriageDate: formik.values.dateOfMarriage
            ? new Date(formik.values.dateOfMarriage)
            : null,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Baptism Details Updated Successfully.`,
            "success"
          );
          setShow(false);
          callGetBaptismDetails();
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Update New Baptism end

  useEffect(() => {
    if (basicDetails?.baptismName === currentBaptism?.firstName) {
      setBaptismName(true);
    } else {
      setBaptismName(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Baptism Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span
              style={{ marginLeft: -54 }}
              className="fs-18 text-primary fw-bold"
            >
              Updating...
            </span>
          </div>
        ) : (
          <Row>
            {/* <p className="text-muted">
              As {basicDetails?.firstName} {basicDetails?.mimddleName}{" "}
              {basicDetails?.lastName}'s baptism was done outside of{" "}
              {churchDetails?.churchName}. <br /> Please Enter these basic
              baptism details for the records.
            </p> */}
            <Col sm={4}>
              <TextInput
                label={"First Name"}
                placeholder={"Enter First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"firstName"}
                value={formik.values.firstName}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
                disabled={basicDetails?.firstName ? true : false}
              />
              {basicDetails?.baptismName && (
                <Form.Check
                  type="switch"
                  className="mt-1"
                  checked={baptismName}
                  label="Use Baptism Name"
                  onChange={(e) => {
                    setBaptismName(e.target.checked);
                  }}
                />
              )}
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Middle Name"}
                placeholder={"Enter Middle Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"middleName"}
                value={formik.values.middleName}
                error={formik.errors.middleName}
                touched={formik.touched.middleName}
                // disabled={basicDetails?.middleName ? true : false}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Last Name"}
                placeholder={"Enter Last Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"lastName"}
                value={formik.values.lastName}
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
                // disabled={basicDetails?.lastName ? true : false}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Father's Name"}
                placeholder={"Enter Father's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"fatherName"}
                value={formik.values.fatherName}
                error={formik.errors.fatherName}
                touched={formik.touched.fatherName}
                // disabled={basicDetails?.fatherName ? true : false}
                disabled
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Mother's Name"}
                placeholder={"Enter Mother's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"motherName"}
                value={formik.values.motherName}
                error={formik.errors.motherName}
                touched={formik.touched.motherName}
                // disabled={basicDetails?.motherName ? true : false}
                disabled
              />
            </Col>
            <Col sm={6}>
              <DateInput
                label={"Date Of Birth"}
                setFieldValue={formik.setFieldValue}
                keyword={"dob"}
                value={formik.values.dob}
                error={formik.errors.dob}
                touched={formik.touched.dob}
                disabled={basicDetails?.dateOfBirth ? true : false}
              />
            </Col>
            <Col sm={6}>
              <SelectInput
                label={"Gender"}
                selectOptions={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                setFieldValue={formik.setFieldValue}
                keyword={"gender"}
                value={formik.values.gender}
                error={formik.errors.gender}
                touched={formik.touched.gender}
                disabled={basicDetails?.gender ? true : false}
              />
            </Col>
            <Col sm={12}>
              <DateInput
                label={"Date Of Baptism"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfBaptism"}
                value={formik.values.dateOfBaptism}
                error={formik.errors.dateOfBaptism}
                touched={formik.touched.dateOfBaptism}
                // disabled={true}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if baptism date is correct"
                onChange={(e) => {
                  formik.setFieldValue("baptismDateNotSure", e.target.checked);
                }}
                checked={formik.values.baptismDateNotSure}
              />
            </Col>

            <Col sm={12}>
              <SelectInput
                label={"Rite"}
                selectOptions={[
                  { label: "Latin", value: "Latin" },
                  { label: "SyroMalabar", value: "SyroMalabar" },
                  { label: "SyroMalankara", value: "SyroMalankara" },
                ]}
                setFieldValue={formik.setFieldValue}
                keyword={"rite"}
                value={formik.values.rite}
                error={formik.errors.rite}
                touched={formik.touched.rite}
              />
            </Col>

            <Col sm={4}>
              <TextInput
                label={"Father's Nationality"}
                setFieldValue={formik.setFieldValue}
                keyword={"fatherNationality"}
                value={formik.values.fatherNationality}
                error={formik.errors.fatherNationality}
                touched={formik.touched.fatherNationality}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Father's Profession"}
                setFieldValue={formik.setFieldValue}
                keyword={"fatherProfession"}
                value={formik.values.fatherProfession}
                error={formik.errors.fatherProfession}
                touched={formik.touched.fatherProfession}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Father's Domicile"}
                setFieldValue={formik.setFieldValue}
                keyword={"fatherDomicile"}
                value={formik.values.fatherDomicile}
                error={formik.errors.fatherDomicile}
                touched={formik.touched.fatherDomicile}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"God Father's First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godFatherFirstName"}
                value={formik.values.godFatherFirstName}
                error={formik.errors.godFatherFirstName}
                touched={formik.touched.godFatherFirstName}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"God Father's Last Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godFatherLastName"}
                value={formik.values.godFatherLastName}
                error={formik.errors.godFatherLastName}
                touched={formik.touched.godFatherLastName}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"God Father's Domicile"}
                setFieldValue={formik.setFieldValue}
                keyword={"godFatherDomicile"}
                value={formik.values.godFatherDomicile}
                error={formik.errors.godFatherDomicile}
                touched={formik.touched.godFatherDomicile}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"God Mother's First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godMotherFirstName"}
                value={formik.values.godMotherFirstName}
                error={formik.errors.godMotherFirstName}
                touched={formik.touched.godMotherFirstName}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"God Mother's Last Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"godMotherLastName"}
                value={formik.values.godMotherLastName}
                error={formik.errors.godMotherLastName}
                touched={formik.touched.godMotherLastName}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"God Mother's Domicile"}
                setFieldValue={formik.setFieldValue}
                keyword={"godMotherDomicile"}
                value={formik.values.godMotherDomicile}
                error={formik.errors.godMotherDomicile}
                touched={formik.touched.godMotherDomicile}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Baptism Place"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismPlace"}
                value={formik.values.baptismPlace}
                error={formik.errors.baptismPlace}
                touched={formik.touched.baptismPlace}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Minister's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"ministerName"}
                value={formik.values.ministerName}
                error={formik.errors.ministerName}
                touched={formik.touched.ministerName}
              />
            </Col>
            <Col sm={4}>
              <TextInput
                label={"Register Reference Number"}
                setFieldValue={formik.setFieldValue}
                keyword={"registerRefNumber"}
                value={formik.values.registerRefNumber}
                error={formik.errors.registerRefNumber}
                touched={formik.touched.registerRefNumber}
                type="number"
              />
            </Col>
            <Col sm={12}>
              <TextArea
                label={"Remark"}
                setFieldValue={formik.setFieldValue}
                keyword={"remark"}
                value={formik.values.remark}
                error={formik.errors.remark}
                touched={formik.touched.remark}
              />
            </Col>

            <Col sm={4}>
              <DateInput
                label={"Date Of Holy Communion"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfholyCommunion"}
                value={formik.values.dateOfholyCommunion}
                error={formik.errors.dateOfholyCommunion}
                touched={formik.touched.dateOfholyCommunion}
              />
            </Col>

            <Col sm={4}>
              <DateInput
                label={"Date Of Holy Confirmation"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfholyConfirmation"}
                value={formik.values.dateOfholyConfirmation}
                error={formik.errors.dateOfholyConfirmation}
                touched={formik.touched.dateOfholyConfirmation}
              />
            </Col>

            <Col sm={4}>
              <DateInput
                label={"Date Of Marriage"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfMarriage"}
                value={formik.values.dateOfMarriage}
                error={formik.errors.dateOfMarriage}
                touched={formik.touched.dateOfMarriage}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditBaptismDetailsModal);
