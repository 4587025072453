import { createSlice } from "@reduxjs/toolkit";

const ChurchSlice = createSlice({
  name: "church",
  initialState: {
    present: false,
    churchDetails: {},
    churchUserDetails: {},
  },
  reducers: {
    setChurchDetails(state, action) {
      state.churchDetails = action.payload;
      state.present = true;
    },
    setChurchUserDetails(state, action) {
      state.churchUserDetails = action.payload;
      state.present = true;
    },
  },
});

export const ChurchAction = ChurchSlice.actions;
export const ChurchReducer = ChurchSlice.reducer;
