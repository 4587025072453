import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .min(6, "Must be 6 characters or more")
    .matches(passwordRegex, "Weak Password.")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf(
      [Yup.ref("newPassword")],
      "Your password and confirmation password do not match."
    ),
});

const ResetPasswordModal = ({ show, setShow, commonAPIRequest }) => {
  const [loading, setLoading] = useState(false);
  const { churchDetails, churchUserDetails } = useSelector(
    (state) => state.church
  );

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      callUpdatePasswordApi(values);
    },
  });

  const callUpdatePasswordApi = (values) => {
    let serviceParams = {
      api: API.UPDATE_PASSWORD,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        churchId: churchDetails?.churchId,
        loginId: churchUserDetails?.userEmail,
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword,
      },
      noToken: true,
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Password Updated Successfully.`, "success");
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <div className="alert alert-danger text-left" role="alert">
              Your password must contain
              <br />- 1 Special character{" "}
              <span className="text-muted">e.g. (@, #, $, etc)</span>, <br />- 1
              Uppercase character{" "}
              <span className="text-muted">e.g. (S, A, P, etc)</span>, <br />- 1
              Digit <span className="text-muted">e.g. (1, 6, 4, etc)</span>,
              <br />- 1 Lowercase character{" "}
              <span className="text-muted">e.g. (d, e, u, etc)</span>,
            </div>
            <Row>
              <Col sm="12">
                <TextInput
                  label={"Old Password"}
                  placeholder={"Enter your old password."}
                  id={"oldPassword"}
                  setFieldValue={formik.setFieldValue}
                  keyword="oldPassword"
                  value={formik.values.oldPassword}
                  error={formik.errors.oldPassword}
                  touched={formik.touched.oldPassword}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"New Password"}
                  placeholder={"Enter your new password"}
                  id={"newPassword"}
                  setFieldValue={formik.setFieldValue}
                  keyword="newPassword"
                  value={formik.values.newPassword}
                  error={formik.errors.newPassword}
                  touched={formik.touched.newPassword}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"Confirm Password"}
                  placeholder={"Confirm your password"}
                  id={"confirmPassword"}
                  setFieldValue={formik.setFieldValue}
                  keyword="confirmPassword"
                  value={formik.values.confirmPassword}
                  error={formik.errors.confirmPassword}
                  touched={formik.touched.confirmPassword}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(ResetPasswordModal);
