import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import delhidioceselogo from "../../assets/img/logo-for-cert.png";

const DioceseDonationReceiptPdf = ({ details, churchDetails }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark */}
        {/* <View style={styles.watermarkContainer}>
          <Image style={{ width: "50%" }} src={waterMark} />
        </View> */}

        {/* Certificate Content */}
        <View style={styles.certContainer}>
          <div style={styles.borderGray}>
            <div style={styles.borderRed}>
              {/* Header */}
              <View>
                <View style={styles.header}>
                  <Image style={styles.image} src={churchDetails?.logoUrl} />
                  <View style={styles.headerText}>
                    <Text
                      style={{
                        fontSize: 24,
                        fontFamily: "Mate",
                      }}
                    >
                      {churchDetails?.churchName}
                    </Text>
                    <View style={styles.line} />
                    <Text
                      style={{ fontSize: 12, marginTop: 5, fontFamily: "Mate" }}
                    >
                      {churchDetails?.churchAddress?.addressLine1}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    //   backgroundColor: "red",
                  }}
                >
                  <Image
                    style={{ height: 72, width: "auto" }}
                    src={delhidioceselogo}
                  />
                </View>

                <View
                  style={{
                    position: "absolute",
                    top: 85,
                    right: 0,
                    alignItems: "flex-end",
                  }}
                >
                  <Text
                    style={[
                      styles.valueText,
                      { fontSize: 8, textAlign: "right" },
                    ]}
                  >
                    TRANSACTION ID:
                  </Text>
                  <Text
                    style={[
                      styles.keyText,
                      { fontSize: 10, textAlign: "right" },
                    ]}
                  >
                    {details?.sysTransactionId}
                  </Text>
                </View>

                <View style={styles.line} />

                {/* <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: "2px solid #d4af37",
                      paddingTop: 8,
                      paddingBottom: 4,
                      margin: 0,
                      fontSize: 13,
                    }}
                  >
                    Extract from the Parochial Register of St Mary Church,
                    Archdiocese Of Delhi
                  </Text>
                </View> */}

                <View>
                  <Text
                    style={{
                      textTransform: "uppercase",
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      fontFamily: "Mate",
                    }}
                  >
                    Donation Receipt
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      color: "#000",
                      textAlign: "right",
                      marginVertical: 20,
                      fontSize: 14,
                    }}
                  >
                    {new Date().toLocaleDateString("en-IN", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    })}
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  <Text style={styles.keyText}>
                    Thank you for your kind contribution!
                  </Text>
                  <Text style={styles.keyText}>
                    We would like to extend our heartfelt thanks for your
                    generous support and contribution to our church support
                    fund. Your kindness and commitment to our mission enable us
                    to continue our work and make a positive impact on the lives
                    of many.
                  </Text>
                  <Text style={styles.keyText}>
                    Your generosity plays a crucial role in sustaining our
                    programs, outreach efforts, and community services. With
                    your help, we can continue to provide spiritual guidance,
                    support, and hope to those in need.
                  </Text>

                  <Text style={styles.keyText}>
                    This receipt is an attestation that we have gratefully
                    received your generous contribution to our humble
                    institution. Keep this receipt for your tax deduction
                    purposes.
                  </Text>
                  {/* Dynamic content here */}

                  <View>
                    <Text style={[styles.valueText, { marginTop: 20 }]}>
                      Donor's Name:{" "}
                    </Text>
                    <Text style={styles.keyText}>{details?.donatedBy}</Text>
                  </View>

                  <View>
                    <Text style={[styles.valueText, { marginTop: 10 }]}>
                      Donor's Address:{" "}
                    </Text>
                    <Text style={styles.keyText}>{details?.addressLine1}</Text>
                  </View>

                  <View>
                    <Text
                      style={[
                        styles.valueText,
                        { marginTop: 10, fontSize: 10, marginBottom: 5 },
                      ]}
                    >
                      Donation Amount:{" "}
                    </Text>
                    <Text style={styles.keyText}>Rs. {details?.amount}</Text>
                  </View>

                  <View>
                    <Text style={[styles.valueText, { marginTop: 10 }]}>
                      Mode Of Payment:{" "}
                    </Text>
                    <Text style={styles.keyText}>{details?.paymentMode}</Text>
                  </View>

                  <View>
                    <Text style={[styles.valueText, { marginTop: 10 }]}>
                      Date Received:{" "}
                    </Text>
                    <Text style={styles.keyText}>
                      {new Date(details?.donationDate).toLocaleDateString(
                        "en-GB",
                        { month: "long", year: "numeric", day: "numeric" }
                      )}
                    </Text>
                  </View>

                  <View>
                    <Text style={[styles.valueText, { marginTop: 10 }]}>
                      Donation Purpose:{" "}
                    </Text>
                    <Text style={styles.keyText}>{details?.category}</Text>
                  </View>

                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text style={{ fontSize: 12 }}>Parish Priest</Text>
                  <Text style={{ fontSize: 10, color: "#7e7e7e" }}>
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12 }}>Seal</Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    // backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.2, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderGray: {
    padding: 5,
    // border: "3px solid #58595b",
    height: "100%",
  },
  borderRed: {
    // border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    // borderColor: "#d4af37",
    // boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    // backgroundColor: "rgb(240, 229, 207, 0.5)",
    height: "100%",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 10,
  },
  headerText: {
    textAlign: "center",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  image: {
    maxHeight: 80,
    maxWidth: 80,
    marginRight: 10,
  },
  keyText: { fontSize: 12, color: "#7e7e7e", marginBottom: 10, marginTop: 5 },
  valueText: {
    fontSize: 12,
    color: "#000",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "black",
    // marginVertical: 10,
  },
});

export default DioceseDonationReceiptPdf;
