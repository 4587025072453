import { createSlice } from "@reduxjs/toolkit";

const SearchSlice = createSlice({
  name: "search",
  initialState: {
    present: false,
    members: [],
  },
  reducers: {
    getMembers(state, action) {
      state.members = action.payload;
      state.present = true;
    },
  },
});

export const SearchAction = SearchSlice.actions;
export const SearchReducer = SearchSlice.reducer;
