import React from "react";
import { Button } from "react-bootstrap";
import { downloadExcel } from "react-export-table-to-excel";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const DonationExcelReport = ({
  totalCount,
  currentMonth,
  commonAPIRequest,
}) => {
  const header = [
    "S.No",
    "Name",
    "Amount",
    "Donated On",
    "Mode",
    "Purpose",
    "Received By",
    "Transaction Id",
    "Remark",
  ];

  // call api for get all donations

  const getAllDonationsApi = () => {
    let serviceParams = {
      api: `${
        API.DONATION_SUMMARY
      }?pgNum=1&pgSize=${totalCount}&month=${currentMonth}&year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        const data = result.payload?.donationDetailList.map((donation, i) => {
          return {
            sno: i + 1,
            name: donation?.donatedBy,
            amount: donation?.amount,
            donatedOn: new Date(donation?.donationDate).toLocaleDateString(
              "en-IN"
            ),
            mode: donation?.paymentMode,
            purpose: donation?.category,
            receivedBy: donation?.createdBy,
            txnId: donation?.sysTransactionId,
            remark: donation?.description,
          };
        });
        downloadExcel({
          fileName: `donations_MONTH_${currentMonth}_${new Date().getFullYear()}`,
          sheet: "donation",
          tablePayload: {
            header,
            // accept two different data structures
            body: data,
          },
        });
        try {
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  // call api for get all donations

  /**
   * @description:
   *  also accepts an array of objects; the method (downloadExcel) will take
   *  as order of each column, the order that each property of the object brings with it.
   *  the method(downloadExcel) will only take the value of each property.
   */

  function handleDownloadExcel() {
    getAllDonationsApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(DonationExcelReport);
