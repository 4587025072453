import React from "react";
import churchLogo from "../../assets/img/logo-1.png";
import waterMark from "../../assets/img/holy-confirmation.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FontDancingRegular from "../../assets/fonts/dancingscript/DancingScript-Regular.ttf";
import FontMateSCRegular from "../../assets/fonts/matesc/MateSC-Regular.ttf";
import delhidioceselogo from "../../assets/img/logo-for-cert.png";

Font.register({
  family: "Dancing",
  fonts: [
    {
      src: FontDancingRegular,
    },
  ],
});

Font.register({
  family: "Mate",
  fonts: [
    {
      src: FontMateSCRegular,
    },
  ],
});

const HolyCommunionCertificatePdf = ({ details, churchDetails }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark */}
        {churchDetails?.watermarkUrl && (
          <View style={styles.watermarkContainer}>
            <Image style={{ width: "50%" }} src={churchDetails?.watermarkUrl} />
          </View>
        )}

        {/* Certificate Content */}
        <View style={styles.certContainer}>
          <div style={styles.borderGray}>
            <div style={styles.borderRed}>
              {/* Header */}
              <View>
                <View style={styles.header}>
                  {/* <Image style={styles.image} src={churchLogo} /> */}
                  {/* <Image style={styles.image} src={churchLogo} /> */}
                  {churchDetails?.logoUrl && (
                    <Image style={styles.image} src={churchDetails?.logoUrl} />
                  )}
                  <View
                    style={{
                      position: "absolute",
                      top: -20,
                      right: -15,
                      //   backgroundColor: "red",
                    }}
                  >
                    <Image
                      style={{ height: 72, width: "auto" }}
                      src={delhidioceselogo}
                    />
                  </View>
                  <View style={styles.headerText}>
                    <Text
                      style={{
                        fontSize: 24,
                        fontFamily: "Mate",
                      }}
                    >
                      {churchDetails?.churchName}
                    </Text>
                    <Text
                      style={{ fontSize: 12, marginTop: 5, fontFamily: "Mate" }}
                    >
                      {churchDetails?.churchAddress?.addressLine1}
                    </Text>
                  </View>
                </View>

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: "2px solid #d4af37",
                      paddingTop: 8,
                      paddingBottom: 4,
                      margin: 0,
                      fontSize: 13,
                      fontFamily: "Mate",
                    }}
                  >
                    Extract from the Parochial Register of St Mary Church,
                    Archdiocese Of Agra
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      // textTransform: "uppercase",
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      fontFamily: "Dancing",
                      fontSize: 32,
                    }}
                  >
                    Holy Communion Certificate
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  {/* Dynamic content here */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.keyText}>Full Name :</Text>
                      <Text style={styles.keyText}>Date of Communion :</Text>
                      <Text style={styles.keyText}>Father's Name :</Text>
                      <Text style={styles.keyText}>Mother's Name :</Text>
                      <Text style={styles.keyText}>Date of Birth/Age :</Text>
                      <Text style={styles.keyText}>Date of Baptism :</Text>
                      <Text style={styles.keyText}>Sex :</Text>
                      <Text style={styles.keyText}>Mobile No. :</Text>
                      <Text style={styles.keyText}>Address :</Text>
                      <Text style={styles.keyText}>Reference No. :</Text>
                    </View>

                    <View style={{ marginLeft: 20 }}>
                      <Text style={styles.valueText}>
                        {details?.middleName
                          ? `${details?.firstName} ${details?.middleName} ${details?.lastName}`
                          : `${details?.firstName} ${details?.lastName}`}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.holyCommunionDate
                          ? new Date(
                              details?.holyCommunionDate
                            ).toLocaleDateString("en-IN", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.fatherName ? details?.fatherName : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.motherName ? details?.motherName : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.dob
                          ? new Date(details?.dob).toLocaleDateString("en-IN", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.baptismDate
                          ? new Date(details?.baptismDate).toLocaleDateString(
                              "en-IN",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.gender ? details?.gender : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.mobileNum ? `+91-${details?.mobileNum}` : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.address ? details?.address : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.referenceNum ? details?.referenceNum : "-"}
                      </Text>
                    </View>
                  </View>

                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text style={{ fontSize: 12 }}>Parish Priest</Text>
                  <Text style={{ fontSize: 10, color: "#7e7e7e" }}>
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12 }}>Seal</Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.15, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderGray: {
    padding: 5,
    border: "3px solid #58595b",
    height: "100%",
  },
  borderRed: {
    border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    // borderColor: "#d4af37",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    backgroundColor: "rgb(240, 229, 207, 0.5)",
    height: "100%",
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 10,
  },
  headerText: {
    textAlign: "center",
    width: "100%",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  image: {
    maxHeight: 100,
    maxWidth: 100,
    marginRight: 10,
  },
  keyText: {
    fontSize: 12,
    color: "#7e7e7e",
    marginBottom: 10,
    fontFamily: "Mate",
  },
  valueText: {
    fontSize: 12,
    color: "#000",
    textTransform: "capitalize",
    fontFamily: "Mate",
    marginBottom: 10,
  },
});

export default HolyCommunionCertificatePdf;
