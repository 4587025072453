import Lottie from "lottie-react";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import TextArea from "../../components/form/TextArea";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import * as Yup from "yup";

const ParishValidationSchema = Yup.object().shape({
  churchName: Yup.string().required("Required"),
  parishPriest: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  abbreviation: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

const AddParishModal = ({
  show,
  setShow,
  commonAPIRequest,
  callGetAllChurches,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      churchName: "",
      parishPriest: "",
      address: "",
      abbreviation: "",
      uploadedImage: "",
      description: "",
    },
    validationSchema: ParishValidationSchema,
    onSubmit: (values) => {
      callAddParishInResourceApi();
    },
  });

  const callAddParishInResourceApi = (id) => {
    let serviceParams = {
      api: API.CREATE_CHURCH_RESOURCE,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        churchName: formik.values.churchName,
        fatherName: formik.values.parishPriest,
        churchCode: formik.values.abbreviation,
        address: {
          addressLine1: formik.values.address,
          addressLine2: "",
          block: "",
          sector: "",
          city: "",
          state: "",
          pinCode: "",
        },
        imageBase64: "",
        imageFormat: "",
        logoBase64: "",
        logoFormat: "",
        watermarkBase64: "",
        watermarkFormat: "",
        description: formik.values.description,
      },
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setShow(false);
          callGetAllChurches();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add New Parish
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col sm="12">
                <TextInput
                  label={"Church Name"}
                  placeholder={"e.g. St. Mary's Church"}
                  id={"churchName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="churchName"
                  value={formik.values.churchName}
                  error={formik.errors.churchName}
                  touched={formik.touched.churchName}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"Parish Priest (Full Name)"}
                  placeholder={"e.g. Fr. Joseph"}
                  id={"parishPriest"}
                  setFieldValue={formik.setFieldValue}
                  keyword="parishPriest"
                  value={formik.values.parishPriest}
                  error={formik.errors.parishPriest}
                  touched={formik.touched.parishPriest}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"Abbreviation"}
                  placeholder={"e.g. SMC"}
                  id={"abbreviation"}
                  setFieldValue={formik.setFieldValue}
                  keyword="abbreviation"
                  value={formik.values.abbreviation}
                  error={formik.errors.abbreviation}
                  touched={formik.touched.abbreviation}
                />
              </Col>
              <Col sm="12">
                <TextArea
                  label={"Address"}
                  placeholder={"e.g. RK PURAM, DELHI, 110011"}
                  id={"address"}
                  setFieldValue={formik.setFieldValue}
                  keyword="address"
                  value={formik.values.address}
                  error={formik.errors.address}
                  touched={formik.touched.address}
                />
              </Col>
              <Col sm="12">
                <TextArea
                  label={"Description"}
                  placeholder={
                    "e.g. The Only Church Located in the heart of Delhi"
                  }
                  id={"description"}
                  setFieldValue={formik.setFieldValue}
                  keyword="description"
                  value={formik.values.description}
                  error={formik.errors.description}
                  touched={formik.touched.description}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddParishModal);
