import React, { useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { useFormik } from "formik";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { Link } from "react-router-dom";

const FindMarriageForAnnulmentModal = ({
  show,
  setShow,
  commonAPIRequest,
  setCurrentMarriageId,
  setOutsiderAnnulmentModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [marriageList, setMarriageList] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    // validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchMarriageApi(fullNameArray[0], "")
        : callSearchMarriageApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  // api to search marriage details by name start

  const callSearchMarriageApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${
        API.SEARCH_MARRIAGE_DETAILS
      }?firstName=${firstName.trim()}${`&lastName=${lastName.trim()}`}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setMarriageList(result?.payload);
          //   dispatch(
          //     MarriagesAction.getHolyCommunions(
          //       result?.payload ? result?.payload : []
          //     )
          //   );
          //   dispatch(MarriagesAction.getTotalCount(0));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search marriage details by name end

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
          Search Marriage Certificate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Label className="fw-bold">
              Full Name of Bride or Groom
            </Form.Label>
            <p className="text-muted m-0">
              In order to create a annulment certificate, it's mandatory to have
              the marriage in the same parish .
            </p>
            <div className="form-search py-2  ">
              <i className="ri-search-line"></i>
              <Form.Control
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                type="text"
                placeholder="Search here..."
              />
              <Button onClick={formik.handleSubmit} variant="primary">
                Search
              </Button>
            </div>
          </Col>

          <hr className="hr mt-4" />

          {/* {loading && <ZonePlaceholder />} */}

          {!loading &&
            marriageList.map((marriage, index) => (
              <Col lg={6} key={index}>
                {
                  <Card className="card-people shadow">
                    <Card.Body>
                      <div className="mb-4 ">
                        <div className="ms-3 ">
                          <h6 className="mt-3">
                            Groom's Name -{" "}
                            <Link className="fw-bold text-primary" to="">
                              {marriage.groomFirstName} {marriage.groomLastName}
                            </Link>
                            <br className="" />
                            <div className="my-2"></div>
                            Bride's Name -{" "}
                            <Link className="fw-bold text-primary" to="">
                              {marriage.brideFirstName} {marriage.brideLastName}
                            </Link>
                            <br />
                            <div className="my-2"></div>
                            Marriage Status:{" "}
                            {marriage?.active ? (
                              <Badge className="ms-1 fw-bold" bg="success" pill>
                                Active
                              </Badge>
                            ) : (
                              <Badge className="ms-1 fw-bold" bg="danger" pill>
                                Annulled
                              </Badge>
                            )}
                          </h6>
                          <div className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                setCurrentMarriageId(marriage.marriageId);
                                setOutsiderAnnulmentModal(true);
                              }}
                              disabled={!marriage?.active}
                            >
                              Select
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                }
              </Col>
            ))}

          {!loading && marriageList.length === 0 && (
            <div className="mb-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="text-center"
              >
                <Lottie
                  style={{ width: 180, height: 180 }}
                  animationData={noSearchAnimation}
                  loop={true}
                />
              </div>
              <h3 className="text-center fw-bold">No Marriage's Found</h3>
              <h5 className="text-center fw-400">
                Please Try With Another Name.
              </h5>
            </div>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default withAPIRequest(FindMarriageForAnnulmentModal);
