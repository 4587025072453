import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { ExtHolyCommunionValidationSchema } from "../../validations/holy-communion";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const AddExternalHolyCommunionDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  baptismDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const { churchDetails } = useSelector((state) => state.church);
  const formik = useFormik({
    initialValues: {
      baptismDate: "",
      holyCommunionDate: "",
      holyCommunionChurchName: "",
      holyCommunionChurchCity: "",
      baptismDateForValidation: "",
      holyCommunionDateNotSure: false,
    },
    validationSchema: ExtHolyCommunionValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Baptism</dt>
          <dd>${new Date(values?.baptismDate).toLocaleDateString("en-GB", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}</dd>
          <dt>Date Of Holy Communion </dt>
          <dd>${new Date(values?.holyCommunionDate).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
          <dt>Holy Communion Church Name</dt>
          <dd>${
            values?.holyCommunionChurchName
              ? values?.holyCommunionChurchName
              : "Not Sure"
          }</dd>
           <dt>Holy Communion Church City</dt>
           <dd>${
             values?.holyCommunionChurchCity
               ? values?.holyCommunionChurchCity
               : "Not Sure"
           }</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callAddHolyCommunionDetailsApi(values);
        }
      });
    },
  });

  // function to call API for Add New Holy Communion start

  const callAddHolyCommunionDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_HOLY_COMMUNION_API,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        holyCommunionDetail: {
          firstName: capitalizeText(basicDetails?.firstName.trim()),
          middleName: capitalizeText(basicDetails?.middleName.trim()),
          lastName: capitalizeText(basicDetails?.lastName.trim()),
          holyCommunionDate: new Date(
            formik.values.holyCommunionDate
          ).toISOString(),
          extHolyCommunion: true,
          extHolyCommunionChurch: capitalizeText(
            formik.values.holyCommunionChurchName.trim()
          ),
          extHolyCommunionCity: capitalizeText(
            formik.values.holyCommunionChurchCity.trim()
          ),
          baptismDate: new Date(formik.values.baptismDate).toISOString(),
          holyCommunionDateAccurate: !formik.values.holyCommunionDateNotSure,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Holy Communion Created Successfully.`,
            "success"
          );
          getFamilyMemberDetails();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Holy Communion end

  useEffect(() => {
    if (show) {
      formik.setFieldValue(
        "baptismDate",
        new Date(baptismDetails?.baptismDate)
      );
      formik.setFieldValue(
        "baptismDateForValidation",
        new Date(
          new Date(baptismDetails?.baptismDate).setDate(
            new Date(baptismDetails?.baptismDate).getDate() - 1
          )
        )
      );
      if (baptismDetails?.holyCommunionDate) {
        formik.setFieldValue(
          "holyCommunionDate",
          new Date(baptismDetails?.holyCommunionDate)
        );
      }
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Holy Communion Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row>
            <p className="text-muted">
              As {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}'s holy communion was done{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                outside of {churchDetails?.churchName}
              </span>{" "}
              <br /> Please Enter these basic holy communion details for the
              records.
            </p>
            <Col sm={12}>
              <DateInput
                label={"Baptism Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"baptismDate"}
                value={formik.values.baptismDate}
                error={formik.errors.baptismDate}
                touched={formik.touched.baptismDate}
                disabled={baptismDetails?.baptismDate ? true : false}
              />
            </Col>
            <Col sm={12}>
              <DateInput
                label={"Holy Communion Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionDate"}
                value={formik.values.holyCommunionDate}
                error={formik.errors.holyCommunionDate}
                touched={formik.touched.holyCommunionDate}
              />
            </Col>

            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if holy communion date is correct"
                onChange={(e) => {
                  formik.setFieldValue(
                    "holyCommunionDateNotSure",
                    e.target.checked
                  );
                }}
                checked={formik.values.holyCommunionDateNotSure}
              />
            </Col>

            <Col sm={6}>
              <TextInput
                label={"Holy Communion Church Name"}
                placeholder={"e.g. St. Mary's Church"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionChurchName"}
                value={formik.values.holyCommunionChurchName}
                error={formik.errors.holyCommunionChurchName}
                touched={formik.touched.holyCommunionChurchName}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Holy Communion Church City"}
                placeholder={"e.g. Noida"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionChurchCity"}
                value={formik.values.holyCommunionChurchCity}
                error={formik.errors.holyCommunionChurchCity}
                touched={formik.touched.holyCommunionChurchCity}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddExternalHolyCommunionDetailsModal);
