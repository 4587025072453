import { createSlice } from "@reduxjs/toolkit";

const LoadingSlice = createSlice({
  name: "loading",
  initialState: {
    loading: false,
    translucentLoader: false,
  },
  reducers: {
    startLoader(state) {
      state.loading = true;
    },
    endLoader(state) {
      state.loading = false;
    },
    startTranslucentLoader(state) {
      state.translucentLoader = true;
    },
    endTranslucentLoader(state) {
      state.translucentLoader = false;
    },
  },
});

export const LoadingAction = LoadingSlice.actions;
export const LoadingReducer = LoadingSlice.reducer;
