import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import { useFormik } from "formik";
import TextArea from "../../components/form/TextArea";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ImportMemberBasicFamilyDetailsModal = ({
  show,
  setShow,
  basicDetails,
  memberId,
  commonAPIRequest,
  familyDetails,
}) => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      dateOfRegistration: "",
      homeParish: "",
      lastParish: "",
      csf: "",
      address: "",
    },
    onSubmit: () => {
      callAddNewFamilyOfExistingMember();
    },
  });

  // function to call API for Add new family start

  const callAddNewFamilyOfExistingMember = () => {
    let serviceParams = {
      api: API.TRANSFER_CREATE_NEW_FAMILY,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        churchFamilyDetail: {
          subZoneId: familyDetails?.subZoneId,
          dateOfRegistration: formik.values.dateOfRegistration,
          address: {
            addressLine1: formik.values.address,
            addressLine2: "",
            block: null,
            sector: null,
            city: "",
            state: "",
            pinCode: "",
          },
          homeParish: formik.values.homeParish,
          lastParish: formik.values.lastParish,
          monthlyDonation: formik.values.csf,
          active: true,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `New Family Created Successfully for ${basicDetails?.firstName} ${basicDetails?.lastName}.`,
            "success"
          );
          setShow(false);
          formik.resetForm();
          navigate("/admin/families");
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New family end

  useEffect(() => {
    if (show) {
      formik.setFieldValue(
        "dateOfRegistration",
        new Date(familyDetails?.dateOfRegistration)
      );
      formik.setFieldValue("address", familyDetails?.address?.addressLine1);
      formik.setFieldValue("homeParish", familyDetails?.homeParish);
      formik.setFieldValue("lastParish", familyDetails?.lastParish);
      formik.setFieldValue("csf", familyDetails?.monthlyDonation);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add New Family Basic Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row>
            <Col sm={12}>
              <DateInput
                label={"Date Of Registration"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfRegistration"}
                value={formik.values.dateOfRegistration}
                error={formik.errors.dateOfRegistration}
                touched={formik.touched.dateOfRegistration}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Home Parish"}
                setFieldValue={formik.setFieldValue}
                keyword={"homeParish"}
                value={formik.values.homeParish}
                error={formik.errors.homeParish}
                touched={formik.touched.homeParish}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Last Parish"}
                setFieldValue={formik.setFieldValue}
                keyword={"lastParish"}
                value={formik.values.lastParish}
                error={formik.errors.lastParish}
                touched={formik.touched.lastParish}
              />
            </Col>
            <Col sm={12}>
              <TextInput
                label={"Monthly C.S.F Contribution"}
                setFieldValue={formik.setFieldValue}
                keyword={"csf"}
                value={formik.values.csf}
                error={formik.errors.csf}
                touched={formik.touched.csf}
              />
            </Col>
            <Col sm={12}>
              <TextArea
                label={"Residential Address"}
                setFieldValue={formik.setFieldValue}
                keyword={"address"}
                value={formik.values.address}
                error={formik.errors.address}
                touched={formik.touched.address}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(ImportMemberBasicFamilyDetailsModal);
