import React from "react";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { Button } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { downloadExcel } from "react-export-table-to-excel";

const ExpenseExcelReport = ({ totalCount, currentMonth, commonAPIRequest }) => {
  const header = [
    "S.No",
    "Amount",
    "Expense Made On",
    "Mode",
    "Purpose",
    "Added By",
  ];

  // call api for get all expenses

  const getAllExpensesApi = () => {
    let serviceParams = {
      api: `${
        API.EXPENSE_SUMMARY
      }?pgNum=1&pgSize=${totalCount}&month=${currentMonth}&year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          const data = result.payload?.expenseDetailList.map((expense, i) => {
            return {
              sno: i + 1,
              amount: expense?.amount,
              exopenseMadeOn: new Date(expense?.expenseDate).toLocaleDateString(
                "en-IN"
              ),
              mode: expense?.paymentMode,
              purpose: expense?.description,
              addedBy: expense?.createdBy,
            };
          });
          downloadExcel({
            fileName: `expenses_MONTH_${currentMonth}`,
            sheet: "expenses",
            tablePayload: {
              header,
              // accept two different data structures
              body: data,
            },
          });
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  // call api for get all expenses

  /**
   * @description:
   *  also accepts an array of objects; the method (downloadExcel) will take
   *  as order of each column, the order that each property of the object brings with it.
   *  the method(downloadExcel) will only take the value of each property.
   */

  function handleDownloadExcel() {
    getAllExpensesApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(ExpenseExcelReport);
