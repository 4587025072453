import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import AddDemiseDetailsModal from "../../modals/demise/AddDemiseDetailsModal";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";

const DemiseDetails = ({
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  deathDetails,
  commonAPIRequest,
}) => {
  const [addDemiseDetailsModal, setAddDemiseDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [letterPdf, setLetterPdf] = useState();
  const [certPdf, setCertPdf] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // call generate pdf certificate api for burial letter start

  const callGetBurialLetterCertificateApi = () => {
    let serviceParams = {
      api: API.GENERATE_BURIAL_LETTER,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        fullName: `${basicDetails?.firstName} ${basicDetails?.middleName} ${basicDetails?.lastName}`,
        deathDate: new Date(deathDetails?.deathDate),
        burialDate: new Date(deathDetails?.burialDate),
        priestName: deathDetails?.ministerName,
        gender: basicDetails?.gender,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setLetterPdf(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call generate pdf certificate api for burial letter end

  // call generate pdf certificate api for burial certificate start

  // const callGetBurialCertificateCertificateApi = () => {
  //   let serviceParams = {
  //     api: `${API.GENERATE_BURIAL_CERTIFICATE}?memberId=${memberId}`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     customHeaders: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   setLoading2(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading2(false);
  //       try {
  //         console.log("result", result);
  //         // setCertPdf(result?.payload);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading2(false);
  //     }
  //   });
  // };

  // call generate pdf certificate api for burial certificate end

  return (
    <div>
      {deathDetails ? (
        <Card className="card-post mt-4 ">
          <Card.Header>
            <h3 className="fw-bold text-primary">Demise Information</h3>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Demise Date</strong>
                      </p>
                      <p className="mb-0">
                        {" "}
                        {new Date(deathDetails?.deathDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Burial Date</strong>
                      </p>
                      <p className="mb-0">
                        {new Date(deathDetails?.burialDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Burial Place</strong>
                      </p>
                      <p className="mb-0">{deathDetails?.burialPlace}</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Minister's Name</strong>
                      </p>
                      <p className="mb-0">{deathDetails?.ministerName}</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Cause Of Death</strong>
                      </p>
                      <p className="mb-0">{deathDetails?.deathCause}</p>
                    </div>
                  </Col>
                  <Col sm={10}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Remark</strong>
                      </p>
                      <p className="mb-0">{deathDetails?.remark}</p>
                    </div>
                  </Col>
                </Row>
                <hr className="hr" />
                <div className="text-center m-4">
                  <Card.Title className="text-dark">
                    Generate Burial Letter for {basicDetails?.firstName}{" "}
                    {basicDetails?.middleName} {basicDetails?.lastName}
                  </Card.Title>
                  <Card.Text>
                    Please click on Generate below for generating burial letter
                    copy for{" "}
                    <span className="fw-bold text-primary">
                      {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                      {basicDetails?.lastName}
                    </span>
                  </Card.Text>
                  {loading ? (
                    <div className="justify-content-center align-items-center d-flex">
                      <Lottie
                        style={{ width: 100, height: 100 }}
                        animationData={loadingAnimation}
                        loop={true}
                      />
                    </div>
                  ) : letterPdf ? (
                    <Button
                      onClick={() => {
                        let pdfWindow = window.open("");
                        pdfWindow.document.write(
                          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                            encodeURI(letterPdf) +
                            "'></iframe>"
                        );
                      }}
                      variant="primary"
                    >
                      Print
                    </Button>
                  ) : (
                    <Button
                      onClick={callGetBurialLetterCertificateApi}
                      variant="primary"
                    >
                      Generate
                    </Button>
                  )}
                </div>

                {/*  */}
                {/* <hr className="hr" />
                <div className="text-center m-4">
                  <Card.Title className="text-dark">
                    Generate Burial Certificate for {basicDetails?.firstName}{" "}
                    {basicDetails?.middleName} {basicDetails?.lastName}
                  </Card.Title>
                  <Card.Text>
                    Please click on Generate below for generating Burial
                    Certificate copy for{" "}
                    <span className="fw-bold text-primary">
                      {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                      {basicDetails?.lastName}
                    </span>
                  </Card.Text>
                  {loading2 ? (
                    <div className="justify-content-center align-items-center d-flex">
                      <Lottie
                        style={{ width: 100, height: 100 }}
                        animationData={loadingAnimation}
                        loop={true}
                      />
                    </div>
                  ) : letterPdf ? (
                    <Button
                      onClick={() => {
                        let pdfWindow = window.open("");
                        pdfWindow.document.write(
                          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                            encodeURI(certPdf) +
                            "'></iframe>"
                        );
                      }}
                      variant="primary"
                    >
                      Print
                    </Button>
                  ) : (
                    <Button
                      onClick={callGetBurialCertificateCertificateApi}
                      variant="primary"
                    >
                      Generate
                    </Button>
                  )}
                </div> */}
                {/*  */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <Card.Body className="text-center m-4">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 150, height: 150 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
          </div>
          <Card.Title className="text-dark">No Demise Details Found</Card.Title>
          <Card.Text>
            Please add Demise details for{" "}
            <span className="fw-bold text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </span>
          </Card.Text>
          <Button
            onClick={() => setAddDemiseDetailsModal(true)}
            variant="primary"
          >
            Add
          </Button>
        </Card.Body>
      )}
      <AddDemiseDetailsModal
        show={addDemiseDetailsModal}
        setShow={setAddDemiseDetailsModal}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
    </div>
  );
};

export default withAPIRequest(DemiseDetails);
