import React from "react";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { downloadExcel } from "react-export-table-to-excel";
import { Button } from "react-bootstrap";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const DioceseDonationExcelReport = ({
  totalCount,
  currentMonth,
  commonAPIRequest,
}) => {
  const header = [
    "S.No",
    "Name",
    "Amount",
    "Donated On",
    "Mode",
    "Purpose",
    "Received By",
    "Transaction Id",
    "Remark",
  ];

  // call api for get all diocese donation

  const getAllDioceseDonationApi = () => {
    let serviceParams = {
      api: `${
        API.DIOCESE_DONATION_SUMMARY
      }?pgNum=1&pgSize=${totalCount}&month=${currentMonth}&year=${new Date().getFullYear()}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setLoading(false);
        try {
          const data = result.payload?.diocesanDonationDetailList.map(
            (donation, i) => {
              return {
                sno: i + 1,
                name: donation?.donatedBy,
                amount: donation?.amount,
                donatedOn: new Date(donation?.donationDate).toLocaleDateString(
                  "en-IN"
                ),
                mode: donation?.paymentMode,
                purpose: donation?.category,
                receivedBy: donation?.createdBy,
                txnId: donation?.sysTransactionId,
                remark: donation?.description,
              };
            }
          );
          downloadExcel({
            fileName: `diocese_donation_MONTH_${currentMonth}_${new Date().getFullYear()}`,
            sheet: "diocese_donation",
            tablePayload: {
              header,
              // accept two different data structures
              body: data,
            },
          });
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setLoading(false);
      }
    });
  };

  // call api for get all diocese donation

  function handleDownloadExcel() {
    getAllDioceseDonationApi();
  }

  return (
    <div>
      <Button className="ms-3" onClick={handleDownloadExcel}>
        <i className="ri-download-2-fill"></i>
      </Button>
    </div>
  );
};

export default withAPIRequest(DioceseDonationExcelReport);
