import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import TextArea from "../../components/form/TextArea";
import { useFormik } from "formik";
import { HolyOrderValidationSchema } from "../../validations/holy-order";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";

const AddHolyOrderDetailsDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  memberId,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      diocese: "",
      dateOfOrdination: "",
      dateOfFinalVows: "",
      remark: "",
    },
    validationSchema: HolyOrderValidationSchema,
    onSubmit: (values) => {
      callAddHolyOrder();
    },
  });

  // function to call API for Add Holy order start

  const callAddHolyOrder = () => {
    let serviceParams = {
      api: API.ADD_HOLY_ORDER,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        holyOrderDetail: {
          diocese: formik.values.diocese.trim(),
          ordinationDate: new Date(formik.values.dateOfOrdination),
          finalVowsDate: new Date(formik.values.dateOfFinalVows),
          remark: formik.values.remark.trim(),
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Holy Order Details Added Successfully.`,
            "success"
          );
          getFamilyMemberDetails();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add Holy order end

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Add Holy Order Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row>
            <Col sm={4}>
              <TextInput
                label={"Diocese"}
                placeholder={"e.g. Delhi"}
                setFieldValue={formik.setFieldValue}
                keyword={"diocese"}
                value={formik.values.diocese}
                error={formik.errors.diocese}
                touched={formik.touched.diocese}
              />
            </Col>
            <Col sm={4}>
              <DateInput
                label={"Date Of Ordination"}
                placeholder={"Select Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfOrdination"}
                value={formik.values.dateOfOrdination}
                error={formik.errors.dateOfOrdination}
                touched={formik.touched.dateOfOrdination}
              />
            </Col>
            <Col sm={4}>
              <DateInput
                label={"Date Of Final Vows"}
                placeholder={"Select Date"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfFinalVows"}
                value={formik.values.dateOfFinalVows}
                error={formik.errors.dateOfFinalVows}
                touched={formik.touched.dateOfFinalVows}
              />
            </Col>
            <Col sm={12}>
              <TextArea
                label={"Remark"}
                placeholder={"Type any remark here... "}
                setFieldValue={formik.setFieldValue}
                keyword={"remark"}
                value={formik.values.remark}
                error={formik.errors.remark}
                touched={formik.touched.remark}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddHolyOrderDetailsDetailsModal);
