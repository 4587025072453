import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    passwordExpiry: "",
    phone: "",
    firstName: "",
    lastName: "",
  },
  reducers: {
    loggedIn() {},
    loggedOut() {},
  },
});

export const AuthAction = AuthSlice.actions;
export const AuthReducer = AuthSlice.reducer;
