import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import UpdateFamilyHeadFormStep1 from "../../forms/families/UpdateFamilyHeadFormStep1";
import { useFormik } from "formik";
import UpdateFamilyHeadFormStep2 from "../../forms/families/UpdateFamilyHeadFormStep2";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../HOC/withAPIRequest";

const UpdateFamilyHeadModal = ({
  show,
  setShow,
  commonAPIRequest,
  getAllFamilyMembersDetailsApi,
}) => {
  const { familyMembers } = useSelector((state) => state.family);
  const [currentState, setCurrentState] = useState(1);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      newHeadMemberId: "",
      memberRelationDetails: [],
    },
    onSubmit: (values) => {
      callChangeFamilyHeadApi();
    },
  });

  const callChangeFamilyHeadApi = () => {
    let serviceParams = {
      api: API.CHANGE_HEAD_OF_FAMILY,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        newHeadMemberId: formik.values.newHeadMemberId,
        memberRelationDetails: formik.values.memberRelationDetails,
      },
    };
    setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Family Head Updated Successfully.`, "success");
          setShow(false);
          getAllFamilyMembersDetailsApi();
          formik.resetForm();
          setCurrentState(1);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Family Head
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h5 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Current Head Of Family is{" "}
                    <span className="fw-bold text-primary">
                      {familyMembers &&
                        `${familyMembers[0]?.firstName} ${familyMembers[0]?.lastName}`}
                    </span>
                  </h5>

                  <p className="mb-0">
                    Please select other new Head Of Family from the member's
                    listed below
                  </p>
                  <hr className="hr" />
                  <UpdateFamilyHeadFormStep1
                    formik={formik}
                    setCurrentState={setCurrentState}
                    familyMembers={familyMembers?.filter(
                      (member) => !member.familyHead && !member?.dead
                    )}
                  />
                </div>
              )}

              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Update Relation's
                  </h3>

                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <UpdateFamilyHeadFormStep2
                    formik={formik}
                    familyMembers={familyMembers?.filter(
                      (member) =>
                        member.memberId !== formik.values.newHeadMemberId
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {currentState === 2 && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCurrentState(1)}>
            Previous
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(UpdateFamilyHeadModal);
