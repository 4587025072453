import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { ZoneValidationSchema } from "../../validations/zones";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Swal from "sweetalert2";
import TextInput from "../../components/form/TextInput";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const AddZoneModal = ({ show, setShow, commonAPIRequest, callGetAllZones }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      zoneName: "",
      zoneRepName: "",
      zoneRepPhNum: "",
      zoneCoordName: "",
      zoneCoordPhNum: "",
      zoneSecyName: "",
      zoneSecyPhNum: "",
    },
    validationSchema: ZoneValidationSchema,
    onSubmit: (values) => {
      callAddZones(values);
    },
  });

  // function to call API for Add New Zone start

  const callAddZones = (value) => {
    let serviceParams = {
      api: API.ADD_ZONE,
      method: AXIOS_METHOD_TYPES.POST,
      data: {
        zoneDetails: [
          {
            zoneName: capitalizeText(value.zoneName.trim()),
            zoneRepName: capitalizeText(value.zoneRepName.trim()),
            zoneRepPhNum: value.zoneRepPhNum,
            zoneCoordName: capitalizeText(value.zoneCoordName.trim()),
            zoneCoordPhNum: value.zoneCoordPhNum,
            zoneSecyName: capitalizeText(value.zoneSecyName.trim()),
            zoneSecyPhNum: value.zoneSecyPhNum,
          },
        ],
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Zone Created Successfully.`, "success");
          callGetAllZones();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Zone end

  useEffect(() => {
    if (show) {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          formik.handleSubmit();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">Add Zone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <div className="mb-3">
              <TextInput
                label={"Zone Name"}
                placeholder={"e.g. St. Mathews"}
                id={"zoneName"}
                setFieldValue={formik.setFieldValue}
                keyword="zoneName"
                value={formik.values.zoneName}
                error={formik.errors.zoneName}
                touched={formik.touched.zoneName}
              />
            </div>
            <Row>
              <Col sm="6">
                <TextInput
                  label={"Zone Incharge"}
                  placeholder={"e.g. Nikhil"}
                  id={"zoneIncharge"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneRepName"
                  value={formik.values.zoneRepName}
                  error={formik.errors.zoneRepName}
                  touched={formik.touched.zoneRepName}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={" Incharge Mobile"}
                  placeholder={"e.g. 9879XXXXXX"}
                  id={"inchargeMobile"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneRepPhNum"
                  value={formik.values.zoneRepPhNum}
                  error={formik.errors.zoneRepPhNum}
                  touched={formik.touched.zoneRepPhNum}
                  disabled={formik.values.zoneRepName ? false : true}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Zone Secretary"}
                  placeholder={"e.g. Akshay"}
                  id={"zoneSecretary"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneSecyName"
                  value={formik.values.zoneSecyName}
                  error={formik.errors.zoneSecyName}
                  touched={formik.touched.zoneSecyName}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Secretary Mobile"}
                  placeholder={"e.g. 9878XXXXXX"}
                  id={"secretaryMobile"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneSecyPhNum"
                  value={formik.values.zoneSecyPhNum}
                  error={formik.errors.zoneSecyPhNum}
                  touched={formik.touched.zoneSecyPhNum}
                  disabled={formik.values.zoneSecyName ? false : true}
                />
              </Col>

              <Col sm="6">
                <TextInput
                  label={"Zone Coordinator"}
                  placeholder={"e.g. Akshay"}
                  id={"zoneCoordinator"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneCoordName"
                  value={formik.values.zoneCoordName}
                  error={formik.errors.zoneCoordName}
                  touched={formik.touched.zoneCoordName}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Coordinator Mobile"}
                  placeholder={"e.g. 9878XXXXXX"}
                  id={"coordinatorMobile"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneCoordPhNum"
                  value={formik.values.zoneCoordPhNum}
                  error={formik.errors.zoneCoordPhNum}
                  touched={formik.touched.zoneCoordPhNum}
                  disabled={formik.values.zoneCoordName ? false : true}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(AddZoneModal);
