import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { useFormik } from "formik";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import ImageUploadInput from "../../components/form/ImageUploadInput";
import * as Yup from "yup";

const ParishValidationSchema = Yup.object().shape({
  churchName: Yup.string().required("Required"),
  parishPriest: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  abbreviation: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

const EditParishModal = ({
  show,
  setShow,
  commonAPIRequest,
  churchId,
  setCurrentChurchId,
  callGetAllChurches,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      churchName: "",
      parishPriest: "",
      address: "",
      abbreviation: "",
      description: "",
      city: "",
      state: "",
      pincode: "",
    },
    validationSchema: ParishValidationSchema,
    onSubmit: (values) => {
      callUpdateChurchDetailsApi();
    },
  });

  const callUpdateChurchDetailsApi = () => {
    let serviceParams = {
      api: API.UPDATE_CHURCH_DETAILS_RESOURCE,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        churchName: formik.values.churchName,
        churchId: churchId,
        address: {
          addressLine1: formik.values.address,
          addressLine2: "",
          block: "",
          sector: "",
          city: formik.values?.city,
          state: formik.values?.state,
          pinCode: formik.values?.pincode,
        },
        fatherName: formik.values.parishPriest,
        description: formik.values.description,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setShow(false);
          callGetAllChurches();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetChurchDetailsApi = (id) => {
    let serviceParams = {
      api: `${API.GET_CHURCH_DETAIL_RESOURCE}?churchId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          formik.setFieldValue("churchName", result?.payload?.churchName);
          formik.setFieldValue("parishPriest", result?.payload?.fatherName);
          formik.setFieldValue(
            "address",
            result?.payload?.address?.addressLine1
          );
          formik.setFieldValue("description", result?.payload?.description);
          formik.setFieldValue("abbreviation", result?.payload?.churchCode);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (churchId && show) {
      callGetChurchDetailsApi(churchId);
    }
  }, [churchId, show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setCurrentChurchId(null);
      }}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Parish Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col sm="12">
                <TextInput
                  label={"Church Name"}
                  placeholder={"e.g. St. Mary's Church"}
                  id={"churchName"}
                  setFieldValue={formik.setFieldValue}
                  keyword="churchName"
                  value={formik.values.churchName}
                  error={formik.errors.churchName}
                  touched={formik.touched.churchName}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"Parish Priest (Full Name)"}
                  placeholder={"e.g. Fr. Joseph"}
                  id={"parishPriest"}
                  setFieldValue={formik.setFieldValue}
                  keyword="parishPriest"
                  value={formik.values.parishPriest}
                  error={formik.errors.parishPriest}
                  touched={formik.touched.parishPriest}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"Abbreviation"}
                  placeholder={"e.g. SMC"}
                  id={"abbreviation"}
                  setFieldValue={formik.setFieldValue}
                  keyword="abbreviation"
                  value={formik.values.abbreviation}
                  error={formik.errors.abbreviation}
                  touched={formik.touched.abbreviation}
                  disabled={true}
                />
              </Col>
              <Col sm="12">
                <TextArea
                  label={"Address"}
                  placeholder={"e.g. RK PURAM, DELHI, 110011"}
                  id={"address"}
                  setFieldValue={formik.setFieldValue}
                  keyword="address"
                  value={formik.values.address}
                  error={formik.errors.address}
                  touched={formik.touched.address}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"City"}
                  placeholder={"e.g. New Delhi"}
                  id={"city"}
                  setFieldValue={formik.setFieldValue}
                  keyword="city"
                  value={formik.values.city}
                  error={formik.errors.city}
                  touched={formik.touched.city}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"State"}
                  placeholder={"e.g. Delhi"}
                  id={"state"}
                  setFieldValue={formik.setFieldValue}
                  keyword="state"
                  value={formik.values.state}
                  error={formik.errors.state}
                  touched={formik.touched.state}
                />
              </Col>
              <Col sm="12">
                <TextInput
                  label={"Pincode"}
                  placeholder={"e.g. 110001"}
                  id={"pincode"}
                  setFieldValue={formik.setFieldValue}
                  keyword="pincode"
                  value={formik.values.pincode}
                  error={formik.errors.pincode}
                  touched={formik.touched.pincode}
                />
              </Col>
              <Col sm="12">
                <TextArea
                  label={"Description"}
                  placeholder={
                    "e.g. The Only Church Located in the heart of Delhi"
                  }
                  id={"description"}
                  setFieldValue={formik.setFieldValue}
                  keyword="description"
                  value={formik.values.description}
                  error={formik.errors.description}
                  touched={formik.touched.description}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
              setCurrentChurchId(null);
            }}
          >
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditParishModal);
