const d1 = [
  [0, 38],
  [1, 32],
  [2, 31],
  [3, 33],
  [4, 34],
  [5, 35],
  [6, 38],
  [7, 37],
  [8, 39],
  [9, 34],
  [10, 33],
  [11, 32],
  [12, 34],
  [13, 38],
  [14, 42],
  [15, 43],
  [16, 45],
  [17, 43],
  [18, 45],
  [19, 48],
  [20, 45],
  [21, 46],
  [22, 44],
  [23, 42],
  [24, 46],
  [25, 48],
  [26, 55],
  [27, 54],
  [28, 58],
  [29, 69],
];
const d2 = [
  [0, 12],
  [1, 11],
  [2, 9],
  [3, 10],
  [4, 15],
  [5, 9],
  [6, 11],
  [7, 12],
  [8, 7],
  [9, 16],
  [10, 18],
  [11, 24],
  [12, 16],
  [13, 10],
  [14, 9],
  [15, 16],
  [16, 15],
  [17, 28],
  [18, 20],
  [19, 15],
  [20, 20],
  [21, 12],
  [22, 15],
  [23, 12],
  [24, 10],
  [25, 12],
  [26, 14],
  [27, 18],
  [28, 20],
  [29, 21],
];

// Tickets Earnings
export const seriesOne = [
  {
    name: "series1",
    data: d1,
  },
  {
    name: "series2",
    data: d2,
  },
];

export const optionOne = {
  chart: {
    parentHeightOffset: 0,
    toolbar: { show: false },
    stacked: true,
    sparkline: { enabled: true },
  },
  colors: ["#912e2d", "#912e2d"],
  stroke: {
    curve: "straight",
    width: 2,
  },
  yaxis: {
    min: 0,
    max: 150,
    show: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
    },
  },
  tooltip: { enabled: false },
};

// Tickets Available
export const seriesTwo = [
  {
    data: [
      [0, 1],
      [1, 2],
      [2, 5],
      [3, 6],
      [4, 8],
      [5, 10],
      [6, 15],
      [7, 18],
      [8, 13],
      [9, 11],
      [10, 13],
      [11, 15],
      [12, 13],
      [13, 7],
      [14, 5],
      [15, 8],
      [16, 11],
      [17, 7],
      [18, 5],
      [19, 5],
      [20, 6],
      [21, 6],
      [22, 5],
      [23, 5],
      [24, 6],
      [25, 2],
      [26, 1],
    ],
  },
  {
    data: [
      [0, 2],
      [1, 3],
      [2, 4],
      [3, 8],
      [4, 4],
      [5, 12],
      [6, 4],
      [7, 6],
      [8, 5],
      [9, 10],
      [10, 4],
      [11, 5],
      [12, 10],
      [13, 2],
      [14, 6],
      [15, 16],
      [16, 5],
      [17, 17],
      [18, 14],
      [19, 6],
      [20, 5],
      [21, 2],
      [22, 12],
      [23, 4],
      [24, 7],
      [25, 1],
      [26, 2],
      [27, 1],
      [28, 2],
      [29, 2],
      [30, 1],
      [31, 0.2],
      [32, 0.2],
      [33, 0.2],
      [34, 0.2],
      [35, 0.2],
      [36, 0.2],
      [37, 0.2],
      [38, 0.2],
      [39, 0.2],
      [40, 0.2],
      [41, 0.2],
      [42, 0.2],
      [43, 0.2],
      [44, 0.2],
      [45, 0.2],
      [46, 0.2],
      [47, 0.2],
      [48, 0.2],
      [49, 0.2],
    ],
  },
];

export const optionTwo = {
  chart: {
    stacked: true,
    sparkline: { enabled: true },
  },
  states: {
    hover: {
      filter: { type: "none" },
    },
    active: {
      filter: { type: "none" },
    },
  },
  colors: ["#912e2d", "#e5e9f2"],
  grid: {
    padding: {
      bottom: 10,
      left: 0,
      right: 0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "40%",
      endingShape: "rounded",
    },
  },
  stroke: {
    curve: "straight",
    lineCap: "square",
  },
  yaxis: {
    min: 0,
    max: 30,
  },
  fill: { opacity: 1 },
  tooltip: { enabled: false },
};

// Tickets Sold
export const seriesThree = [
  {
    name: "series1",
    data: d1,
  },
];

export const optionThree = {
  chart: {
    parentHeightOffset: 0,
    toolbar: { show: false },
    sparkline: { enabled: true },
  },
  colors: ["#912e2d"],
  stroke: {
    curve: "straight",
    width: 2,
  },
  xaxis: { max: 20 },
  yaxis: {
    min: 0,
    max: 160,
    show: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
    },
  },
  tooltip: { enabled: false },
};

// Tickets Unsold
export const optionFour = {
  chart: {
    parentHeightOffset: 0,
    toolbar: { show: false },
    sparkline: { enabled: true },
  },
  colors: ["#912e2d"],
  stroke: {
    curve: "straight",
    width: 2,
  },
  xaxis: {
    min: 3,
    max: 25,
  },
  yaxis: {
    min: 0,
    max: 160,
    show: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
    },
  },
  tooltip: { enabled: false },
};
