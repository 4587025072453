import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  MarriageFormStep2ValidationSchema,
  MarriageFormStep3ValidationSchema,
  OutsiderMarriageFormStep1ValidationSchema,
} from "../../validations/marriage";
import { Alert, Button, Col, Card, Modal, Row } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";
import MarriageFormStep1 from "../../forms/marriage/MarriageFormStep1";
import MarriageFormStep2 from "../../forms/marriage/MarriageFormStep2";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import OutsideMarriageFormStep2 from "../../forms/marriage/OutsideMarriageFormStep2";
import OutsideMarriageFormStep3 from "../../forms/marriage/OutsideMarriageFormStep3";

const EditOutsiderMarriageDetailsModal = ({
  show,
  setShow,
  commonAPIRequest,
  currentMarriage,
  callGetAllMarriage,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(1);

  const formik1 = useFormik({
    initialValues: {
      marriageDate: "",
      marriagePlace: "",
      confirmationDate: "",
      confirmationDateForValidation: "",
      firstBannesDate: "",
      secondBannesDate: "",
      thirdBannesDate: "",
      firstWitnessFirstName: "",
      firstWitnessLastName: "",
      firstWitnessDomicile: "",
      secondWitnessFirstName: "",
      secondWitnessLastName: "",
      secondWitnessDomicile: "",
      ministerName: "",
      impediment: "",
      remark: "",
      registerRefNumber: null,
      marriageDateNotSure: false,
    },
    validationSchema: OutsiderMarriageFormStep1ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      groomFirstName: "",
      groomMiddleName: "",
      groomLastName: "",
      groomFatherName: "",
      groomMotherName: "",
      groomDob: "",
      groomNationality: "",
      groomDomicile: "",
      groomProfession: "",
      groomMarriageStatus: "",
      previousWifeName: "",
    },
    validationSchema: MarriageFormStep2ValidationSchema,
    onSubmit: (values) => {
      if (!(currentState >= 3)) {
        setCurrentState((prevValue) => prevValue + 1);
      }
    },
  });

  const formik3 = useFormik({
    initialValues: {
      brideFirstName: "",
      brideMiddletName: "",
      brideLastName: "",
      brideFatherName: "",
      brideMotherName: "",
      brideDob: "",
      brideNationality: "",
      brideDomicile: "",
      brideProfession: "",
      brideMarriageStatus: "",
      previousHusbandName: "",
      brideMemberId: "",
    },
    validationSchema: MarriageFormStep3ValidationSchema,
    onSubmit: (values) => {
      callEditMarriageDetailsApi();
    },
  });

  // function to call API for Add Marriage start

  const callEditMarriageDetailsApi = () => {
    let serviceParams = {
      api: API.EXTERNAL_MARRIAGE_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        marriageDetail: {
          marriageId: currentMarriage?.marriageId,
          marriageDate: formik1.values.marriageDate,
          marriagePlace: formik1.values.marriagePlace,
          groomFirstName: formik2.values.groomFirstName.trim(),
          groomMiddleName: formik2.values.groomMiddleName.trim(),
          groomLastName: formik2.values.groomLastName.trim(),
          groomFatherName: formik2.values.groomFatherName.trim(),
          groomMotherName: formik2.values.groomMotherName.trim(),
          groomDob: formik2.values.groomDob,
          groomNationality: formik2.values.groomNationality.trim(),
          groomDomicile: formik2.values.groomDomicile.trim(),
          groomProfession: formik2.values.groomProfession.trim(),
          groomMarriageStatus: formik2.values.groomMarriageStatus,
          brideFirstName: formik3.values.brideFirstName.trim(),
          brideMiddleName: formik3.values.brideMiddletName.trim(),
          brideLastName: formik3.values.brideLastName.trim(),
          brideFatherName: formik3.values.brideFatherName.trim(),
          brideMotherName: formik3.values.brideMotherName.trim(),
          brideDob: formik3.values.brideDob,
          brideNationality: formik3.values.brideNationality.trim(),
          brideDomicile: formik3.values.brideDomicile.trim(),
          brideProfession: formik3.values.brideProfession.trim(),
          brideMarriageStatus: formik3.values.brideMarriageStatus,
          pastHusbandName: formik3.values.previousHusbandName.trim(),
          pastWifeName: formik2.values.previousWifeName.trim(),
          firstBannsDate: formik1.values.firstBannesDate,
          secondBannsDate: formik1.values.secondBannesDate,
          thirdBannsDate: formik1.values.thirdBannesDate,
          impediment: formik1.values.impediment.trim(),
          firstWitnessFirstName: formik1.values.firstWitnessFirstName.trim(),
          firstWitnessLastName: formik1.values.firstWitnessLastName.trim(),
          firstWitnessDomicile: formik1.values.firstWitnessDomicile.trim(),
          secondWitnessFirstName: formik1.values.secondWitnessFirstName.trim(),
          secondWitnessLastName: formik1.values.secondWitnessLastName.trim(),
          secondWitnessDomicile: formik1.values.secondWitnessDomicile.trim(),
          remark: formik1.values.remark.trim(),
          active: true,
          ministerName: formik1.values.ministerName.trim(),
          regReferenceNum: formik1.values?.registerRefNumber
            ? formik1.values?.registerRefNumber
            : null,
          marriageDateAccurate: !formik1.values.marriageDateNotSure,
        },
        brideMemberId: null,
        groomMemberId: null,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Marriage Details Added Successfully.`,
            "success"
          );
          setShow(false);
          formik1.resetForm();
          formik2.resetForm();
          formik3.resetForm();
          setCurrentState(1);
          callGetAllMarriage();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add Marriage end

  useEffect(() => {
    if (show) {
      console.log("currentMarriage", currentMarriage);
      formik1.setFieldValue(
        "marriageDate",
        new Date(currentMarriage?.marriageDate)
      );
      formik1.setFieldValue("marriagePlace", currentMarriage.marriagePlace);
      //   Banns Date
      formik1.setFieldValue(
        "firstBannesDate",
        new Date(currentMarriage?.firstBannsDate)
      );
      formik1.setFieldValue(
        "secondBannesDate",
        new Date(currentMarriage?.secondBannsDate)
      );
      formik1.setFieldValue(
        "thirdBannesDate",
        new Date(currentMarriage?.thirdBannsDate)
      );
      //   First Witness Info
      formik1.setFieldValue(
        "firstWitnessFirstName",
        currentMarriage.firstWitnessFirstName
      );
      formik1.setFieldValue(
        "firstWitnessLastName",
        currentMarriage.firstWitnessLastName
      );
      formik1.setFieldValue(
        "firstWitnessDomicile",
        currentMarriage.firstWitnessDomicile
      );

      //   Second Witness Info
      formik1.setFieldValue(
        "secondWitnessFirstName",
        currentMarriage.secondWitnessFirstName
      );
      formik1.setFieldValue(
        "secondWitnessLastName",
        currentMarriage.secondWitnessLastName
      );
      formik1.setFieldValue(
        "secondWitnessDomicile",
        currentMarriage.secondWitnessDomicile
      );

      //   Other Info Details
      formik1.setFieldValue("ministerName", currentMarriage.ministerName);
      formik1.setFieldValue("impediment", currentMarriage.impediment);
      formik1.setFieldValue("remark", currentMarriage.remark);
      formik1.setFieldValue(
        "registerRefNumber",
        currentMarriage.regReferenceNum
      );

      // Groom's Details Auto fetch

      formik2.setFieldValue("groomFirstName", currentMarriage.groomFirstName);
      formik2.setFieldValue("groomMiddleName", currentMarriage.groomMiddleName);
      formik2.setFieldValue("groomLastName", currentMarriage.groomLastName);
      formik2.setFieldValue("groomFatherName", currentMarriage.groomFatherName);
      formik2.setFieldValue("groomMotherName", currentMarriage.groomMotherName);
      formik2.setFieldValue(
        "groomNationality",
        currentMarriage.groomNationality
      );
      formik2.setFieldValue(
        "groomMarriageStatus",
        currentMarriage.groomMarriageStatus
      );
      formik2.setFieldValue("groomDomicile", currentMarriage.groomDomicile);
      formik2.setFieldValue("groomProfession", currentMarriage.groomProfession);
      formik2.setFieldValue("groomDob", new Date(currentMarriage?.groomDob));
      formik2.setFieldValue("previousWifeName", currentMarriage?.pastWifeName);

      // Bride Details Auto-fetch

      formik3.setFieldValue("brideFirstName", currentMarriage.brideFirstName);
      formik3.setFieldValue("brideMiddleName", currentMarriage.brideMiddleName);
      formik3.setFieldValue("brideLastName", currentMarriage.brideLastName);
      formik3.setFieldValue("brideFatherName", currentMarriage.brideFatherName);
      formik3.setFieldValue("brideMotherName", currentMarriage.brideMotherName);
      formik3.setFieldValue(
        "brideNationality",
        currentMarriage.brideNationality
      );
      formik3.setFieldValue(
        "brideMarriageStatus",
        currentMarriage.brideMarriageStatus
      );
      formik3.setFieldValue("brideDomicile", currentMarriage.brideDomicile);
      formik3.setFieldValue("brideProfession", currentMarriage.brideProfession);
      formik3.setFieldValue("brideDob", new Date(currentMarriage?.brideDob));
      formik3.setFieldValue(
        "previousHusbandName",
        currentMarriage?.pastHusbandName
      );
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setCurrentState(1);
      }}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Edit Outsider's Marriage Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
            <span
              style={{ marginLeft: -54 }}
              className="fs-18 text-primary fw-bold"
            >
              Updating...
            </span>
          </div>
        ) : (
          <div
            id="wizard1"
            className="wizard-demo wizard clearfix"
            role="application"
          >
            <div className="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  className={
                    currentState === 1
                      ? "current first"
                      : currentState > 1
                      ? "done first"
                      : "first"
                  }
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a id="wizard1-t-0" href="#" aria-controls="wizard1-p-0">
                    <span className="number">1</span>
                    <span className="title">Step 1</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 2
                      ? "current second"
                      : currentState > 2
                      ? "done second"
                      : "second"
                  }
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <a id="wizard1-t-1" href="#" aria-controls="wizard1-p-1">
                    <span className="number">2</span>{" "}
                    <span className="title">Step 2</span>
                  </a>
                </li>
                <li
                  role="tab"
                  className={
                    currentState === 3
                      ? "current last"
                      : currentState > 3
                      ? "done last"
                      : "last"
                  }
                  aria-disabled="true"
                >
                  <a id="wizard1-t-2" href="#" aria-controls="wizard1-p-2">
                    <span className="number">3</span>{" "}
                    <span className="title">Step 3</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content clearfix">
              {currentState === 1 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />
                  <MarriageFormStep1 formik={formik1} outsider />
                </div>
              )}
              {currentState === 2 && (
                <div>
                  <h3 id="wizard1-h-0" tabIndex="-1" className="title current">
                    Groom's Basic Information
                  </h3>
                  <p className="mb-0">Please enter the below details</p>
                  <hr className="hr" />

                  <OutsideMarriageFormStep2
                    formik={formik2}
                    currentMarriage={currentMarriage}
                  />
                </div>
              )}

              {currentState === 3 && (
                <div>
                  <Row className="d-flex justify-content-between">
                    <Col sm={6}>
                      <h3
                        id="wizard1-h-0"
                        tabIndex="-1"
                        className="title current"
                      >
                        Bride's Basic Information
                      </h3>
                      <p className="mb-0">Please enter the below details</p>
                    </Col>
                  </Row>
                  <hr className="hr" />
                  <OutsideMarriageFormStep3 formik={formik3} />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (!(currentState <= 1)) {
                setCurrentState((prevValue) => prevValue - 1);
              }
            }}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (currentState === 1) {
                formik1.handleSubmit();
              } else if (currentState === 2) {
                formik2.handleSubmit();
              } else if (currentState === 3) {
                formik3.handleSubmit();
              }
            }}
          >
            {currentState === 3 ? "Save" : "Next"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditOutsiderMarriageDetailsModal);
