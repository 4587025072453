import React from "react";
import Forbidden from "../pages/Forbidden";
import InternalServerError from "../pages/InternalServerError";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Signin from "../pages/Signin";
import TokenPage from "../pages/TokenPage";

const publicRoutes = [
  { path: "/", element: <Signin /> },
  { path: "404", element: <NotFound /> },
  { path: "500", element: <InternalServerError /> },
  { path: "503", element: <ServiceUnavailable /> },
  { path: "505", element: <Forbidden /> },
  { path: "token", element: <TokenPage /> },
];

export default publicRoutes;
